/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import api from '../../../services/api';

import Button from '../../../components/Button';
import {
  Main,
  Section,
  FormCadastroPequenosAlmocos,
  DeleteIcon,
} from './styles';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input/index';

interface IDeliverymanFormData {
  name: string;
  documentNumber: string;
  licensePlate: string;
  vehicleDocument: string;
  contactNumber: string;
  referenceContactNumber: string;
  additionalData: string;
}

interface IDeliveryParams {
  id: string;
}

const EditarEntregador: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<IDeliveryParams>();

  const history = useHistory();
  const { addToast } = useToast();

  const [model, setModel] = useState<IDeliverymanFormData>({
    name: '',
    documentNumber: '',
    licensePlate: '',
    vehicleDocument: '',
    contactNumber: '',
    referenceContactNumber: '',
    additionalData: '',
  });

  useEffect(() => {
    api.get<IDeliverymanFormData>(`deliveryman/read/${id}`).then(response => {
      setModel(response.data);
    });
  }, [id]);

  const removeDeliveryman = async () => {
    try {
      setLoading(true);
      const response = await api.delete(`/deliveryman/delete/${id}`);
      setLoading(false);
      addToast({
        type: 'success',
        title: 'Entregador Removido',
        description: 'Entregador removido com sucesso',
      });

      history.push('/entregadores');
    } catch (error: any) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na Atualização Entregador',
        description:
          JSON.parse(error.request.response).message ||
          'Falha em atualizar Entregador',
      });
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = useCallback(
    async (data: IDeliverymanFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),

          contactNumber: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.put(`/deliveryman/update/${id}`, data);

        window.scrollTo(0, 0);
        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description: 'Cadastro atualizado com sucesso',
        });

        setLoading(false);
        history.push('/entregadores');
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar!',
          description: 'Oops ocorreu um erro ao atualizar o cadastro',
        });
        window.scrollTo(0, 0);
      }
    },
    [addToast, history, id],
  );

  function handleBack() {
    history.go(-1);
  }

  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Acompanhe o Cadastro de seus entregadores</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>
      <Section>
        <div className="sectionCard">
          <div className="flex-container">
            <div>
              <div>
                <h2>Editar Cadastro</h2>
              </div>
              <div className="deleteItem">
                <Button
                  type="button"
                  loading={loading}
                  onClick={() => removeDeliveryman()}
                >
                  Remover Entregador
                  <DeleteIcon />
                </Button>
              </div>
            </div>

            <div className="menu-cadastro">
              <Link to="/entregadores">
                <div className="item-novo-cadastro">Cadastrados</div>
              </Link>
              <div className="item-cadastrados">Novo Cadastro</div>
            </div>

            <div>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormCadastroPequenosAlmocos>
                  <div className="formulario-cadastro">
                    <div>
                      <div className="form-inputs">
                        <h5>Nome do Entregador*</h5>
                        <Input
                          type="text"
                          name="name"
                          defaultValue={model.name}
                        />
                      </div>
                      <div className="form-inputs">
                        <h5>Documento</h5>
                        <Input
                          type="text"
                          name="documentNumber"
                          defaultValue={model.documentNumber}
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Placa do Veículo</h5>
                          <Input
                            type="text"
                            name="licensePlate"
                            defaultValue={model.licensePlate}
                          />
                        </div>
                        <div className="form-inputs">
                          <h5>Documento do Veículo</h5>
                          <Input
                            type="text"
                            name="vehicleDocument"
                            defaultValue={model.vehicleDocument}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Telefone de Contato*</h5>
                          <Input
                            type="text"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            maxLength={10}
                            className="input-contato"
                            name="contactNumber"
                            defaultValue={model.contactNumber}
                          />
                        </div>
                        <div className="form-inputs">
                          <h5>Telefone de Referência</h5>
                          <Input
                            pattern="^-?[0-9]\d*\.?\d*$"
                            maxLength={10}
                            type="text"
                            className="input-contato"
                            name="referenceContactNumber"
                            defaultValue={model.referenceContactNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Informações Adicionais</h5>
                          <Input
                            type="text"
                            className="form-info"
                            name="additionalData"
                            defaultValue={model.additionalData}
                          />
                        </div>
                      </div>
                    </div>
                    <h6>*Campos Obrigatórios</h6>
                    <div className="bottomPedidos">
                      <Button loading={loading} type="submit">
                        Salvar
                      </Button>
                      <Button className="allItems" onClick={handleBack}>
                        Voltar
                      </Button>
                    </div>
                  </div>
                </FormCadastroPequenosAlmocos>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default EditarEntregador;
