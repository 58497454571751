/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

import AdminLayout from '../pages/_layouts/Admin';
import ClienteLayout from '../pages/_layouts/Cliente';
import ProducaoLayout from '../pages/_layouts/Producao';
import LoginLayout from '../pages/_layouts/Login';

interface RouteProps extends ReactDOMRouteProps {
  component: ComponentType;
  isPrivate?: boolean;
  typeLayout: string;
}

const RoutesWrapper: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  typeLayout,
  ...rest
}) => {
  const { user } = useAuth();
  let Layout: any = null;

  switch (typeLayout) {
    case 'login':
      Layout = LoginLayout;
      break;

    case 'admin':
      Layout = AdminLayout;
      break;

    case 'cliente':
      Layout = ClienteLayout;
      break;

    case 'production':
      Layout = ProducaoLayout;
      break;

    default:
      Layout = LoginLayout;
  }

  let novoPath = '/';
  if (user) {
    if (user.role === 'ADMIN') {
      novoPath = 'admin';
    } else if (user.role === 'CLIENT') {
      novoPath = 'cliente';
    } else if (user.role === 'PRODUCTION') {
      novoPath = 'production';
    } else {
      novoPath = '/';
    }
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : novoPath,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default RoutesWrapper;
