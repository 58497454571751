/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import api from '../../../services/api';

import lunchImg from '../../../assets/lunch.png';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import {
  Main,
  Section,
  FormCadastroCliente,
  SelectContract,
  RadioBox,
  EditIcon,
  DeleteIcon,
} from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';

interface INewClientDataAddress {
  fakeId: number;
  street: string;
  streetNumber: string;
  number: string;
  city: string;
  neighborhood: string;
  doorCode: string;
  numberOfBedrooms: string;
  deliveryPrice: number;
  apartments: [{ aptId: number; value: string }];
  deliveryPlace: string;
}

interface newClientData {
  listApartments?: unknown;
  id: string;
  name: string;
  companyName: string;
  fiscalName: string;
  clientType: string;
  contactPhone: number;
  nif: string;
  contactName: string;
  contactEmail: string;
  address: INewClientDataAddress[];

  lunchs: [
    {
      lunchId: string;
      customLunchCost: number;
    },
  ];
}

interface ILunchFormData {
  id: string;
  lunchType: string;
  description: string;
  smallLunchPrice: number;
}

interface INewLunchsData {
  lunchId: string;
  lunchType: string;
  description: string;
  customLunchCost?: number;
  smallLunchPrice: number;
}

const generateEmptyAddress: any = (newFakeId: number) => {
  const data = {
    fakeId: newFakeId,
    city: '',
    doorCode: '',
    neighborhood: '',
    number: '',
    numberOfBedrooms: '',
    street: '',
    streetNumber: '',
    addressType: 'DELIVERY',
    deliveryPlace: 'DELIVERY',
    deliveryPrice: 0,
    apartments: [{ aptId: 1, value: '' }],
  };

  return data;
};

const CadastroCliente: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const [clientType, setClientType] = useState('COM_CONTRATO');
  const [lunch, setLunch] = useState<ILunchFormData[]>([]);
  const [addresses, setAddresses] = useState<INewClientDataAddress[]>([
    {
      fakeId: 1,
      city: '',
      doorCode: '',
      neighborhood: '',
      number: '',
      numberOfBedrooms: '',
      street: '',
      streetNumber: '',
      deliveryPlace: '',
      deliveryPrice: 0,
      apartments: [{ aptId: 1, value: '' }],
    },
  ]);
  const [cardAddressData, setCardAddressData] = useState<
    INewClientDataAddress[]
  >([]);
  const [lunchChecked, setLunchChecked] = useState<INewLunchsData[] | []>([]);
  const [mainAddress, setMainAddress] = useState<any>({
    fakeId: uuidv4(),
    city: '',
    doorCode: '',
    neighborhood: '',
    number: '',
    numberOfBedrooms: '',
    street: '',
    streetNumber: '',
    addressType: 'DELIVERY',
    deliveryPrice: 0,
    apartments: [{ aptId: uuidv4(), value: '' }],
  });

  const handlePickerLunch = (checked: boolean, lunchId: string) => {
    if (checked) {
      const selectedLunch = lunch.filter(food => food.id === lunchId)[0];

      const obj: INewLunchsData = {
        description: selectedLunch.description,
        lunchId: selectedLunch.id,
        lunchType: selectedLunch.lunchType,
        smallLunchPrice: selectedLunch.smallLunchPrice,
      };

      setLunchChecked([...lunchChecked, obj]);
    } else {
      const selected = lunchChecked.filter(food => food.lunchId !== lunchId);
      setLunchChecked(selected);
    }
  };

  useEffect(() => {
    api.get('/lunch/list').then(response => {
      setLunch(response.data);
    });
  }, []);

  function handleValueLunch(e: any, id: string) {
    setLunch(state =>
      state.map(l => {
        if (l.id === id) {
          return {
            ...l,
            smallLunchPrice: e.target.value,
          };
        }
        return l;
      }),
    );
    if (lunchChecked.length > 0) {
      setLunchChecked(lunchPrice =>
        lunchPrice.map((lc: INewLunchsData) => {
          if (lc.lunchId === id) {
            return {
              ...lc,
              customLunchCost: e.target.value,
            };
          }
          return lc;
        }),
      );
    }
  }

  const changeApartamentNumber = (
    addressId: number,
    aptId: number,
    apt: string,
  ) => {
    const obj = {
      aptId,
      value: apt,
    };

    setMainAddress({
      ...mainAddress,
      apartments: mainAddress.apartments.map((place: any) =>
        place.aptId === aptId ? obj : place,
      ),
    });
  };

  const addApartment = () => {
    const newApt = { aptId: uuidv4(), value: '' };

    setMainAddress({
      ...mainAddress,
      apartments: [...mainAddress.apartments, newApt],
    });
  };

  const addAddress = () => {
    const lastAddressArray = cardAddressData![addresses.length - 1];

    const newAddress = generateEmptyAddress(lastAddressArray.fakeId + 1);

    setMainAddress(newAddress);
  };

  const saveAddressCard = (addressId: number) => {
    const addressExist = cardAddressData.filter(
      address => address.fakeId === addressId,
    )[0];

    if (addressExist && addressExist.fakeId) {
      setCardAddressData(
        cardAddressData.map(address =>
          address.fakeId === addressId
            ? {
                ...mainAddress,
                numberOfBedrooms: mainAddress.apartments.length,
              }
            : address,
        ),
      );
    } else {
      setCardAddressData([
        ...cardAddressData,
        { ...mainAddress, numberOfBedrooms: mainAddress.apartments.length },
      ]);
    }

    setMainAddress(generateEmptyAddress(uuidv4()));
  };

  const changeAddressData = ({ input, data }: { input: any; data: any }) => {
    const main: any = mainAddress;

    main![input] = data;

    setMainAddress({ ...mainAddress, input: data });
  };

  const removeApt = ({ aptId }: { aptId: number }) => {
    if (mainAddress.apartments.length > 1) {
      setMainAddress({
        ...mainAddress,
        apartments: mainAddress.apartments.filter(
          (apt: any) => apt.aptId !== aptId,
        ),
      });
    }
  };

  const removeAddress = ({ addressId }: { addressId: number }) => {
    const filter = cardAddressData.filter(
      address => address.fakeId !== addressId,
    );
    setCardAddressData(filter);
  };

  const editAddress = (addressId: number) => {
    const filter = cardAddressData.filter(
      address => address.fakeId === addressId,
    )[0];

    setMainAddress(filter);
  };

  const handleSubmit = useCallback(
    async (data: newClientData) => {
      try {
        formRef.current?.setErrors({});

        const addressData = cardAddressData.map(address => ({
          ...address,
          apartments: address.apartments.map(item => item.value),
        }));

        const newData = {
          ...data,
          clientType,
          address: addressData,
          lunchs: lunchChecked,
        };

        const schema = Yup.object().shape({
          companyName: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          contactPhone: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          nif: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          contactName: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          contactEmail: Yup.string()
            .email(`E-mail Inválido${window.scrollTo(0, 0)}`)
            .required(`Campo Obrigatório${window.scrollTo(0, 0)}`),
        });

        await schema.validate(newData, {
          abortEarly: false,
        });

        setLoading(true);
        await api.post('/client/create', newData);

        addToast({
          type: 'success',
          title: 'Cadastro Realizado com sucesso',
          description: 'Cadastro realizado com sucesso',
        });
        setLoading(false);
        history.push('/clientes');
      } catch (err: any) {
        setLoading(false);
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            JSON.parse(err.request.response).message ||
            'Oops ocorreu um erro ao realizar o cadastro',
        });
        window.scrollTo(0, 0);
      }
    },
    [addToast, history, clientType, lunchChecked, cardAddressData],
  );

  function handleBack() {
    history.go(-1);
  }

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe o cadastro de seus clientes</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div>
            <div className="flex-container">
              <div>
                <h2>Cadastro de Clientes</h2>
              </div>

              <div className="menu-cadastro">
                <Link to="/clientes">
                  <div className="item-novo-cadastro">Cadastrados</div>
                </Link>
                <div className="item-cadastrados">Novo Cadastro</div>
              </div>
              <div>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <SelectContract>
                    <RadioBox
                      type="button"
                      onClick={() => {
                        setClientType('COM_CONTRATO');
                      }}
                      isActive={clientType === 'COM_CONTRATO'}
                    >
                      <span>Cliente com Contrato</span>
                      <div className="ratio-check" />
                    </RadioBox>
                    <RadioBox
                      type="button"
                      onClick={() => {
                        setClientType('SEM_CONTRATO');
                      }}
                      isActive={clientType === 'SEM_CONTRATO'}
                    >
                      <span>Cliente sem Contrato</span>
                      <div className="ratio-check" />
                    </RadioBox>
                  </SelectContract>
                  <FormCadastroCliente>
                    <div className="formulario-cadastro">
                      <div>
                        <div className="form-inputs">
                          <h5>Nome da Empresa*</h5>
                          <Input name="companyName" type="text" />
                        </div>
                        <div className="form-inputs">
                          <h5>Nome Fiscal</h5>
                          <Input name="fiscalName" type="text" />
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="form-inputs">
                            <h5>Nome do Contato*</h5>
                            <Input name="contactName" type="text" />
                          </div>
                          <div className="form-inputs">
                            <h5>Telefone do Contato*</h5>
                            <Input
                              pattern="^-?[0-9]\d*\.?\d*$"
                              maxLength={10}
                              name="contactPhone"
                              type="tel"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="form-inputs">
                            <h5>Email do Contato*</h5>
                            <Input
                              name="contactEmail"
                              type="text"
                              className="input-email"
                            />
                          </div>
                          <div>
                            <div className="form-inputs">
                              <h5>NIF*</h5>
                              <Input name="nif" maxLength={10} type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="form-inputs">
                          <h5>Senha do Cliente</h5>
                          <Input name="password" type="password" />
                        </div>
                      </div>
                      <h6>*Campos obrigatórios</h6>
                    </div>
                    {cardAddressData?.length !== 0 &&
                      cardAddressData !== null &&
                      cardAddressData !== undefined &&
                      cardAddressData!.map((address, index) => (
                        <div key={String(index)} className="cardMorada">
                          <h3>Morada</h3>
                          <div>
                            <div className="enderecoCard">
                              <span>
                                {address.street} - {address.streetNumber}
                              </span>
                              <div className="buttonsCards">
                                <EditIcon
                                  onClick={() => editAddress(address.fakeId)}
                                  style={{ cursor: 'pointer' }}
                                />
                                <DeleteIcon
                                  onClick={() =>
                                    removeAddress({ addressId: address.fakeId })
                                  }
                                  style={{ cursor: 'pointer' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className="redLine" />
                    <div className="morada">
                      <h2>Cadastrar Morada</h2>
                      <div className="formulario-cadastro">
                        <div>
                          <div className="form-inputs">
                            <h5>Rua</h5>
                            <Input
                              name="street"
                              id="street"
                              type="text"
                              value={mainAddress!.street}
                              onChange={e =>
                                changeAddressData({
                                  data: e.target.value,
                                  input: e.target.id,
                                })
                              }
                            />
                          </div>
                          <div className="form-inputs">
                            <h5>Número</h5>
                            <Input
                              id="streetNumber"
                              name="streetNumber"
                              type="text"
                              className="input-numero"
                              value={mainAddress!.streetNumber}
                              onChange={e =>
                                changeAddressData({
                                  data: e.target.value,
                                  input: e.target.id,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="form-inputs">
                              <h5>Bairro</h5>
                              <Input
                                id="neighborhood"
                                name="neighborhood"
                                type="text"
                                value={mainAddress!.neighborhood}
                                onChange={e =>
                                  changeAddressData({
                                    data: e.target.value,
                                    input: e.target.id,
                                  })
                                }
                              />
                            </div>
                            <div className="form-inputs">
                              <h5>Cidade</h5>
                              <Input
                                id="city"
                                name="city"
                                type="text"
                                value={mainAddress!.city}
                                onChange={e =>
                                  changeAddressData({
                                    data: e.target.value,
                                    input: e.target.id,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="form-inputs">
                              <h5>Morada de Entrega</h5>
                              <select
                                name="deliveryPlace"
                                id="deliveryPlace"
                                defaultValue={mainAddress?.deliveryPlace}
                                onChange={e =>
                                  changeAddressData({
                                    data: e.target.value,
                                    input: e.target.id,
                                  })
                                }
                              >
                                <option value="Morada de Entrega">
                                  Morada de Entrega
                                </option>

                                <option value="FISCAL">FISCAL</option>
                                <option value="DELIVERY">DELIVERY</option>
                              </select>
                            </div>
                            <div className="form-inputs">
                              <h5>Código da Porta</h5>
                              <Input
                                id="doorCode"
                                name="doorCode"
                                type="text"
                                value={mainAddress?.doorCode}
                                onChange={e =>
                                  changeAddressData({
                                    data: e.target.value,
                                    input: e.target.id,
                                  })
                                }
                              />
                            </div>
                            <div className="form-inputs">
                              <h5>Custo de Entrega</h5>
                              <Input
                                id="deliveryPrice"
                                name="deliveryPrice"
                                type="number"
                                className="input-numero"
                                value={mainAddress?.deliveryPrice}
                                onChange={e =>
                                  changeAddressData({
                                    data: e.target.value,
                                    input: e.target.id,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="form-inputs">
                            <h4>Quantidade de Apartamentos</h4>
                            <div className="form-inputs">
                              <h5>
                                Clique no botão Abaixo para adicionar
                                apartamentos
                              </h5>
                              <Button onClick={() => addApartment()}>
                                Adicionar apartamentos
                              </Button>
                              <div className="form-inputs-apartments">
                                {mainAddress!.apartments.length > 0 &&
                                  mainAddress!.apartments.map(
                                    (i: any, index: any) => (
                                      <div key={i.aptId}>
                                        <div className="form-input-numbers">
                                          <h5>Apt {index + 1}</h5>
                                          <div>
                                            <Input
                                              className="apartment"
                                              onChange={e =>
                                                changeApartamentNumber(
                                                  mainAddress!.fakeId,
                                                  i.aptId,
                                                  e.target.value,
                                                )
                                              }
                                              value={i.value}
                                              name="listApartments"
                                              type="text"
                                            />
                                            <DeleteIcon
                                              className="buttonDeleted"
                                              style={{ cursor: 'pointer' }}
                                              onClick={() =>
                                                removeApt({
                                                  aptId: i.aptId,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <Button
                          onClick={() => saveAddressCard(mainAddress.fakeId)}
                          className="buttonSave"
                        >
                          Salvar Morada
                        </Button>
                        <div />
                      </div>
                    </div>
                    <div className="redLine" />
                    <div className="header-lunch">
                      <h1>Selecionar Pequenos Almoços</h1>
                    </div>
                    <div className="selectLunch">
                      {lunch.map(item => {
                        return (
                          <div className="lunch-item" key={item.id}>
                            <div className="lunch-img-title">
                              <div>
                                <img src={lunchImg} alt="" />
                              </div>
                              <div>
                                <h4>{item.lunchType}</h4>
                              </div>
                            </div>
                            <div className="lunch-cost">
                              <span>Valor</span>
                              <input
                                type="number"
                                placeholder=""
                                step="any"
                                defaultValue={Number(item.smallLunchPrice)}
                                name="lunchs.customLunchCost"
                                disabled={
                                  lunchChecked &&
                                  !lunchChecked.some(
                                    value => value.lunchId === item.id,
                                  )
                                }
                                id={item.id}
                                onChange={e => handleValueLunch(e, item.id)}
                              />
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                defaultValue={item.id}
                                name="lunchs.lunchId"
                                onChange={({ target }) =>
                                  handlePickerLunch(
                                    target.checked,
                                    target.value,
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="bottomPedidos">
                      <Button loading={loading} type="submit">
                        Cadastrar
                      </Button>
                      <Button className="allItems" onClick={handleBack}>
                        Voltar
                      </Button>
                    </div>
                  </FormCadastroCliente>
                </Form>
              </div>
            </div>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default CadastroCliente;
