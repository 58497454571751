import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;

  h1 {
    font-size: 2.4rem;
    margin: 60px 0;
  }

  .bottomPedidos {
    display: flex;
    margin: 40px;
    button {
      margin-right: 20px;
    }
  }

  button.allItems {
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
`;
