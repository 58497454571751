import React from 'react';
import { IClientFormData, INewRequestDataLunchs } from '..';
import lunchImg from '../../../../assets/lunch.png';

interface IProps {
  clientData: IClientFormData;
  pickedLunchAmount(id: string): number;
  increaseLunchAmount(id: string, value: number): void;
  pickedLunchs: INewRequestDataLunchs[];
  handlePickedLunch({
    checked,
    lunchId,
  }: {
    checked: boolean;
    lunchId: string;
  }): void;
}

const Normal: React.FC<IProps> = ({
  increaseLunchAmount,
  clientData,
  handlePickedLunch,
  pickedLunchAmount,
  pickedLunchs,
}) => {
  return (
    <div className="lunch-list">
      <div className="select-lunch">
        {clientData.lunchs.length > 0 &&
          clientData.lunchs.map(item => (
            <div key={item.id} className="lunch-item">
              <div className="lunch-img-title">
                <div>
                  <img src={lunchImg} alt="" />
                </div>
                <div>
                  <h4>
                    {item.lunch.lunchType
                      ? item.lunch.lunchType
                      : 'Nome do Pequeno Almoço'}
                  </h4>
                </div>
              </div>
              <div className="lunch-cost">
                <span>Quantidade</span>
                <input
                  type="number"
                  placeholder=""
                  min={Number(0)}
                  value={pickedLunchAmount(item.lunch.id)}
                  name="lunchs.customLunchCost"
                  id={item.lunch.id}
                  onChange={event => {
                    increaseLunchAmount(
                      event.target.id,
                      Number(event.target.value),
                    );
                  }}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  defaultValue={item.lunch.id}
                  id={item.lunch.id}
                  checked={pickedLunchs.some(i => i.lunchId === item.lunch.id)}
                  name="lunchs.lunchId"
                  onChange={({ target }) =>
                    handlePickedLunch({
                      checked: target.checked,
                      lunchId: target.value,
                    })
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Normal;
