/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import api from '../../../services/api';

import Button from '../../../components/Button';
import {
  FormCadastroPequenosAlmocos,
  Main,
  Section,
  DeleteIcon,
} from './styles';
import Input from '../../../components/Input';
import InputTextArea from '../../../components/InputTextArea';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';

interface ILunchFormData {
  lunchType: string;
  description: string;
  smallLunchPrice: string;
}

interface LunchParams {
  id: string;
}

const EditarAlmocos: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<LunchParams>();
  const { addToast } = useToast();
  const history = useHistory();

  const [model, setModel] = useState<ILunchFormData>({
    lunchType: '',
    description: '',
    smallLunchPrice: '',
  });
  useEffect(() => {
    api.get<ILunchFormData>(`lunch/get/${id}`).then(response => {
      setModel(response.data);
    });
  }, [id]);

  const removeLunch = async () => {
    try {
      setLoading(true);
      const response = await api.delete(`/lunch/delete/${id}`);
      setLoading(false);
      addToast({
        type: 'success',
        title: 'Pequeno Almoço Removido',
        description: 'Pequeno almoço removido com sucesso!',
      });
      history.push('/pequenos-almocos');
    } catch (error: any) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na Atualização Pequeno Almoco',
        description:
          JSON.parse(error.request.response).message ||
          'Falha em atualizar Entregador',
      });
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = useCallback(
    async (data: ILunchFormData) => {
      try {
        // formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          lunchType: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await api.put(`/lunch/update/${id}`, {
          lunchId: id,
          newLunchType: data.lunchType,
          newLunchCost: data.smallLunchPrice,
          newLunchDescription: data.description,
        });

        addToast({
          type: 'success',
          title: 'Atualização de almoço realizado',
          description: 'Almoço editado com sucesso',
        });
        setLoading(false);
        history.push('/pequenos-almocos');
      } catch (err: any) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na Atualização',
          description:
            JSON.parse(err.request.response).message ||
            'Oops ocorreu um erro ao realizar a Atualização',
        });
      }
    },
    [addToast, history, id],
  );

  function handleBack() {
    history.go(-1);
  }

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe o Cadastro de Pequenos Almoços</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div>
            <div className="flex-container">
              <div>
                <div>
                  <h2>Editar Pequeno Almoço</h2>
                </div>
                <div className="deleteItem">
                  <Button
                    type="button"
                    loading={loading}
                    onClick={() => removeLunch()}
                  >
                    Remover Pequeno Almoço
                    <DeleteIcon />
                  </Button>
                </div>
              </div>
              <div>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <FormCadastroPequenosAlmocos>
                    <div className="formulario-cadastro">
                      <div>
                        <div className="form-inputs">
                          <h5>Nome do Pequeno Almoço*</h5>
                          <Input
                            type="text"
                            name="lunchType"
                            defaultValue={model.lunchType}
                          />
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="form-inputs">
                            <h5>Descrição</h5>
                            <InputTextArea
                              className="form-textarea"
                              name="description"
                              defaultValue={model.description}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="form-inputs">
                            <h5>Valor</h5>
                            <Input
                              className="input-valor"
                              name="smallLunchPrice"
                              step="any"
                              type="number"
                              min={Number(1)}
                              defaultValue={model.smallLunchPrice}
                            />
                          </div>
                        </div>
                      </div>
                      <h6>*Campo Obrigatório</h6>
                      <div className="bottomPedidos">
                        <Button loading={loading} type="submit">
                          Salvar
                        </Button>
                        <Button className="allItems" onClick={handleBack}>
                          Voltar
                        </Button>
                      </div>
                    </div>
                  </FormCadastroPequenosAlmocos>
                </Form>
              </div>
            </div>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default EditarAlmocos;
