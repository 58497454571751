import styled, { css } from 'styled-components';
import { Home, Buy, Bag, Calendar, Document } from 'react-iconly';
import { shade } from 'polished';

export const Container = styled.div`
  width: 93px;
  background: var(--secondary);
  display: flex;
  flex-direction: column;

  @media (max-width: 1076px) {
    width: 92px;
  }

  @media (max-width: 768px) {
    width: 108px;
  }

  .topMenu {
    height: 109px;
  }
  .menuBox {
    background: var(--secondary);
    height: 100vh;
  }

  .menuItem {
    display: flex;
    flex-direction: column;
    height: 109px;
  }

  .menuItem a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    background: transparent;
    display: flex;
    width: 79px;
    height: 65px;
    text-decoration: none;
    text-align: center;
    transition: color 0.2s;

    svg {
      color: #ffffff;
      margin: 130px 10px 40px -3px;
    }
    h5 {
      font-size: 0.8rem;
      color: var(--secondary);
    }
  }

  .menuItem a:hover,
  .menuItem a.selected {
    position: relative;
    z-index: 1;
    background: #fcfcfc;
    border-radius: 20px 0 0 20px;
    margin: 35px 0 0px 14px;
    color: ${shade(0.2, '#ffffff')};

    & > .titleMenu {
      position: absolute;
      z-index: 10;
      margin-top: 35px;
      padding: 20px;
      h5 {
        font-size: 1rem;
      }
    }

    > svg {
      width: 24px;
      height: 24px;
      margin: -20px 0 2px 0;
      color: var(--secondary);
    }
  }

  .menuItem a::before {
    content: '';
    position: absolute;
    z-index: 1;

    background: transparent;
    bottom: -50px;
    right: 0;
    height: 50px;
    width: 25px;
    border-top-right-radius: 20px;
    box-shadow: 0 -25px 0 0 #fcfcfc;
  }

  .menuItem a::after {
    content: '';
    position: absolute;
    z-index: 1;
    background: transparent;
    bottom: 65px;
    right: 0;
    height: 50px;
    width: 25px;
    border-top-right-radius: 20px;
    box-shadow: 0 -25px 0 0 #fcfcfc;
    transform: rotateX(180deg);
    > .titleMenu h5 {
      font-size: 1rem;
      margin: 2px;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
`;
const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const HomeIcon = styled(Home)`
  ${iconCSS}
`;
export const BuyIcon = styled(Buy)`
  ${iconCSS}
`;
export const BagIcon = styled(Bag)`
  ${iconCSS}
`;
export const CalendarIcon = styled(Calendar)`
  ${iconCSS}
`;
export const DocumentIcon = styled(Document)`
  ${iconCSS}
`;
