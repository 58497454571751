import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { Container, Content, Background } from './styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SignInFormData {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string()
            .required('Usuário ou email obrigatório!')
            .email('E-mail obrigatório'),
          password: Yup.string().required('Senha obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        const { role } = await signIn({
          username: data.username,
          password: data.password,
        });
        setLoading(false);

        let historyPath = '/';
        switch (role) {
          case 'ADMIN':
            historyPath = '/admin';
            break;
          case 'CLIENT':
            historyPath = '/cliente';
            break;
          case 'PRODUCTION':
            historyPath = '/production';
            break;
          default:
            historyPath = '/';
        }
        history.push(historyPath);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
        });
      }
    },
    [signIn, addToast, history],
  );
  return (
    <Container>
      <div className="sideBar">
        <div>
          <h1>UM PEQUENO-ALMOÇO DE HOTEL À SUA PORTA</h1>
        </div>
        <div>
          <h2>
            Administre, controle e faça novos pedidos através da Plataforma,
            aumente sua produtividade e a satisfação de seu cliente.
          </h2>
        </div>
      </div>
      <Background />
      <Content>
        <img src={logoImg} alt="Bib" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <h5>Login ou E-mail</h5>
          <Input name="username" />
          <h5>Senha</h5>
          <Input type="password" name="password" />
          <Button loading={loading} type="submit">
            Login
          </Button>
          <div className="formPass">
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-check-input"
                name="remind-password"
                id="remind-password"
                value="checkedValue"
              />
              <h5>Lembrar senha</h5>
            </div>
            <Link to="/forgotpassword">Esqueci a Senha</Link>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default Login;
