/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { debounce } from 'lodash';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section, SearchIcon } from './styles';
import Input from '../../../components/Input/index';

interface LunchFormData {
  id: string;
  lunchType: string;
  description: string;
  smallLunchPrice: number;
}

const defaultProps: LunchFormData[] = [];

const PequenoAlmocos: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [initialLunch, setInitialLunch] = useState<any>([]);
  const [lunch, setLunch] = useState<any>();

  const fetchLunchs = async () => {
    try {
      setLoading(true);
      const response = await api.get<LunchFormData>('/lunch/list');
      setInitialLunch(response.data);
      setLunch(response.data);
      setDataSearch(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchLunchs();
  };
  useEffect(didMount, []);

  const debouncedData = useCallback(
    debounce(() => {
      if (search !== '') {
        const mySearch = lunch.filter(
          (c: { lunchType: string; description: string }) =>
            c.lunchType.toLowerCase().includes(search.toLocaleLowerCase()) ||
            c.description.toLowerCase().includes(search.toLocaleLowerCase()),
        );
        if (search.length) {
          setDataSearch(mySearch);
        }
      } else {
        setDataSearch(lunch);
      }
    }, 500),
    [lunch, search],
  );

  useEffect(() => {
    debouncedData();
  }, [search, fetchLunchs]);

  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Acompanhe o Cadastro de Pequenos Almoços</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>
      <Section>
        <div className="sectionCard">
          <div className="flex-container">
            <div>
              <h2>Cadastro de Pequenos Almoços</h2>
            </div>

            <div className="menu-cadastro">
              <div className="item-cadastrados">Cadastrados</div>
              <Link to="/cadastro-pequenos-almocos">
                <div className="item-novo-cadastro">Novo Cadastro</div>
              </Link>
            </div>
            <Form ref={formRefSearch} onSubmit={() => {}}>
              <div className="searchBar">
                <SearchIcon />
                <Input
                  name="search"
                  type="text"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Procure por nome ou item da descrição"
                />
              </div>
            </Form>
            <div className="box-list">
              <div>
                {!loading && <h4>Carregando</h4> &&
                  dataSearch?.length !== 0 &&
                  dataSearch?.map((item: any) => {
                    return (
                      <div key={item.id} className="list-lunchs">
                        <div>
                          <div>
                            <h3>{item.lunchType}</h3>
                            <span>{item.description}</span>
                          </div>
                        </div>
                        <div className="listPrice">
                          <span>€</span>
                          <span>{item.smallLunchPrice}</span>
                        </div>
                        <div>
                          <div className="bottomPedidos">
                            <Link to={`/editar-almocos/${item.id}`}>
                              <Button className="allItems">Editar</Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {lunch?.length === 0 && (
                  <h4>Você não possui Pequenos Almoços cadastrados...</h4>
                )}
                {loading && (
                  <div className="loadingData">
                    <span>Carregando...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default PequenoAlmocos;
