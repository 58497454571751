/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section, FormCadastroPequenosAlmocos } from './styles';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input/index';

interface DeliverymanFormData {
  id: string;
  name: string;
  documentNumber: string;
  licensePlate: string;
  vehicleDocument: string;
  contactNumber: string;
  referenceContactNumber: string;
  additionalData: string;
}

const CadastroEntregadores: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: DeliverymanFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),

          contactNumber: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.post('/deliveryman/create', data);

        addToast({
          type: 'success',
          title: 'Cadastro realizado',
          description: 'Cadastro efetuado com sucesso',
        });
        setLoading(false);
        history.push('/entregadores');
      } catch (err: any) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            JSON.parse(err.request.response).message ||
            'Oops ocorreu um erro ao realizar o cadastro',
        });
        window.scrollTo(0, 0);
      }
    },
    [addToast, history],
  );

  function handleBack() {
    history.go(-1);
  }
  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Acompanhe o Cadastro de seus entregadores</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>
      <Section>
        <div className="sectionCard">
          <h2>Cadastro de Entregadores</h2>
          <div className="flex-container">
            <div className="menu-cadastro">
              <Link to="/entregadores">
                <div className="item-novo-cadastro">Cadastrados</div>
              </Link>
              <div className="item-cadastrados">Novo Cadastro</div>
            </div>

            <div>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormCadastroPequenosAlmocos>
                  <div className="formulario-cadastro">
                    <div>
                      <div className="form-inputs">
                        <h5>Nome do Entregador*</h5>
                        <Input type="text" name="name" />
                      </div>
                      <div className="form-inputs">
                        <h5>Documento</h5>
                        <Input type="text" name="documentNumber" />
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Placa do Veículo</h5>
                          <Input
                            type="text"
                            className="input-veiculo"
                            name="licensePlate"
                          />
                        </div>
                        <div className="form-inputs">
                          <h5>Documento do Veículo</h5>
                          <Input
                            type="text"
                            className="input-veiculo"
                            name="vehicleDocument"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Telefone de Contato*</h5>
                          <Input
                            maxLength={10}
                            type="text"
                            name="contactNumber"
                            className="input-contato"
                          />
                        </div>
                        <div className="form-inputs">
                          <h5>Telefone de Referência</h5>
                          <Input
                            type="text"
                            name="referenceContactNumber"
                            className="input-contato"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Informações Adicionais</h5>
                          <Input
                            type="text"
                            name="additionalData"
                            className="form-info"
                          />
                        </div>
                      </div>
                    </div>
                    <h6>*Campos Obrigatórios</h6>
                    <div className="bottomPedidos">
                      <Button loading={loading} type="submit">
                        Cadastrar
                      </Button>
                      <Button className="allItems" onClick={handleBack}>
                        Voltar
                      </Button>
                    </div>
                  </div>
                </FormCadastroPequenosAlmocos>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default CadastroEntregadores;
