/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Modal from 'react-modal';
import { CloseSquare } from 'react-iconly';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import { Container, Content } from './styled';
import Button from '../Button';

interface OrderModalProps {
  isOpen: boolean;
  id: string;
  client: string;
  onRequestClose: () => void;
  verifyIfCancelAll: (deleteAll: boolean) => void;
}

const customStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
};

export function CancelOrderModal({
  isOpen,
  onRequestClose,
  client,
  id,
  verifyIfCancelAll,
}: OrderModalProps) {
  function onClickFunction(deleteAll: boolean) {
    verifyIfCancelAll(deleteAll);
    onRequestClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyle}
      overlayClassName="react-modal-overlay"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <CloseSquare />
      </button>
      <Container>
        <Content>
          <h1>Deseja cancelar todos os pedidos de {client}?</h1>
          <div className="bottomPedidos">
            <Button onClick={() => onClickFunction(true)}>
              Cancelar todos
            </Button>
            <Button onClick={() => onClickFunction(false)}>Somente esse</Button>
          </div>
        </Content>
      </Container>
    </Modal>
  );
}
