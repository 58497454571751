import React from 'react';

import { Link } from 'react-router-dom';
import logoImg from '../../assets/logo.svg';
import NotificationsClient from '../NotificationsClient';

import { Container, Main, LogoutIcon } from './styles';
import { useAuth } from '../../hooks/auth';
import Button from '../Button';

const Header: React.FC = () => {
  const { signOut } = useAuth();
  return (
    <>
      <Container>
        <Link to="/cliente">
          <img src={logoImg} alt="Breakfast In Box" />
        </Link>

        <Main>
          <div>
            <div className="notifications">
              <NotificationsClient />
            </div>
            <div className="profile">
              <Button className="logout" type="button" onClick={signOut}>
                <LogoutIcon />
                <h4>Logout</h4>
              </Button>
            </div>
          </div>
        </Main>
      </Container>
    </>
  );
};

export default Header;
