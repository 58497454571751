import styled from 'styled-components';

interface CircleProps {
  isVisible: boolean;
}

export const Container = styled.div`
  position: relative;

  svg {
    margin: 4px 0;
    width: 38px;
    height: 38px;
    color: var(--secondary);
  }

  .notificationsDropdown {
    position: relative;
    display: inline-block;
  }

  .notifications {
    display: none;
    z-index: 999999;
    position: absolute;
    top: -10px;
    right: -80px;
    width: 530px;
    background: var(--background);
    border-radius: 10px;
    padding: 4px 4px;
    border: 1px solid #c2c2c2;
    font-size: 1.3rem;
    font-weight: 500;
    color: #c2c2c2;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  }

  .msgItem {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--background);
    margin: 0 10px;
    padding: 0px;
    align-items: left;
    border-bottom: 1px solid var(--line);

    &:hover {
      background: filter(0.2, '#c2c2c2');
    }

    .time {
      h6 {
        text-decoration: uppercase;
        color: var(--secondary);
      }
    }

    a {
      text-decoration: uppercase;
    }

    > div {
      display: flex;
      margin: 8px 10px;
      .item {
        display: flex;
        .active {
          color: var(--primary);
        }
      }
    }
    /* border-bottom: 1px solid var(--line-table); */

    span {
      color: var(--secondary);
    }
    h6 {
      margin-bottom: 5px;
      text-decoration: none;
    }
    .notificationConfirmar {
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 20px;
      border-radius: 10px;
      background: var(--blue);
      margin-left: 10px;
      span {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .notificationProducao {
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 20px;
      border-radius: 20px;
      background: var(--green-light);
      margin-left: 10px;
      span {
        font-size: 1.3rem;
        text-align: center;
        color: var(--secondary);
      }
    }

    .notificationPendencia {
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 20px;
      border-radius: 20px;
      background: var(--red);
      margin-left: 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .notificationFinalizado {
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 20px;
      border-radius: 20px;
      background: var(--green-dark-plus);
      margin-left: 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .notificationEntregue {
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 20px;
      border-radius: 20px;
      background: var(--green);
      margin-left: 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .notificationCancelado {
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 20px;
      border-radius: 20px;
      background: var(--primary);
      margin-left: 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }
  }

  .notificationsDropdown:hover .notifications {
    display: block;
  }

  .notificationsDropdown:hover .notificationsDropdown-profile {
    display: block;
  }
`;

export const Circle = styled.div<CircleProps>`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--green);
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;
