/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Modal from 'react-modal';
import { CloseSquare } from 'react-iconly';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Content } from './styles';

import Button from '../Button';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface OrderModalProps {
  isOpen: boolean;
  id: string;
  onRequestClose: () => void;
  onConfirmDeliveryMan: (deliverymanId: string, id: string) => void;
  onCancelDeliveryMan: () => void;
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

export function DeliveryManModal({
  isOpen,
  onRequestClose,
  id,
  onConfirmDeliveryMan,
  onCancelDeliveryMan,
}: OrderModalProps) {
  const { addToast } = useToast();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>('');

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar dados',
        description: 'Oops ocorreu um erro ao carregar dados de entregadores',
      });
    }
  };

  useEffect(() => {
    getAllDeliveryman();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <CloseSquare />
      </button>
      <Container>
        <Content>
          <div className="modalDeliveryMan">
            <h1>Atenção!</h1>
            <h2>
              É necessário selecionar um motorista para a entrega do pedido
              antes de mudar o status para "Entregue".
            </h2>
            <div className="formDeliveryMan">
              <h2>Selecionar Motorista</h2>
              <select
                name="deliveryman"
                defaultValue=""
                id="deliveryman"
                onChange={event => handleDeliveryman(event.target.value)}
              >
                <option value="">Selecionar Motorista</option>
                {deliverymanList &&
                  deliverymanList?.map(driver => (
                    <option key={driver.id} value={driver.id}>
                      {driver.name} - {driver.licensePlate}
                    </option>
                  ))}
              </select>
            </div>
            <div className="bottomPedidos">
              <Button onClick={() => onConfirmDeliveryMan(deliverymanId, id)}>
                Confirmar
              </Button>
              <Button className="allItems" onClick={onCancelDeliveryMan}>
                Voltar
              </Button>
            </div>
          </div>
        </Content>
      </Container>
    </Modal>
  );
}
