/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-sequences */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { FormHandles } from '@unform/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, subMonths, subDays, parseISO } from 'date-fns';

import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section } from './styles';
import { toEuroCurrency } from '../../../utils/toEuro';
import {
  ClientAllTotalizer,
  IClientAddressList,
  IClientList,
} from './index.interface';

const TotalizadoresCliente: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [clientTotalizer, setClientTotalizer] = useState<ClientAllTotalizer>({
    rowData: [],
    column: [],
    totalQuantity: 0,
    biggest: 0,
    totalCanceledAmount: '',
    totalCanceledQtd: 0,
    totalDeliveredAmount: '',
    totalDeliveredQtd: 0,
    totalCost: '',
    client: '',
  });
  const [clientList, setClientList] = useState<IClientList[]>([]);
  const [clientAddressList, setClientAddressList] = useState<
    IClientAddressList[]
  >([]);
  const [clientId, setClientId] = useState<string>('all');
  const [addressId, setAddressId] = useState<string>('all');
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });
  const [lunchsAmount, setLunchsAmount] = useState<number>(0);

  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

  const fetchClientAddress = async () => {
    try {
      setLoading(true);
      const response = await api.get<IClientAddressList[]>(
        `/totalizer/client/list/address/${clientId}`,
      );
      setClientAddressList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchClientsList = async () => {
    try {
      setLoading(true);
      const response = await api.get<IClientList[]>(`/totalizer/client/list`);
      setClientList(response.data);

      if (clientId !== 'all') {
        fetchClientAddress();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchClients = async () => {
    try {
      setLoading(true);

      let url = '';

      if (clientId === 'all') {
        setAddressId('all');
        url = `/totalizer/client/all?start=${formatDate(
          dateFilter.start,
        )}&end=${formatDate(dateFilter.end)}`;
      } else if (clientId !== 'all' && addressId === 'all') {
        url = `/totalizer/client?start=${formatDate(
          dateFilter.start,
        )}&end=${formatDate(dateFilter.end)}&clientId=${clientId}`;
      } else if (clientId !== 'all' && addressId !== 'all') {
        url = `/totalizer/client/address?start=${formatDate(
          dateFilter.start,
        )}&end=${formatDate(
          dateFilter.end,
        )}&clientId=${clientId}&addressId=${addressId}`;
      }

      const response = await api.get<ClientAllTotalizer>(url);

      setClientTotalizer(response.data);
      setLunchsAmount(response.data.column.length - 5);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
    fetchClientsList();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };

  const handleClientId = (id: string) => {
    setClientAddressList([]);
    setAddressId('all');
    setClientId(id);
  };

  const handleAddressId = (id: string) => setAddressId(id);

  useEffect(didMount, [dateFilter, clientId, addressId]);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Totalizadores Cliente</h1>
            <h3>Acompanhe os totalizadores dos relatórios de cliente</h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <div>
                    <h4>Período específico</h4>
                    <select
                      defaultValue=""
                      name="periodo-especifico"
                      id="periodo-especifico"
                      onChange={e =>
                        handleDateFilter(e.target.value, e.target.id)
                      }
                    >
                      <option id="last" value={7}>
                        Últimos 07 dias
                      </option>
                      <option id="last" value={15}>
                        Últimos 15 dias
                      </option>
                      <option id="last" value={30}>
                        Últimos 30 dias
                      </option>
                      <option id="last" value={60}>
                        Últimos 60 dias
                      </option>
                      <option id="last" value={90}>
                        Últimos 90 dias
                      </option>
                    </select>
                  </div>
                  <div>
                    <div className="delivery-date">
                      <div>
                        <span>Início</span>
                        <DatePicker
                          className="datapicker"
                          selected={dateFilter.start}
                          maxDate={dateFilter.end}
                          onChange={date => handleStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="I have been cleared!"
                        />
                      </div>
                      <div>
                        <span>Fim</span>
                        <DatePicker
                          className="datapicker"
                          minDate={dateFilter.start}
                          selected={dateFilter.end}
                          onChange={date => handleEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="I have been cleared!"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Cliente</h4>
                    <select
                      defaultValue=""
                      name="client-list"
                      id="client-list"
                      disabled={!clientList || loadingV2}
                      onChange={e => handleClientId(e.target.value)}
                    >
                      <option id="last" value="all">
                        Todos
                      </option>
                      {clientList &&
                        clientList.map(i => (
                          <option key={i.id} id="last" value={i.id}>
                            {i.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {clientId !== 'all' && clientAddressList.length !== 0 && (
                    <div>
                      <h4>Morada(s)</h4>
                      <select
                        defaultValue=""
                        name="address-list"
                        disabled={!clientAddressList || loadingV2}
                        id="address-list"
                        onChange={e => handleAddressId(e.target.value)}
                      >
                        <option id="last" value="all">
                          Todos
                        </option>
                        {clientAddressList &&
                          clientAddressList.map(i => (
                            <option
                              key={i.id}
                              id="last"
                              value={i.id}
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '15em',
                              }}
                            >
                              {`${i.city} - ${i.neighborhood} - ${i.street} - ${i.streetNumber}`}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <h2>Pedidos no Período</h2>
            <div className="flex-container">
              <table style={{ width: '100%' }} id="customers">
                <thead>
                  <tr>
                    <th>Data de Entrega</th>
                    {clientTotalizer.column &&
                      clientTotalizer.column.map(i => (
                        <>
                          <th>{i.columnName}</th>
                        </>
                      ))}
                    <th>Cancelados</th>
                    <th>Entregues</th>
                    <th>Cancelados $</th>
                    <th>Entregues $</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingV2 &&
                    clientTotalizer?.rowData &&
                    clientTotalizer!.rowData.map((i, index) => (
                      <tr key={String(index)}>
                        <td>{i.deliveryDate}</td>
                        {i.lunchs.map((z, lunchIndex) => (
                          <td>{z.quantity}</td>
                        ))}
                        <td>{i.canceledQuantity}</td>
                        <td>{i.deliveryQuantity}</td>
                        <td>{toEuroCurrency(i.canceledAmount)}</td>
                        <td>{toEuroCurrency(i.deliveryAmount)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr style={{ fontWeight: 'bold' }}>
                    <td>TOTAL</td>
                    {clientTotalizer.column.map(i => (
                      <td>{i.columnTotalizer}</td>
                    ))}
                    <td>{clientTotalizer?.totalCanceledQtd}</td>
                    <td>{clientTotalizer?.totalDeliveredQtd}</td>
                    <td>{clientTotalizer?.totalCanceledAmount}</td>
                    <td>{clientTotalizer?.totalDeliveredAmount}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div />
          </div>
        </Section>
      </Main>
    </>
  );
};

export default TotalizadoresCliente;
