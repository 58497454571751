import React from 'react';

import { Link } from 'react-router-dom';
import logoImg from '../../assets/logo.svg';
import Notifications from '../Notifications';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  Main,
  ChevronDownIcon,
  PaperPlusIcon,
  CategoryIcon,
  SettingIcon,
  LogoutIcon,
} from './styles';
import Button from '../Button';

const HeaderAdmin: React.FC = () => {
  const { signOut } = useAuth();
  return (
    <>
      <Container>
        <Link to="/admin">
          <img src={logoImg} alt="Breakfast In Box" />
        </Link>
        <div className="menuContainer">
          <PaperPlusIcon />

          <div className="dropdown">
            <span>Cadastros</span>
            <ChevronDownIcon />
            <div className="dropdown-content">
              <Link to="/clientes">
                <div className="dropItem">
                  <p>Clientes</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/entregadores">
                <div className="dropItem">
                  <p>Entregadores</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/pequenos-almocos">
                <div className="dropItem">
                  <p>Pequenos Almoços</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/pedidos">
                <div className="dropItem">
                  <p>Pedidos</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <CategoryIcon />
          <div className="dropdown">
            <span>Relatórios</span>
            <ChevronDownIcon />
            <div className="dropdown-content">
              <Link to="/relatorio-cliente">
                <div className="dropItem">
                  <p>Clientes</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/relatorio-negocio">
                <div className="dropItem">
                  <p>Negócio</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/relatorio-entregadores">
                <div className="dropItem">
                  <p>Entregadores</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/relatorio-pequenos-almocos">
                <div className="dropItem">
                  <p>Pequenos Almoços</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/relatorio-totalizador-cliente">
                <div className="dropItem">
                  <p>Totalizadores Cliente</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
              <Link to="/relatorio-totalizador-negocio">
                <div className="dropItem">
                  <p>Totalizadores Negóc.</p>
                  <span>
                    <div />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <Main>
          <div>
            <div className="settings">
              <Link to="/painel">
                <SettingIcon />
              </Link>
            </div>
            <div className="notifications">
              <Notifications />
            </div>
            <div className="profile">
              <Button className="logout" type="button" onClick={signOut}>
                <LogoutIcon />
                <h4>Logout</h4>
              </Button>
            </div>
          </div>
        </Main>
      </Container>
    </>
  );
};

export default HeaderAdmin;
