/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format, parseISO } from 'date-fns';
import { CSVLink } from 'react-csv';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import Button from '../../../components/Button';
import { Main, Section, ChevronLeftIcon, SourceGraphic } from './styles';

import PDFDeliveryManExplodeFinal from './PDFDeliveryManExplodeInicial';

interface IDeliveryAddress {
  street: string;
  streetNumber: string;
  neighborhood: string;
  doorCode: string;
  deliveryPlace: string;
}

interface IDeliverymanLunch {
  quantity: number;
  lunchCost: number;
  lunchType: string;
}
interface IDeliveryman {
  id: string;
  deliverymanId: string;
  client: string;
  deliverymanName: string;
  deliveryDate: string;
  lunchs: IDeliverymanLunch[];
  deliveryAddress: IDeliveryAddress[];
  total: number;
  totalDeliverys: number;
}

interface IDeliverymanFull {
  deliverymanName: string;
  data: IDeliveryman[];
}

const RelatorioEntregadoresExplodeInicial: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const history = useHistory();
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [deliverymanName, setDeliverymanName] = useState<string>();
  const [clients, setClients] = useState<IDeliverymanFull>({
    data: [],
    deliverymanName: '',
  });

  const { id, date }: { id: string; date: string } = useParams();

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IDeliverymanFull>(
        `/reports/deliveryman?start=${date}&end=${date}&deliverymanId=${id}`,
      );
      setClients(response.data);
      setDeliverymanName(response.data.deliverymanName);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  useEffect(didMount, []);

  const clientDataList = clients?.data.map(client => {
    const address = client.deliveryAddress.map(add => {
      return {
        addresses: `${add.street} n${add.streetNumber} - ${add.neighborhood}`,
      };
    });

    let lc: any = [];
    client.lunchs.map(lunch => {
      if (lc.length > 0) {
        lc[0] = `${lc[0]} | ${lunch.quantity} - ${lunch.lunchType}`;
      } else {
        lc[0] = `${lunch.quantity} - ${lunch.lunchType}`;
      }
      return {
        ...lunch,
      };
    });

    return {
      ...client,
      deliveryAddress: address[0].addresses,
      lunchs: lc[0],
    };
  });

  const csvHeaders = [
    { label: 'Cliente', key: 'client' },
    { label: 'Endereço de Entrega', key: 'deliveryAddress' },
    { label: 'Quantidade', key: 'totalDeliverys' },
    { label: 'Pedido', key: 'lunchs' },
    { label: 'Valor Total Gerados', key: 'total' },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Entregadores</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="clientName">
              <Link to="/" onClick={() => history.go(-1)}>
                <ChevronLeftIcon />
                <h2>
                  {deliverymanName} - {format(parseISO(date), 'dd/MM/yyyy')}
                </h2>
              </Link>
            </div>
            <div className="flex-container">
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço de Entrega</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Valor Gerado</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {clients?.data.map(i => (
              <div key={i.id} className="flex-container">
                <div>{i.client}</div>
                <div>
                  {i.deliveryAddress[0].street}{' '}
                  {i.deliveryAddress[0].streetNumber} -{' '}
                  {i.deliveryAddress[0].neighborhood}
                </div>
                <div>{i.totalDeliverys}</div>
                <div className="listLunch">
                  {i.lunchs.map(z => (
                    <div key={z.lunchType}>
                      {z.quantity} - {z.lunchType}
                    </div>
                  ))}
                </div>
                <div>{i.total}</div>
                <div>
                  <Link to={`/relatorio-entregadores-explode-final/${i.id}`}>
                    <Button className="button-plus">
                      <span>+</span>
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFDeliveryManExplodeFinal
                        client={clients}
                        deliveryDate={date}
                        deliverymanName={clients.deliverymanName}
                      />
                    }
                    fileName={`relatorio-cliente-detalhe-${deliverymanName}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Gerar Relatório</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clientDataList}
                      headers={csvHeaders}
                      separator=";"
                      filename={`relatorio-cliente-${deliverymanName}.csv`}
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioEntregadoresExplodeInicial;
