import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useToast } from '../../hooks/toast';

import logoImg from '../../assets/logo.svg';
import { Container, Content, Background } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/mail/forgotpassword', {
          email: data.email,
        });

        history.push('/');
        addToast({
          type: 'success',
          title: 'Email de recuperação enviado!',
          description:
            'Enviamos um email com um link para a recuperação de senha.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na recuperação de senha',
          description:
            'Ocorreu um erro ao tentar realizar a recuperação de senha, tente novamente',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <div className="sideBar">
        <div>
          <h1>UM PEQUENO-ALMOÇO DE HOTEL À SUA PORTA</h1>
        </div>
        <div>
          <h2>
            Administre, controle e faça novos pedidos através da Plataforma,
            aumente sua produtividade e a satisfação de seu cliente.
          </h2>
        </div>
      </div>
      <Background />
      <Content>
        <img src={logoImg} alt="Bib" />
        <h1>DIGITE SEU E-MAIL PARA RECUPERAR A SENHA</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h5>E-mail</h5>
          <Input name="email" id="email" />

          <Button loading={loading} type="submit">
            Recuperar senha
          </Button>
          <div className="formPass">
            <div className="checkbox" />
            <Link to="/">Já tenho a senha</Link>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
