import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { Search, ChevronLeft } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;

  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    button.allItems {
      float: right;
      width: 274px;
      height: 47px;
      margin: 30px 20px 50px 0;
      background: transparent;
      border: 2px solid var(--red);
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--red);
      transition: background-color 0.2s;

      &:hover {
        color: ${(shade(0.2), '#BF1E2D')};
      }
    }

    svg {
      width: 30px;
      height: 28px;
      font-weight: 700;
      color: var(--primary);
      position: absolute;
      margin: 64px 0 0 60px;
    }
  }

  h2 {
    color: var(--primary);
    font-size: 2.4rem;
    margin: 64px 0 29px 100px;
  }

  .selectContainer {
    display: flex;
    align-items: left;
    justify-content: space-between;
    text-align: left;
    width: 94%;
    background: var(--table-color);

    padding-right: 10px;
    margin: 5px 0 10px 41px;

    & > div {
      width: 140px;
      display: flex;
      align-items: left;
      justify-content: left;
      text-align: left;
    }

    .selectContent {
      width: 840px;
      display: flex;
      align-items: left;
      margin: 20px 0;

      padding: 6px 0 16px 36px;

      h4 {
        margin-bottom: 15px;
        color: var(--primary);
      }

      & > div {
        margin: 0 50px 5px 0;
      }

      select {
        border: 1px solid var(--primary);
        color: var(--primary);
        width: 230px;
        height: 47px;
        border-radius: 20px;
        padding: 12px 18px;
      }
    }
  }

  .flex-container {
    border-bottom: 1px solid var(--line);
    display: flex;
    align-items: left;
    justify-content: space-between;
    text-align: left;
    background: var(--table-color);

    padding-right: 10px;
    margin: 5px 40px 20px 71px;

    & > div {
      height: 100%;
      width: 140px;
      display: flex;
      align-items: left;
      justify-content: left;
      text-align: left;
    }

    .listLunch {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    .item {
      color: var(--background);
    }

    button.button-plus {
      width: 30px;
      height: 30px;
      background: var(--primary);
      margin: -10px 0 16px 40px;
      transition: color 0.2s;

      & > span {
        margin: -8px 2px 2px -6px;
        font-size: 2rem;
        font-weight: 400;

        text-align: center;
      }
      &:hover {
        background: ${(shade(0.2), '#EB5757')};
      }
    }
  }

  .delivery-date {
    display: flex;
    margin: 0 40px;
    > div {
      display: flex;
      flex-direction: column;
    }
    span {
      color: var(--primary);
      margin-bottom: 10px;
    }

    .datapicker {
      width: 195px;
      height: 47px;
      border: 0;
      border-radius: 20px;
      margin-right: 50px;
      padding: 0 20px;
      background: var(--background);
      border: 1px solid var(--primary);
      color: var(--primary);
    }
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;
export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
  ${iconCSS}
`;

export const SourceGraphic = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
  h1 {
    margin: 0 29px 14px 35px;

    font-size: 2.4rem;
    font-weight: 500;
    color: var(--primary);
  }

  .leftGraphic {
    width: 560px;
    height: 330px;
    padding: 44px 43px;
    background: var(--background);
    box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.05);
  }

  .rightGraphic {
    width: 560px;
    height: 330px;
    padding: 44px 43px;
    background: var(--background);
    box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.05);
  }
`;
