import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Search, Delete, EditSquare } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;

  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    color: var(--primary);
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 20px 20px -2px rgba(0, 0, 0, 0.05);

    margin: 25px 0 20px 5px;
    padding: 40px;

    > div {
      display: flex;
      justify-content: space-between;
    }
    .menu-cadastro {
      width: 100%;
      max-width: 815px;
      border-bottom: 2px solid var(--line-table);
      display: flex;
      align-items: center;
      justify-content: left;
      text-decoration: none;
      margin-top: 30px;
    }

    .item-cadastrados {
      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid var(--primary);

      font-size: 1.8rem;
      font-weight: 400;
      color: var(--primary);
    }

    .item-novo-cadastro {
      margin-right: 47px;

      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 400;
      border: 0;
      background: #e8ebf1;
      text-decoration: none;
      color: var(--secondary);

      &:hover {
        border-top: 2px solid var(--primary);
      }
    }

    .bottomPedidos {
      display: flex;
      margin-top: 10px;
      padding-bottom: 15px;
      > button {
        margin-right: 40px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  font-size: 40px;
  flex-shrink: 0;
`;

export const SearchIcon = styled(Search)`
  ${iconCSS}
`;
export const EditIcon = styled(EditSquare)`
  ${iconCSS}
`;
export const DeleteIcon = styled(Delete)`
  ${iconCSS};
  width: 30px;
  color: var(--primary);
  margin-left: 10px;
`;

export const FormCadastroCliente = styled.div`
  display: flex;
  flex-direction: column;

  .formulario-cadastro {
    margin-top: 54px;

    div {
      display: flex;
      align-items: flex-start;
      justify-content: left;
      & + div {
        margin-bottom: 30px;
      }
    }

    .form-inputs {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      h5,
      h4 {
        font-size: 1.6rem;
        font-weight: 400;
        margin-left: 20px;
        margin-bottom: 4px;
      }

      select {
        border: 0;
        width: 320px;
        height: 47px;
        border-radius: 20px;
        padding: 0 10px;
        background: var(--input);
      }

      input {
        border: 0;
        border-radius: 20px;
        width: 320px;
        height: 28px;
        padding: 10px 5px;
        background: var(--input);
      }

      input.input-email {
        width: 450px;
      }
      input.input-numero {
        width: 100px;
      }
      input.apartment {
        width: 50px;
      }
      input.addressType {
        padding: 10px 20px;
      }
    }
    .buttonSave {
      margin-bottom: 30px;
    }
  }

  .morada {
    margin-top: 30px;

    h2 {
      font-size: 2.4rem;
      font-weight: 500;
    }
  }
  .form-inputs-apartments {
    display: flex;

    flex-wrap: wrap;
    flex-direction: row;
    h5 {
      margin: 10px 10px 20px 0;
    }

    .form-input-numbers {
      display: flex;
      flex-direction: column;
      margin: 10px 10px 0 0;
    }
  }

  //cardMoradas
  .cardMorada {
    width: 520px;
    display: flex;
    flex-direction: column;
    margin: 30px 0 20px 0;

    h3 {
      color: var(--primary);
      margin-bottom: 10px;
    }

    .enderecoCard {
      width: 390px;
      display: flex;
      > div {
        display: flex;
        margin-left: 30px;
        margin-right: 30px;
      }
      .buttonsCards {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 20px;
          color: var(--primary);
        }
      }
    }
  }

  .header-lunch {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1 {
      font-size: 2.4rem;
      color: var(--primary);
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }

  .selectLunch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1079px;

    .lunch-item {
      display: flex;
      width: 100%;
      max-width: 460px;

      align-items: center;
      justify-content: left;
      > div:last-child {
        margin-left: 30px;
      }

      img {
        margin-right: 25px;
      }

      > div:first-child {
        margin-bottom: 30px;
      }

      .lunch-img-title {
        display: flex;
        align-items: center;
        width: 220px;
      }

      .lunch-cost {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        input {
          width: 122px;
          height: 40px;
          border-radius: 20px;
          background: var(--input);
          border: 0;
          padding: 0 10px;
          text-align: center;
          margin-bottom: 40px;
        }

        span {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
      input[type='checkbox'] {
        margin-bottom: 10px;
        margin: 0 30px 10px 0;
        position: relative;
        width: 28px;
        height: 28px;
        color: var(--secondary);
        border: 4px solid var(--primary);
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        &::before {
          position: absolute;
          content: '';
          display: block;
          top: -1px;
          left: 4px;
          width: 8px;
          height: 14px;
          border-style: solid;
          border-color: var(--input);
          border-width: 0 4px 4px 0;
          transform: rotate(45deg);
          opacity: 0;
        }
        &:checked {
          color: var(--secondary);
          border-color: var(--primary);
          background: var(--primary);
          &::before {
            opacity: 1;
          }
          ~ label::before {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }
        }
      }
    }
  }
`;

export const SelectContract = styled.div`
  max-width: 635px;
  margin: 40px 30px 36px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

interface RadioBoxProps {
  isActive: boolean;
}

export const RadioBox = styled.button<RadioBoxProps>`
  height: 47px;
  width: 300px;
  border: 1px solid var(--primary);
  border-radius: 20px;

  background: ${props => (props.isActive ? '#BF1E2D' : '#F3F3F3')};

  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  .ratio-check {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 60px;
    background: ${props => (props.isActive ? '#D7D7D7' : '#F3F3F3')};
    border: 1px solid var(--primary);

    transition: border-color 0.2s;

    &:hover {
      border-color: var(--red);
    }

    span {
      display: inline-block;
      align-items: center;
      margin-left: 10px;
      margin-top: 2px;
      font-size: 1.6rem;
      font-weight: 700;
      color: ${props => (props.isActive ? '#FFFFFF' : '#2E3135')};
    }
  }
`;
