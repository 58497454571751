/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import api from '../../../services/api';
import Select from '../../../components/InputSelect';

import Button from '../../../components/Button';
import {
  Main,
  Section,
  FormCadastroPequenosAlmocos,
  DeleteIcon,
} from './styles';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input/index';

interface IProfileData {
  name: string;
  contactNumber: string;
  username: string;
  job: string;
  userType: string;
  password: string;
}

interface IProfileParams {
  id: string;
}

const EditarUsuarios: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<IProfileParams>();

  const options = [
    { value: 'ADMIN', label: 'Administrador' },
    { value: 'PRODUCTION', label: 'Produção' },
  ];

  const history = useHistory();
  const { addToast } = useToast();

  const [model, setModel] = useState<IProfileData>({
    name: '',
    contactNumber: '',
    username: '',
    job: '',
    userType: '',
    password: '',
  });

  useEffect(() => {
    setLoading(true);
    api.get<IProfileData>(`control-panel/read/${id}`).then(response => {
      setModel(response.data);
      setLoading(false);
    });
  }, [id]);

  const removeUser = async () => {
    try {
      setLoading(true);
      const response = await api.delete(`/control-panel/delete/${id}`);
      setLoading(false);
      addToast({
        type: 'success',
        title: 'Usuário Removido',
        description: 'Usuário removido com sucesso',
      });

      history.push('/painel');
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao excluir o Usuário',
      });
    }
  };

  const handleSubmit = useCallback(
    async (data: IProfileData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          username: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          contactNumber: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          role: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.put(`/control-panel/update/${id}`, data);

        window.scrollTo(0, 0);
        addToast({
          type: 'success',
          title: 'Cadastro atualizado!',
          description: 'Cadastro atualizado com sucesso',
        });

        setLoading(false);
        history.push('/painel');
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            JSON.parse(err.request.response).message ||
            'Houve um erro no cadastro',
        });
        window.scrollTo(0, 0);
      }
    },
    [addToast, history, id],
  );

  function handleBack() {
    history.go(-1);
  }

  function showUserType(userType: string): string {
    let retornoStatus = '';

    const roleAdmin = 'ADMIN';
    const roleProduction = 'PRODUCTION';

    const admin = 'Administrador';
    const producao = 'Produção';

    if (userType === roleAdmin) {
      retornoStatus = admin;
    }
    if (userType === roleProduction) {
      retornoStatus = producao;
    }
    return retornoStatus;
  }

  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Gerenciar Usuários</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>
      <Section>
        <div className="sectionCard">
          <div className="flex-container">
            <div>
              <div>
                <h2>Editar Usuário</h2>
              </div>
              <div className="deleteItem">
                <Button
                  type="button"
                  loading={loading}
                  onClick={() => removeUser()}
                >
                  Remover Usuário
                  <DeleteIcon />
                </Button>
              </div>
            </div>

            <div className="menu-cadastro">
              <Link to="/painel">
                <div className="item-novo-cadastro">Usuários Cadastrados</div>
              </Link>
              <div className="item-cadastrados">Novo Usuário</div>
            </div>

            <div>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormCadastroPequenosAlmocos>
                  <div className="formulario-cadastro">
                    <div>
                      <div className="form-inputs">
                        <h5>Nome do Usuário*</h5>
                        <Input
                          type="text"
                          name="name"
                          defaultValue={model.name}
                        />
                      </div>
                      <div className="form-inputs">
                        <h5>Telefone Usuário*</h5>
                        <Input
                          type="text"
                          name="contactNumber"
                          defaultValue={model.contactNumber}
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>E-mail do Usuário*</h5>
                          <Input
                            type="text"
                            name="username"
                            defaultValue={model.username}
                          />
                        </div>
                        <div className="form-inputs">
                          <h5>Cargo</h5>
                          <Input
                            type="text"
                            name="job"
                            defaultValue={model.job}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>
                            Nível de Acesso atual*:
                            <span> {showUserType(model.userType)} </span>
                          </h5>
                          {!loading && (
                            <Select
                              name="role"
                              id="role"
                              className="selects"
                              options={options}
                              defaultValue={{
                                label:
                                  model.userType === 'PRODUCTION'
                                    ? 'Produção'
                                    : 'Administrador',
                                value: model.userType,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bottomPedidos">
                      <Button loading={loading} type="submit">
                        Salvar
                      </Button>
                      <Button className="allItems" onClick={handleBack}>
                        Voltar
                      </Button>
                    </div>
                  </div>
                </FormCadastroPequenosAlmocos>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default EditarUsuarios;
