/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { addDays, format, isSameDay, parse, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactHorizontalDatePicker from 'react-horizontal-strip-datepicker';
import './ReactHorizontalDatePicker.css';
import api from '../../../services/api';

import {
  Main,
  Section,
  Calender,
  InfoIcon,
  ButtonWrapperHover,
  RedRoundButton,
  Tooltip,
} from './styles';
import Button from '../../../components/Button';
import { useToast } from '../../../hooks/toast';
import { DeliveryManModal } from '../../../components/DeliveryManModal';
import { convertDateString } from '../../../utils/convertDateString';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';

interface IMapOfOrdersList {
  order: string;
  address: string;
  deliveryDate: string;
  quantity: number;
}

interface MapOfOrders {
  order: string;
  total: number;
  groupId: string;
  name: string;
  apartment: string;
  clientId: string;
  address: string;
  deliveryTime: string;
  additionalData: string;
  list: IMapOfOrdersList[];
}

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
  additionalData?: string;
}

interface ITodayOrdersLunch {
  order: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  formattedNumber: number;
  createdAt: any;
  additionalData: string;
  deliveryDate: any;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

interface IOrderParams {
  id: string;
}

const Dashboard: React.FC = () => {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<ITodayOrders | undefined>();
  const [date, setDate] = useState<Date>(new Date());
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [pendingOrders, setPendingOrders] = useState<ITodayOrders[]>();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliveryMap, setDeliveryMap] = useState<MapOfOrders[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>('');
  const [ordersStatus, setOrdersStatus] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 10));
  const [loading, setLoading] = useState<boolean>(true);
  const mountedRef = useRef(true);
  const history = useHistory();
  const { addToast } = useToast();

  const today = new Date();

  const parseDate = (date: string) => {
    const formattedDate = parse(date, 'yyyy-MM-dd', new Date());

    return formattedDate;
  };

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );

    const orderArray = order.data;

    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    const orderByClient = orderByTime.sort((a: any, b: any) => {
      if (a.client < b.client) {
        return -1;
      }
      if (a.client > b.client) {
        return 1;
      }
      return 0;
    });

    setTodayOrders(orderByClient);
  };

  let lastClientById = '';
  let bgColor = false;

  const getNextOrders = async () => {
    const order = await api.get<ITodayOrders[]>('food-order/all/next');
    const orderArray = order.data;

    const orderBySchedule = orderArray.sort((leftDate, rightDate) => {
      const leftTimestamp = Date.parse(leftDate.deliveryDate);
      const rightTimestamp = Date.parse(rightDate.deliveryDate);
      return leftTimestamp - rightTimestamp;
    });

    setNextOrders(orderBySchedule);
  };

  const getDeliveryMapOrders = async () => {
    const mapOrders = await api.get<any[]>(
      `food-order/day/range/${format(startDate, 'yyyy-MM-dd')}/${format(
        endDate,
        'yyyy-MM-dd',
      )}`,
    );

    setDeliveryMap(mapOrders.data);
  };

  const getPendingProduction = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');
    const order = await api.get<ITodayOrders[]>(
      `/food-order/${formattedDate}/?status=${'A_CONFIRMAR'}`,
    );
    const orderArray = order.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setPendingOrders(orderByTime);
  };

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar dados',
        description: 'Oops ocorreu um erro ao carregar dados de entregadores',
      });
    }
  };

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  const didMount = () => {
    setLoading(true);
    getTodayOrders();
    getAllDeliveryman();
    getNextOrders();
    getPendingProduction();
    getDeliveryMapOrders();
    setLoading(false);
  };

  const changeDate = (day: Date) => {
    setDate(day);
  };

  function handleOpenModal(order: ITodayOrders) {
    setActiveModal(order);
    setIsOrderModalOpen(true);
  }

  function handleCloseModal() {
    setIsOrderModalOpen(false);
  }

  const handleChangeStatus = (value: string) => {
    setOrdersStatus(value);
  };

  function handleChangeStatusConfirm(
    status: string,
    orderId: string,
    order: ITodayOrders,
  ) {
    try {
      if (status === 'openModal') {
        handleOpenModal(order);
        return;
      }
      setLoading(true);
      api.put(`food-order/update/status/${orderId}`, {
        status,
      });
      addToast({
        type: 'success',
        title: 'Status Alterado!',
        description: 'Status alterado com sucesso',
      });
      setLoading(false);

      setTimeout(() => {
        didMount();
        history.push('/');
      }, 500);
    } catch (err: any) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar Status',
        description:
          err.data.message ||
          'Oops! Ocorreu um erro ao alterar o status do pedido',
      });
    }
  }

  function handleOnConfirmDeliveryMan(deliverymanId: string, id: string) {
    setLoading(true);
    api.put(
      `/food-order/update/${id}/deliveryman/${deliverymanId}`,
      deliverymanId,
    );

    addToast({
      type: 'success',
      title: 'Entregador adicionado!',
      description: 'Entregador adicionado ao pedido com sucesso',
    });
    setLoading(false);
    setTimeout(() => {
      didMount();
      history.push('/');
    }, 1000);
  }

  const pendingOrdersToDelivery = nextOrders?.filter((order: any) => {
    return order.status !== 'ENTREGUE';
  });

  const filterDeliveryMap = (order: MapOfOrders, days: number) => {
    return order.list.filter(i =>
      isSameDay(parseDate(i.deliveryDate), addDays(startDate, days)),
    )[0] ? (
      order.list.filter(i =>
        isSameDay(parseDate(i.deliveryDate), addDays(startDate, days)),
      )[0].quantity
    ) : (
      <span>0</span>
    );
  };

  useEffect(() => {
    didMount();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Olá, Seja bem vindo(a) de volta</h1>
            <h3>Acompanhe aqui tudo que acontece na sua conta</h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <h2>Mapa de Entregas</h2>
            <div className="flex-container">
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <Calender>
                <ReactHorizontalDatePicker
                  enableScroll
                  selectedDay={(e: Date) => changeDate(e)}
                  enableDays={11}
                  enableDaysBefore={0}
                />
              </Calender>
            </div>
            {!loading &&
              deliveryMap?.length !== 0 &&
              deliveryMap?.map(order => {
                return (
                  <div key={order.groupId} className="flex-container">
                    <Link
                      to={`/producao-do-dia-detalhe/${order.list[0].order}`}
                    >
                      <div>{order.name}</div>
                    </Link>
                    <div>{order.address}</div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {order.apartment}
                    </div>
                    <div>{order.deliveryTime.substr(0, 5)}</div>
                    <div className="orderDataPicker">
                      <div />
                      <div>{filterDeliveryMap(order, 0)}</div>
                      <div>{filterDeliveryMap(order, 1)}</div>
                      <div>{filterDeliveryMap(order, 2)}</div>
                      <div>{filterDeliveryMap(order, 3)}</div>
                      <div>{filterDeliveryMap(order, 4)}</div>
                      <div>{filterDeliveryMap(order, 5)}</div>
                      <div>{filterDeliveryMap(order, 6)}</div>
                      <div>{filterDeliveryMap(order, 7)}</div>
                      <div>{filterDeliveryMap(order, 8)}</div>
                      <div>{filterDeliveryMap(order, 9)}</div>
                      <div />
                    </div>
                  </div>
                );
              })}
            {deliveryMap?.length === 0 && (
              <h2>Não há pedidos cadastrados...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
          <div className="sectionCard">
            <h2>Pedidos do dia {format(today, 'dd/MM/yyyy')}</h2>
            <div className="flex-container">
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status</h3>
              </div>
              <div className="item">Item</div>
            </div>

            {!loading &&
              todayOrders?.length !== 0 &&
              todayOrders?.map((order, i) => {
                if (lastClientById !== order.client) {
                  lastClientById = order.client;
                  bgColor = !bgColor;
                }
                return (
                  <div
                    key={order.id}
                    className="flex-container-production"
                    style={{
                      backgroundColor: bgColor
                        ? 'var(--bg-table)'
                        : 'var(--bg-table-light)',
                    }}
                  >
                    <div>{order.client}</div>

                    <div>{order.deliveryTime.substr(0, 5)}</div>
                    <div>
                      {order.deliveryAddress[0].street} n
                      {order.deliveryAddress[0].streetNumber}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {order.apartment}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '16px',
                      }}
                    >
                      {order.lunchs.map((i, index) => (
                        <div key={i.order}>
                          {index < 3 ? `${i.quantity} - ${i.order}` : `...`}
                        </div>
                      ))}
                      <div
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        {order.additionalData && (
                          <ButtonWrapperHover>
                            {order.additionalData && (
                              <>
                                <RedRoundButton>
                                  <span>!</span>
                                </RedRoundButton>
                                <Tooltip>{order.additionalData}</Tooltip>
                              </>
                            )}
                          </ButtonWrapperHover>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className={confirmStatus(order.status)}>
                        <span>{formatStatus(order.status)}</span>
                      </div>
                    </div>
                    <div>
                      <div className="changeStatusSelect">
                        <select
                          defaultValue={order.status}
                          name="changeStatus"
                          id="changeStatus"
                          onChange={event =>
                            handleChangeStatus(event.target.value)
                          }
                        >
                          <option value={order.status}>
                            {formatStatus(order.status)}
                          </option>
                          <option value="A_CONFIRMAR">A Confirmar</option>
                          <option value="EM_PRODUCAO">Em Produção</option>
                          <option value="FINALIZADO">Finalizado</option>
                          {order.deliverymanName !== '' ? (
                            <option value="openModal">Entregue</option>
                          ) : (
                            <option value="ENTREGUE">Entregue</option>
                          )}
                          <option value="CANCELADA">Cancelada</option>
                        </select>
                        <Button
                          onClick={() =>
                            handleChangeStatusConfirm(
                              ordersStatus,
                              order.id,
                              order,
                            )
                          }
                        >
                          Ok
                        </Button>
                      </div>
                    </div>
                    <div className="lastDiv">
                      <Link to={`/producao-do-dia-detalhe/${order.id}`}>
                        <Button className="button-plus">
                          <span>+</span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            {todayOrders?.length === 0 && (
              <h2>Não há pedidos para produção na data de hoje...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
          <div className="sectionCard">
            <h2>Produção Pendente {format(today, 'dd/MM/yyyy')}</h2>
            <div className="flex-container">
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>

              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {!loading &&
              pendingOrders?.length !== 0 &&
              pendingOrders?.map((order, index) => (
                <div key={String(index)} className="flex-container">
                  <div>{order.deliveryTime.substr(0, 5)}</div>
                  <div>{order.client}</div>
                  <div>
                    {order.deliveryAddress[0].street} n
                    {order.deliveryAddress[0].streetNumber}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {order.apartment}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '16px',
                    }}
                  >
                    {order.lunchs.map((i, index) => (
                      <div key={String(index)}>
                        {index < 3 ? `${i.quantity} - ${i.order}` : `...`}
                      </div>
                    ))}
                    <div
                      style={{
                        position: 'relative',
                        display: 'inline-block',
                      }}
                    >
                      {order.additionalData && (
                        <ButtonWrapperHover>
                          {order.additionalData && (
                            <>
                              <RedRoundButton>
                                <span>!</span>
                              </RedRoundButton>
                              <Tooltip>{order.additionalData}</Tooltip>
                            </>
                          )}
                        </ButtonWrapperHover>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className={confirmStatus(order.status)}>
                      <span>{formatStatus(order.status)}</span>
                    </div>
                  </div>
                  <div>
                    <div className="changeStatusSelect">
                      <select
                        name="changeStatus"
                        id="changeStatus"
                        defaultValue={order.status}
                        onChange={event =>
                          handleChangeStatus(event.target.value)
                        }
                      >
                        <option defaultValue={order.status}>
                          {formatStatus(order.status)}
                        </option>

                        <option value="A_CONFIRMAR">A Confirmar</option>
                        <option value="EM_PRODUCAO">Em Produção</option>
                        <option value="FINALIZADO">Finalizado</option>
                        {order.deliverymanName !== '' ? (
                          <option value="openModal">Entregue</option>
                        ) : (
                          <option value="ENTREGUE">Entregue</option>
                        )}
                        <option value="CANCELADA">Cancelada</option>
                      </select>
                      <Button
                        onClick={() =>
                          handleChangeStatusConfirm(
                            ordersStatus,
                            order.id,
                            order,
                          )
                        }
                      >
                        <span>OK</span>
                      </Button>
                    </div>
                  </div>
                  <div className="lastDiv">
                    <Link to={`/producao-do-dia-detalhe/${order.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {pendingOrders?.length === 0 && (
              <h2>Não há pedidos pendentes para produção...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
          <div className="sectionCard">
            <h2>Entregas Pendentes</h2>
            <div className="flex-container">
              <div>
                <h3>Data do Pedido</h3>
              </div>
              <div>
                <h3>Data da Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Motorista</h3>
              </div>
              <div style={{ width: '50px' }} />
            </div>
            {!loading &&
              pendingOrdersToDelivery?.length !== 0 &&
              pendingOrdersToDelivery?.map((nextOrder, i) => (
                <div key={nextOrder.id} className="flex-container">
                  <div>
                    {format(parseISO(nextOrder.createdAt), 'dd/MM/yyyy')}
                  </div>

                  <div>{convertDateString(nextOrder.deliveryDate)}</div>
                  <div>{nextOrder.deliveryTime.substr(0, 5)}</div>
                  <div>{nextOrder.client}</div>
                  <div className="nextOrderAddress">
                    {nextOrder.deliveryAddress[0].street} n
                    {nextOrder.deliveryAddress[0].streetNumber}-
                    {nextOrder.deliveryAddress[0].neighborhood}
                  </div>
                  <div>
                    <div>
                      {nextOrder.deliverymanName === '' ? (
                        <>
                          <div className="changeStatusSelect">
                            <select
                              name="deliverymanname"
                              id="deliverymanname"
                              defaultValue="Selecionar"
                              onChange={event =>
                                handleDeliveryman(event.target.value)
                              }
                            >
                              <option defaultValue="">Selecionar</option>
                              {deliverymanList &&
                                deliverymanList?.map(driver => (
                                  <option key={driver.id} value={driver.id}>
                                    {driver.name} - {driver.licensePlate}
                                  </option>
                                ))}
                            </select>
                            <Button
                              onClick={() =>
                                handleOnConfirmDeliveryMan(
                                  deliverymanId,
                                  nextOrder.id,
                                )
                              }
                            >
                              Ok
                            </Button>
                          </div>
                        </>
                      ) : (
                        <h5>{nextOrder.deliverymanName}</h5>
                      )}
                    </div>
                  </div>
                  <div className="lastDiv">
                    <Link to={`/producao-do-dia-detalhe/${nextOrder.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {pendingOrdersToDelivery?.length === 0 && (
              <h2>Não há pedidos pendentes para entrega...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
        </Section>
        {activeModal?.id && (
          <DeliveryManModal
            id={activeModal?.id}
            isOpen={isOrderModalOpen}
            onRequestClose={handleCloseModal}
            onConfirmDeliveryMan={handleOnConfirmDeliveryMan}
            onCancelDeliveryMan={handleCloseModal}
          />
        )}
      </Main>
    </>
  );
};

export default Dashboard;
