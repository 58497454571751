import { shade } from 'polished';
import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;

  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.08);

    h2 {
      color: var(--primary);
    }
  }

  h2 {
    font-size: 2.4rem;
    margin: 44px 0 29px 40px;
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    margin-right: 50px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    border-bottom: 1px solid var(--line);
    display: flex;
    align-items: left;
    justify-content: space-between;
    text-align: left;

    padding-right: 10px;
    margin: 5px 40px 20px 41px;

    & > div {
      height: 50px;
      width: 140px;
      display: flex;
      align-items: left;
      justify-content: left;
      text-align: left;
    }

    .clientLunchList {
      top: 0;
      display: flex;
      align-items: center;
      margin-top: -8px;
      margin-bottom: 8px;
      & > div {
        display: flex;
        flex-direction: column;
        margin-right: 8px;
        margin-bottom: 8px;
      }
      .lunchsPerClient {
        display: flex;
        align-items: center;
        & > div {
        }
      }
    }
    .item {
      color: var(--background);
    }

    .aConfirmar {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 20px;
      width: 135px;
      height: 33px;
      background: var(--blue);
      margin-top: -5px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .comPendencia {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      width: 135px;
      height: 33px;
      background: var(--red);
      margin-top: -5px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .emProducao {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green-light);
      margin-top: -5px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--secondary);
      }
    }

    .finalizado {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green-dark-plus);
      margin-top: -5px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .entregue {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green);
      margin-top: -5px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .cancelado {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--primary);
      margin-top: -5px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    button.button-plus {
      width: 31px;
      height: 31px;
      background: var(--primary);

      margin-left: 40px;
      margin-top: -10px;
      transition: color 0.2s;

      & > span {
        margin: 1px 2px 2px -7px;
        font-size: 2.4rem;
        font-weight: 400;

        text-align: center;
      }
      &:hover {
        background: ${(shade(0.2), '#EB5757')};
      }
    }
  }

  .bottomPedidos {
    margin-bottom: 50px;
  }
`;
