import { shade } from 'polished';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    //box-sizing: border-box;
    outline: none;
  }

  :root {
    --primary: #BF1E2D;
    --secondary: #2E3135;
    --background: #FCFCFC;
    --table-color: #ffffff;
    --title: #474B4C;
    --line: #C2C2C2;
    --line-table: #E7E7E7;
    --input: #F3F3F3;
    --bg-table: #F4F2F7;
    --bg-table-light: #F8F8FB;
    --red: #EB5757;
    --green: #219653;
    --green-light: #8FDEC6;
    --green-dark: #1A7541;
    --green-dark-plus: #155E34;
    --blue: #2D9CDB;
    --blue-dark: #18638E;
    --cyan: #418898;
    --orange: #E28921;
  }

  body, html {

    font-size: 62.5%;
    background: var(--background);
    color: var(--title);
    -webkit-font-smoothing: antialiased;
  }

  html {

    @media (max-width: 1024px){
      font-size: 58.5%;
    }
    @media (max-width: 769px){
      font-size: 50.5%;
    }

  }

  body, input, button {
    font-family: 'Rubik', sans-serif;
    font-size: 1.6rem;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-modal-content {
  width: 100%;
  max-width: 1000px;
  height: 80%;
  background: var(--background);
  padding: 3rem;
  position: relative;
  border-radius: 1rem;
  overflow: auto;
  overflow-x: hidden;
}

.react-modal-close {
  position: absolute;
  z-index: 9990000;
  right: 1.5rem;
  top: 1.5rem;
  border: 0;
  background: transparent;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
}

.redLine {
  border-bottom: 1px solid var(--primary);
  margin: 16px 0;
}

.grayLine {
  border-bottom: 1px solid var(--line-table);
  margin: 16px 0;
}

// Main

.header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    span {
      color: var(--primary);
    }

    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }

  // Section

  .aConfirmar {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 20px;
    width: 125px;
    height: 33px;
    background: var(--blue);
    margin-top: -5px;
    span {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
      color: var(--input);
    }
  }

  .comPendencia {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 125px;
    height: 33px;
    background: var(--red);
    margin-top: -5px;
    span {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
      color: var(--input);
    }
  }

  .emProducao {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 33px;
    border-radius: 20px;
    background: var(--green-light);
    margin-top: -5px;
    span {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
      color: var(--secondary);
    }
  }

  .finalizado {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 33px;
    border-radius: 20px;
    background: var(--green-dark-plus);
    margin-top: -5px;
    span {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
      color: var(--input);
    }
  }

  .entregue {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 33px;
    border-radius: 20px;
    background: var(--green);
    margin-top: -5px;
    span {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
      color: var(--input);
    }
  }

  .cancelado {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 33px;
    border-radius: 20px;
    background: var(--primary);
    margin-top: -5px;
    color: var(--input);
    span {
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
      color: var(--input);
    }
  }


  button.button-plus {
    width: 31px;
    height: 31px;
    background: var(--primary);

    margin-top: -4px;
    transition: color 0.2s;

    & > span {
      margin: 1px 2px 2px -7px;
      font-size: 2.4rem;
      font-weight: 400;

      text-align: center;
    }
    &:hover {
      background: ${(shade(0.2), '#EB5757')};
    }
  }

  .bottomPedidos {
      display: flex;
      margin-top: 8px;
      padding-bottom: 8px;
      transition: color 0.2s;

      > button {
        margin-top: 0;
        margin-bottom: 30px;

        &:hover {
         color: ${(shade(0.2), '#EB5757')};
        }
      }
  }
`;
