import React from 'react';
import Input from '../../../../components/Input';

interface IProps {
  selectedAddress: any;
  changeAddressData({ data, input }: { data: any; input: any }): void;
}

const Avulso: React.FC<IProps> = ({ selectedAddress, changeAddressData }) => {
  return (
    <div className="morada">
      <div className="formulario-cadastro" style={{ marginLeft: 25 }}>
        <div>
          <div className="form-inputs">
            <h5>Nome</h5>
            <Input
              name="contactName"
              id="contactName"
              type="text"
              value={selectedAddress?.contactName}
              onChange={e =>
                changeAddressData({
                  data: e.target.value,
                  input: e.target.id,
                })
              }
            />
          </div>
          <div className="form-inputs">
            <h5>Contato</h5>
            <Input
              id="contactPhone"
              name="contactPhone"
              type="text"
              value={selectedAddress?.contactPhone}
              onChange={e =>
                changeAddressData({
                  data: e.target.value,
                  input: e.target.id,
                })
              }
            />
          </div>
        </div>
        <div>
          <div className="form-inputs">
            <h5>Rua</h5>
            <Input
              name="street"
              id="street"
              type="text"
              value={selectedAddress?.street}
              onChange={e =>
                changeAddressData({
                  data: e.target.value,
                  input: e.target.id,
                })
              }
            />
          </div>
          <div className="form-inputs">
            <h5>Número</h5>
            <Input
              id="streetNumber"
              name="streetNumber"
              type="text"
              className="input-numero"
              value={selectedAddress?.streetNumber}
              onChange={e =>
                changeAddressData({
                  data: e.target.value,
                  input: e.target.id,
                })
              }
            />
          </div>
        </div>
        <div>
          <div>
            <div className="form-inputs">
              <h5>Bairro</h5>
              <Input
                id="neighborhood"
                name="neighborhood"
                type="text"
                value={selectedAddress?.neighborhood}
                onChange={e =>
                  changeAddressData({
                    data: e.target.value,
                    input: e.target.id,
                  })
                }
              />
            </div>
            <div className="form-inputs">
              <h5>Cidade</h5>
              <Input
                id="city"
                name="city"
                type="text"
                value={selectedAddress?.city}
                onChange={e =>
                  changeAddressData({
                    data: e.target.value,
                    input: e.target.id,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="form-inputs">
              <h5>Morada de Entrega</h5>
              <Input
                id="deliveryPlace"
                name="deliveryPlace"
                type="text"
                value={selectedAddress?.deliveryPlace}
                onChange={e =>
                  changeAddressData({
                    data: e.target.value,
                    input: e.target.id,
                  })
                }
              />
            </div>
            <div className="form-inputs">
              <h5>Código da Porta</h5>
              <Input
                id="doorCode"
                name="doorCode"
                type="text"
                value={selectedAddress?.doorCode}
                onChange={e =>
                  changeAddressData({
                    data: e.target.value,
                    input: e.target.id,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div>
          <div className="form-inputs">
            <h5>Custo de Entrega</h5>
            <Input
              id="deliveryPrice"
              name="deliveryPrice"
              type="number"
              step="any"
              min={0}
              className="input-numero"
              value={selectedAddress?.deliveryPrice}
              onChange={e =>
                changeAddressData({
                  data: e.target.value,
                  input: e.target.id,
                })
              }
            />
          </div>
          <div className="form-inputs">
            <h5>Apartamento</h5>
            <Input
              id="apartment"
              name="apartment"
              type="text"
              value={selectedAddress?.apartment}
              onChange={e =>
                changeAddressData({
                  data: e.target.value,
                  input: e.target.id,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Avulso;
