import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Search } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  max-width: 1355px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    h2 {
      color: var(--primary);
      font-size: 2.4rem;
      margin: 44px 0 29px 40px;
    }
  }

  .destino {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 2.4rem;
      color: var(--primary);
    }
    .check-one-shot {
      width: 100%;
      max-width: 300px;
      display: flex;
      align-items: center;
      margin-left: auto;
      span {
        margin-right: 10px;
        margin-left: 10px;
        font-size: 1.6rem;
        font-weight: 700;
        color: var(--primary);
      }
    }
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    color: var(--primary);
  }

  .client {
    margin-left: 40px;

    .addressList {
      display: flex;

      h5 {
        font-weight: 700;
        margin-right: 10px;
      }

      transition: border-color 0.2;
      &:hover {
        border-color: var(--red);
      }
    }

    h2 {
      margin: 0 0 20px 0;

      color: var(--secondary);
    }

    h4 {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 4px;
      margin-right: 4px;
    }
    span {
      font-size: 1.6rem;
      font-weight: 400;
    }
  }

  .cardClient {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 740px;
    height: 100%;
    background: var(--input);
    border-radius: 20px;
    padding: 28px 72px;
    margin: 4px 0 16px 0;
  }
  .apartamentsList {
    margin: 4px 0;

    h5 {
      margin-bottom: 10px;
    }
    .apartaments {
      display: flex;
      flex-wrap: wrap;

      & > input[type='radio'] {
        height: 47px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--line);
        width: 80px;
        height: 44px;
        background: var(--input);
        border-radius: 20px;
        margin-right: 10px;
        margin-bottom: 4px;
        padding: 16px 10px;
        label {
          margin: 20px 0 20px 8px;
        }
        &:hover {
          background: ${shade(0.2, '#ffffff')};
        }
      }
    }
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .delivery-date {
    display: flex;
    margin: 20px 40px;
    > div {
      display: flex;
      flex-direction: column;
    }
    span {
      margin-bottom: 10px;
    }
    .datapicker {
      width: 195px;
      height: 47px;
      border: 0;
      border-radius: 20px;
      margin-right: 50px;
      padding: 0 20px;
      background: var(--input);
      color: var(--secondary);
    }
  }

  .lunchTitle {
    margin-bottom: 10px;
    margin-left: 40px;
    h2 {
      font-size: 2.4rem;
    }
  }
  .delivery-time {
    display: flex;
    flex-direction: column;
    margin-left: 40px;

    span {
      margin: 20px 0;
    }

    select {
      width: 230px;
      height: 47px;
      border: 0;
      border-radius: 20px;
      margin-right: 50px;
      padding: 0 20px;
      background: var(--input);
      color: var(--secondary);
    }

    textarea {
      width: 560px;
      height: 82px;
      border: 0;
      border-radius: 20px;
      margin-right: 50px;
      margin-bottom: 60px;
      padding: 10px 20px;
      background: var(--input);
      color: var(--secondary);
      font-family: 'Rubik', sans-serif;
    }
  }

  .lunchs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 40px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1079px;
    > h2 {
      color: var(--secondary);
      margin-left: 0;
    }

    > h4 {
      font-size: 2.4rem;
      color: var(--primary);
    }
  }

  .lunch-item {
    display: flex;
    width: 100%;
    max-width: 460px;

    align-items: center;
    justify-content: left;
    > div:last-child {
      margin-left: 30px;
    }

    img {
      margin-right: 25px;
    }

    > div:first-child {
      margin-bottom: 30px;
    }

    .lunch-img-title {
      display: flex;
      align-items: center;
      width: 220px;
    }

    .lunch-cost {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      input {
        width: 122px;
        height: 47px;
        border-radius: 20px;
        background: var(--input);
        border: none;
        padding: 0 10px;
        text-align: center;
        margin-bottom: 40px;
      }

      span {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }

    input[type='checkbox'] {
      margin-bottom: 10px;
      position: relative;
      width: 28px;
      height: 28px;
      color: var(--secondary);
      border: 4px solid var(--primary);
      border-radius: 4px;
      appearance: none;
      outline: 0;
      cursor: pointer;
      transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
      &::before {
        position: absolute;
        content: '';
        display: block;
        top: -1px;
        left: 4px;
        width: 8px;
        height: 14px;
        border-style: solid;
        border-color: var(--input);
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
        opacity: 0;
      }
      &:checked {
        color: var(--secondary);
        border-color: var(--primary);
        background: var(--primary);
        &::before {
          opacity: 1;
        }
        ~ label::before {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    width: 100%;
    background: var(--table-color);
    box-shadow: 0 20px 20px -2px rgba(0, 0, 0, 0.05);

    margin: 25px 0 20px 5px;
    padding: 40px;

    > div {
      display: flex;
      justify-content: space-between;
    }
    .menu-cadastro {
      width: 100%;
      max-width: 815px;
      border-bottom: 2px solid var(--line-table);
      display: flex;
      align-items: center;
      justify-content: left;
      text-decoration: none;
      margin-top: 30px;
    }

    .item-cadastrados {
      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid var(--primary);

      font-size: 1.8rem;
      font-weight: 400;
      color: var(--primary);
    }

    .item-novo-cadastro {
      margin-right: 47px;

      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 400;
      border: 0;
      background: #e8ebf1;
      text-decoration: none;
      color: var(--secondary);

      &:hover {
        border-top: 2px solid var(--primary);
      }
    }
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const FormCadastroCliente = styled.div`
  display: flex;
  flex-direction: column;

  .formulario-cadastro {
    margin-top: 54px;

    div {
      display: flex;
      align-items: left;
      justify-content: left;
      & + div {
        margin-bottom: 30px;
      }
    }

    .form-inputs {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      h5 {
        font-size: 1.6rem;
        font-weight: 400;
        margin-left: 20px;
        margin-bottom: 4px;
      }

      input {
        border: 0;
        border-radius: 20px;
        width: 320px;
        height: 28px;
        padding: 10px 20px;
        background: var(--input);
      }
      input.input-email {
        width: 450px;
      }
      input.input-numero {
        width: 100px;
      }

      .select-options {
        border: 0;
        border-radius: 20px;
        width: 320px;
        height: 47px;
        background: var(--input);
        padding: 10px 20px;
        ::placeholder {
          font-size: 1.6px;
          font-weight: 400;
          color: var(--secondary);
        }
      }
    }
  }

  .bottomPedidos {
    display: flex;
    align-items: flex-start;
    margin: 45px 0 60px 40px;

    > button {
      margin-top: 0;
      margin-right: 40px;
      margin-bottom: 60px;
    }
  }

  .morada {
    margin-top: 30px;

    h2 {
      font-size: 2.4rem;
      font-weight: 500;
    }
  }
`;
