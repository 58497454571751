import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Search } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;

  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    h2 {
      color: var(--primary);
    }

    button.allItems {
      float: left;
      width: 274px;
      height: 47px;
      margin-left: 40px;
      background: transparent;
      border: 2px solid var(--red);
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--red);
      transition: background-color 0.2s;

      &:hover {
        color: ${(shade(0.2), '#BF1E2D')};
      }
    }
  }

  h2 {
    font-size: 2.4rem;
    margin: 0 0 20px 0px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    border-radius: 10px;
    background: var(--table-color);

    padding: 40px 40px 300px 40px;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .menu-cadastro {
      width: 100%;
      max-width: 815px;
      border-bottom: 2px solid var(--line-table);
      display: flex;
      align-items: center;
      justify-content: left;
      text-decoration: none;
      margin-top: 30px;
    }

    .item-cadastrados {
      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid var(--primary);

      font-size: 1.8rem;
      font-weight: 400;
      color: var(--primary);
    }

    .item-novo-cadastro {
      margin-right: 47px;

      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 400;
      border: 0;
      background: #e8ebf1;
      text-decoration: none;
      color: var(--secondary);

      &:hover {
        border-top: 2px solid var(--primary);
      }
    }

    .bottomPedidos {
      display: flex;
      margin-top: 10px;
      padding-bottom: 15px;
      > button {
        margin-right: 40px;
      }
    }
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const FormCadastroPequenosAlmocos = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .formulario-cadastro {
    margin-top: 10px;

    div {
      display: flex;
      align-items: left;
      justify-content: left;
      & + div {
        margin-bottom: 30px;
      }
    }

    .form-inputs {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      h5 {
        font-size: 1.6rem;
        font-weight: 400;
        margin: 20px 0 10px 10px;
      }

      input {
        border: 0;
        border-radius: 20px;
        width: 600px;
        height: 30px;
        padding: 10px 20px;
        background: var(--input);
      }
      input.input-valor {
        width: 167px;
      }
      input.input-numero {
        width: 100px;
        height: 47px;
      }
      input.form-textarea {
        border: 0;
        border-radius: 20px;
        padding: 10px 20px;
        max-width: 600px;
        width: 600px;
      }
    }
  }
`;
