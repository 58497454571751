/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Switch } from 'react-router-dom';
import Route from './Route';

import Login from '../pages/Login';
import CriarCredenciais from '../pages/CriarCredenciais';
import ForgotPassword from '../pages/ForgotPassword';
import NewPassword from '../pages/NewPassword';

// Admin
import Admin from '../pages/Admin/Dashboard';
import Painel from '../pages/Admin/Painel';
import CadastroNovoUsuario from '../pages/Admin/CadastroNovoUsuario';
import EditarUsuarios from '../pages/Admin/EditarUsuarios';
import Clientes from '../pages/Admin/Clientes';
import EditarCliente from '../pages/Admin/EditarCliente';
import CadastroCliente from '../pages/Admin/CadastroCliente';
import PequenosAlmocos from '../pages/Admin/PequenosAlmocos';
import EditarAlmocos from '../pages/Admin/EditarAlmocos';
import CadastroPequenoAlmocos from '../pages/Admin/CadastroPequenosAlmocos';
import Entregadores from '../pages/Admin/Entregadores';
import EditarEntregador from '../pages/Admin/EditarEntregador';
import CadastroEntregadores from '../pages/Admin/CadastroEntregadores';
import NovoPedidoAdmin from '../pages/Admin/NovoPedido';
import CadastroNovoPedidoAdmin from '../pages/Admin/CadastroNovoPedido';
import PedidosAdmin from '../pages/Admin/Pedidos';
import EditarPedidoInicioAdmin from '../pages/Admin/EditarPedidoInicio';
import EditarPedidoFinalAdmin from '../pages/Admin/EditarPedidoFinal';
import ProducaoDoDiaAdmin from '../pages/Admin/ProducaoDoDia';
import ProducaoDoDiaDetalheAdmin from '../pages/Admin/ProducaoDoDiaDetalhe';
import EntregasPendentesAdmin from '../pages/Admin/EntregasPendentes';
import ProximosPedidosAdmin from '../pages/Admin/ProximosPedidos';
import RelatorioClienteAdmin from '../pages/Admin/RelatorioCliente';
import RelatorioClienteExplodeAdmin from '../pages/Admin/RelatorioClienteExplode';
import RelatorioClienteExplodeFinalAdmin from '../pages/Admin/RelatorioClienteExplodeFinal';
import HistoricoAdmin from '../pages/Admin/Historico';
import HistoricoFinalAdmin from '../pages/Admin/HistoricoFinal';
import EditarMoradaAdmin from '../pages/Admin/EditarMorada/index';
import RelatorioNegocioAdmin from '../pages/Admin/RelatorioNegocio';
import RelatorioNegocioExplodeAdmin from '../pages/Admin/RelatorioNegocioExplode';
import RelatorioNegocioExplodeFinalAdmin from '../pages/Admin/RelatorioNegocioExplodeFinal';
import RelatorioEntregadoresAdmin from '../pages/Admin/RelatorioEntregadores';
import RelatorioEntregadoresExplodeAdmin from '../pages/Admin/RelatorioEntregadoresExplode';
import RelatorioEntregadoresExplodeInicialAdmin from '../pages/Admin/RelatorioEntregadoresExplodeInicial';
import RelatorioEntregadoresExplodeFinalAdmin from '../pages/Admin/RelatorioEntregadoresExplodeFinal';

// Cliente
import Cliente from '../pages/Client/Dashboard';
import NovoPedidoCliente from '../pages/Client/NovoPedido';
import CadastrarNovoPedidoCliente from '../pages/Client/CadastroNovoPedido';
import EditarPedidoCliente from '../pages/Client/EditarPedido';
import EntregasDoDiaCliente from '../pages/Client/EntregasDoDia';
import EntregasDoDiaDetalhesCliente from '../pages/Client/EntregasDoDiaDetalhe';
import ProximosPedidosCliente from '../pages/Client/ProximosPedidos';
import HistoricoCliente from '../pages/Client/Historico';

// Produção
import Producao from '../pages/Production/Dashboard';
import NovoPedidoProducao from '../pages/Production/NovoPedido';
import CadastrarNovoPedidoProduction from '../pages/Production/CadastroNovoPedido';
import ProducaoDoDiaProducao from '../pages/Production/ProducaoDoDia';
import ProducaoDoDiaDetalheProducao from '../pages/Production/ProducaoDoDiaDetalhe';
import EntregasPendentesProducao from '../pages/Production/EntregasPendentes';
import ProximosPedidosProducao from '../pages/Production/ProximosPedidos';
import HistoricoProducao from '../pages/Production/Historico';
import CadastroNovoPedidoAvulso from '../pages/Admin/CadastroNovoPedidoAvulso';
import HistoricoFinal from '../pages/Production/HistoricoFinal';
import HistoricoClienteFinal from '../pages/Client/HistoricoFinal';
import RelatorioPequenosAlmocos from '../pages/Admin/RelatorioPequenosAlmocos';
import RelatorioPequenosAlmocosExplode from '../pages/Admin/RelatorioPequenosAlmocosExplode';
import TotalizadoresCliente from '../pages/Admin/TotalizadoresCliente';
import TotalizadoresNegocio from '../pages/Admin/TotalizadoresNegocio';

export default function Routes() {
  return (
    <Switch>
      <Route typeLayout="login" path="/" exact component={Login} />
      <Route
        typeLayout="criar-credenciais"
        path="/clientcredentials"
        exact
        component={CriarCredenciais}
      />
      <Route
        typeLayout="/forgotpassword"
        path="/forgotpassword"
        exact
        component={ForgotPassword}
      />
      <Route
        typeLayout="/changepassword"
        path="/changepassword"
        exact
        component={NewPassword}
      />

      {/* Admin Routes */}
      <Route isPrivate typeLayout="admin" path="/admin" component={Admin} />
      <Route isPrivate typeLayout="admin" path="/painel" component={Painel} />
      <Route
        isPrivate
        typeLayout="admin"
        path="/cadastro-usuario"
        component={CadastroNovoUsuario}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/editar-usuario/:id"
        component={EditarUsuarios}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/clientes"
        component={Clientes}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/cadastro-cliente"
        component={CadastroCliente}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/editar-cliente/:id"
        component={EditarCliente}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/editar-morada/:clientId/:addressId"
        component={EditarMoradaAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/entregadores"
        component={Entregadores}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/cadastro-entregadores"
        component={CadastroEntregadores}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/editar-entregador/:id"
        component={EditarEntregador}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/cadastro-pequenos-almocos"
        component={CadastroPequenoAlmocos}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/pequenos-almocos"
        component={PequenosAlmocos}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/editar-almocos/:id"
        component={EditarAlmocos}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/novo-pedido"
        component={NovoPedidoAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/cadastro-novo-pedido-avulso"
        component={CadastroNovoPedidoAvulso}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/cadastro-novo-pedido/:id"
        component={CadastroNovoPedidoAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/pedidos"
        component={PedidosAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/editar-pedido-inicio/:id"
        component={EditarPedidoInicioAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/editar-pedido-final/:id"
        component={EditarPedidoFinalAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/producao-do-dia"
        component={ProducaoDoDiaAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/producao-do-dia-detalhe/:id"
        component={ProducaoDoDiaDetalheAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/entregas-pendentes"
        component={EntregasPendentesAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/proximos-pedidos"
        component={ProximosPedidosAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-totalizador-cliente"
        component={TotalizadoresCliente}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-totalizador-negocio"
        component={TotalizadoresNegocio}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-pequenos-almocos"
        component={RelatorioPequenosAlmocos}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-pequenos-almocos-explode/:id"
        component={RelatorioPequenosAlmocosExplode}
      />

      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-cliente"
        component={RelatorioClienteAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-cliente-explode/:id"
        component={RelatorioClienteExplodeAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-cliente-explode-final/:id"
        component={RelatorioClienteExplodeFinalAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-negocio"
        component={RelatorioNegocioAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-negocio-explode/:date"
        component={RelatorioNegocioExplodeAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-negocio-explode-final/:id"
        component={RelatorioNegocioExplodeFinalAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-entregadores"
        component={RelatorioEntregadoresAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-entregadores-explode/:id"
        component={RelatorioEntregadoresExplodeAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-entregadores-explode-inicial/:date/:id"
        component={RelatorioEntregadoresExplodeInicialAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/relatorio-entregadores-explode-final/:id"
        component={RelatorioEntregadoresExplodeFinalAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/historico"
        component={HistoricoAdmin}
      />
      <Route
        isPrivate
        typeLayout="admin"
        path="/historico-final/:id"
        component={HistoricoFinalAdmin}
      />

      {/* Client Routes */}
      <Route
        isPrivate
        typeLayout="cliente"
        path="/cliente"
        component={Cliente}
      />
      <Route
        isPrivate
        typeLayout="cliente"
        path="/novo-pedido-cliente"
        component={NovoPedidoCliente}
      />
      <Route
        isPrivate
        typeLayout="cliente"
        path="/cadastro-novo-pedido-cliente/:id"
        component={CadastrarNovoPedidoCliente}
      />

      <Route
        isPrivate
        typeLayout="cliente"
        path="/editar-pedido-cliente/:id"
        component={EditarPedidoCliente}
      />

      <Route
        isPrivate
        typeLayout="cliente"
        path="/entregas-do-dia-cliente"
        component={EntregasDoDiaCliente}
      />
      <Route
        isPrivate
        typeLayout="cliente"
        path="/entregas-do-dia-detalhe-cliente/:id"
        component={EntregasDoDiaDetalhesCliente}
      />

      <Route
        isPrivate
        typeLayout="cliente"
        path="/proximos-pedidos-cliente"
        component={ProximosPedidosCliente}
      />

      <Route
        isPrivate
        typeLayout="cliente"
        path="/historico-cliente"
        component={HistoricoCliente}
      />

      <Route
        isPrivate
        typeLayout="cliente"
        path="/historico-cliente-final/:id"
        component={HistoricoClienteFinal}
      />

      {/* Productions Routes */}
      <Route
        isPrivate
        typeLayout="production"
        path="/production"
        component={Producao}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/novo-pedido-producao"
        component={NovoPedidoProducao}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/cadastro-novo-pedido-producao/:id"
        component={CadastrarNovoPedidoProduction}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/producao-do-dia-producao"
        component={ProducaoDoDiaProducao}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/producao-do-dia-detalhe-prod/:id"
        component={ProducaoDoDiaDetalheProducao}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/entregas-pendentes-producao"
        component={EntregasPendentesProducao}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/proximos-pedidos-producao"
        component={ProximosPedidosProducao}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/historico-producao"
        component={HistoricoProducao}
      />
      <Route
        isPrivate
        typeLayout="production"
        path="/historico-final-producao/:id"
        component={HistoricoFinal}
      />
    </Switch>
  );
}
