import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1420px;
  box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.04);

  height: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  max-width: 1440px;
`;
