/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import { format, isSameDay, parse, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import api from '../../../services/api';
import {
  Main,
  Section,
  InfoIcon,
  ButtonWrapperHover,
  RedRoundButton,
  Tooltip,
} from './styles';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';
import { useToast } from '../../../hooks/toast';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  additionalData: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

interface IClientParams {
  id: string;
}
const ProducaoDoDiaAdmin: React.FC = () => {
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [ordersStatus, setOrdersStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { addToast } = useToast();

  const today = new Date();

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );
    const orderArray = order.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setTodayOrders(orderByTime);
  };

  const didMount = () => {
    setLoading(true);
    getTodayOrders();
    setLoading(false);
  };

  useEffect(didMount, []);

  function handleChangeStatusConfirm(status: string, id: string) {
    setLoading(true);
    api.put(`food-order/update/status/${id}`, {
      status,
    });
    addToast({
      type: 'success',
      title: 'Status Alterado!',
      description: 'Status alterado com sucesso',
    });
    setLoading(false);

    setTimeout(() => {
      didMount();
      history.push('/');
    }, 500);
  }

  const handleChangeStatus = (value: string) => {
    setOrdersStatus(value);
  };

  const isNextDateAfterPrevious = (actualDate: string, nextDate: string) => {
    const formatActual = parse(actualDate, 'dd/MM/yyyy', new Date());
    const formatNext = parse(nextDate, 'dd/MM/yyyy', new Date());
    return isSameDay(formatActual, formatNext);
  };

  const filteredTodayOrders = todayOrders?.filter((order: any) => {
    return order.status === 'A_CONFIRMAR';
  });

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe sua produção de hoje</h1>
            <h3>
              Você possui <span>{filteredTodayOrders?.length} pedidos</span>{' '}
              pendentes para produzir
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <h2>Produção do dia {format(today, 'dd/MM/yyyy')}</h2>
            <div className="flex-container">
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status</h3>
              </div>
              <div className="item">Item</div>
            </div>

            {!loading &&
              todayOrders?.length !== 0 &&
              todayOrders?.map(order => (
                <div key={order.id}>
                  <div className="flex-container">
                    <div>{order.client}</div>
                    <div>{order.deliveryTime.substr(0, 5)}</div>
                    <div>
                      {order.deliveryAddress[0].street} n
                      {order.deliveryAddress[0].streetNumber}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {order.apartment}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '16px',
                      }}
                    >
                      {order.lunchs.map((i, index) => (
                        <div key={String(index)}>
                          {index < 3 ? `${i.quantity} - ${i.order}` : `...`}
                        </div>
                      ))}
                      <div
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        {order.additionalData && (
                          <ButtonWrapperHover>
                            {order.additionalData && (
                              <>
                                <RedRoundButton>
                                  <span>!</span>
                                </RedRoundButton>
                                <Tooltip>{order.additionalData}</Tooltip>
                              </>
                            )}
                          </ButtonWrapperHover>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className={confirmStatus(order.status)}>
                        <span>{formatStatus(order.status)}</span>
                      </div>
                    </div>
                    <div>
                      <div className="changeStatusSelect">
                        <select
                          defaultValue=""
                          name="changeStatus"
                          id="changeStatus"
                          onChange={event =>
                            handleChangeStatus(event.target.value)
                          }
                        >
                          <option value="A Confirmar">Mudar Status</option>

                          <option value="A_CONFIRMAR">A Confirmar</option>
                          <option value="EM_PRODUCAO">Em Produção</option>
                          <option value="FINALIZADO">Finalizado</option>
                          <option value="ENTREGUE">Entregue</option>
                          <option value="CANCELADA">Cancelada</option>
                        </select>
                        <Button
                          onClick={() =>
                            handleChangeStatusConfirm(ordersStatus, order.id)
                          }
                        >
                          Ok
                        </Button>
                      </div>
                    </div>
                    <div className="lastDiv">
                      <Link to={`/producao-do-dia-detalhe/${order.id}`}>
                        <Button className="button-plus">
                          <span>+</span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            {todayOrders?.length === 0 && (
              <h2>Você não possui Pedidos para produção...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default ProducaoDoDiaAdmin;
