/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 8,
    marginLeft: 6,
    marginRight: 6,
    backgroundColor: '#FCFCFC',
  },

  header: {
    flexDirection: 'row',
  },

  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  menu: {
    width: '100%',
    paddingRight: 4,
    flexDirection: 'row',
    borderTop: '1px solid #2E3135',
    borderBottom: '1px solid #2E3135',
  },

  content: {
    width: '100%',
    paddingRight: 4,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  section: {
    margin: 8,
    maxWidth: 255,
    paddingLeft: 9,
    paddingRight: 9,
    paddingTop: 4,
    paddingBottom: 4,
    flexGrow: 1,
  },

  main: {
    alignContent: 'flex-start',
    textAlign: 'center',
    width: 140,
    maxWidth: 270,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    flexGrow: 1,
    borderBottom: '1px solid #E7E7E7',
  },

  image: {
    maxWidth: 180,
    objectFit: 'cover',
    width: '60%',
    height: 50,
  },

  title: {
    fontSize: 12,
    marginBottom: 6,
    fontWeight: 'bold',
  },

  subtitle: {
    fontSize: 10.5,
    fontWeight: 'normal',
    marginRight: 8,
  },

  boxtitle: {
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 9,
  },

  text: {
    textAlign: 'left',
    alignItems: 'flex-start',
    fontSize: 9,
    marginLeft: 8,
  },
});

interface IHistoricAdmin {
  id: string;
  clientId: string;
  client: string;
  groupId: string;
  deliveryDate: string;
  deliveryTime: string;
  createdAt: string;
  address: string;
  totalCost: string;
  totalQuantity: number;
}

interface IHistoricAdminAll {
  data: IHistoricAdmin[];
}

interface IProps {
  start: string;
  end: string;
  history: IHistoricAdminAll;
}

function PDFHistoricoClient({ history, start, end }: IProps) {
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View wrap={false} style={styles.header}>
          <View style={styles.section}>
            <Image style={styles.image} src="/img/logo.png" />
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Histórico de Pedidos</Text>
            <Text style={styles.title}>Pedidos por Período</Text>
            <Text style={styles.subtitle}>
              Período de {start} a {end}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              Emissão:
              <Text style={styles.text}>
                {format(new Date(), 'dd/MM/yyyy')}
              </Text>
            </Text>
            <Text style={styles.subtitle}>
              Horário:{' '}
              <Text style={styles.text}>{format(new Date(), 'HH:mm')}</Text>
            </Text>
          </View>
        </View>

        <View style={styles.container}>
          <View style={styles.menu}>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Data do Pedido</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Data de Entrega</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Horário de Entrega</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Cliente</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Quantidade</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Endereço</Text>
            </View>
          </View>
          {history.data.map((i, index) => (
            <View key={String(index)} style={styles.content}>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>
                  {format(parseISO(i.createdAt), 'dd/MM/yyyy')}
                </Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>
                  {format(parseISO(i.deliveryDate), 'dd/MM/yyyy')}
                </Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>
                  {i.deliveryTime.substr(0, 5)}
                </Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.client}</Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.totalQuantity}</Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.address}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}

export default PDFHistoricoClient;
