/* eslint-disable camelcase */
import React, { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import logoImg from '../../assets/logo.svg';

import { Container, Content, Background } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

interface ResetPasswordFormData {
  newPassword: string;
  password_confirmation: string;
}

const NewPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const history = useHistory();
  const location = useLocation();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          newPassword: Yup.string().required('Senha obrigatória'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('newPassword')],
            'Confirmação incorreta',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { newPassword, password_confirmation } = data;
        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        setLoading(true);
        await api.post('/mail/changepassword', {
          newPassword,
          password_confirmation,
          token,
        });

        setLoading(false);
        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao cadastrar senha',
          description:
            'Ocorreu um erro ao cadastrar sua senha, tente novamente.',
        });
      }
    },
    [addToast, history, location.search],
  );
  return (
    <Container>
      <div className="sideBar">
        <div>
          <h1>UM PEQUENO-ALMOÇO DE HOTEL À SUA PORTA</h1>
        </div>
        <div>
          <h2>
            Administre, controle e faça novos pedidos através da Plataforma,
            aumente sua produtividade e a satisfação de seu cliente.
          </h2>
        </div>
      </div>
      <Background />
      <Content>
        <img src={logoImg} alt="Bib" />
        <h1>CADASTRE SUA NOVA SENHA</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h5>Digite sua Senha</h5>
          <Input type="password" name="newPassword" id="newPassword" />
          <h5>Confirme sua Nova Senha</h5>
          <Input
            type="password"
            name="password_confirmation"
            id="password_confirmation"
          />
          <Button loading={loading} type="submit">
            Salvar nova senha
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default NewPassword;
