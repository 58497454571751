/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 8,
    marginLeft: 6,
    marginRight: 6,
    backgroundColor: '#FCFCFC',
  },

  header: {
    flexDirection: 'row',
  },

  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  menu: {
    flexDirection: 'row',
    borderTop: '1px solid #2E3135',
    borderBottom: '1px solid #2E3135',
  },

  content: {
    flexDirection: 'row',
  },

  section: {
    margin: 8,
    maxWidth: 215,
    padding: 9,
    flexGrow: 1,
  },

  main: {
    alignContent: 'flex-start',
    textAlign: 'center',
    maxWidth: 210,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    flexGrow: 1,
    borderBottom: '1px solid #E7E7E7',
  },

  image: {
    maxWidth: 180,
    objectFit: 'cover',
    width: '60%',
    height: 50,
  },

  title: {
    fontSize: 12,
    marginBottom: 6,
    fontWeight: 'bold',
  },

  subtitle: {
    fontSize: 10.5,
    fontWeight: 'normal',
    marginRight: 8,
  },

  boxtitle: {
    textAlign: 'left',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontSize: 9,
  },

  text: {
    textAlign: 'left',
    alignItems: 'flex-start',
    fontSize: 9,
    marginLeft: 8,
  },
});

interface IDeliveryAddress {
  street: string;
  streetNumber: string;
  neighborhood: string;
  doorCode: string;
  deliveryPlace: string;
}

interface IDeliverymanLunch {
  quantity: number;
  lunchCost: number;
  lunchType: string;
}
interface IDeliveryman {
  id: string;
  deliverymanId: string;
  client: string;
  deliverymanName: string;
  deliveryDate: string;
  lunchs: IDeliverymanLunch[];
  deliveryAddress: IDeliveryAddress[];
  total: number;
  totalDeliverys: number;
}

interface IDeliverymanAll {
  data: IDeliveryman[];
  deliverymanName: string;
}

interface IProps {
  deliverymanName: string;
  deliveryDate: string;
  client: IDeliverymanAll;
}

function PDFDeliveryManExplodeInicial({
  client,
  deliveryDate,
  deliverymanName,
}: IProps) {
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View wrap={false} style={styles.header}>
          <View style={styles.section}>
            <Image style={styles.image} src="/img/logo.png" />
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Relatório de Entregadores</Text>
            <Text style={styles.title}>Entregador: {deliverymanName}</Text>
            <Text style={styles.subtitle}>
              Data: {format(new Date(deliveryDate), 'dd/MM/yyyy')}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              Emissão:
              <Text style={styles.text}>
                {format(new Date(), 'dd/MM/yyyy')}
              </Text>
            </Text>
            <Text style={styles.subtitle}>
              Horário:
              <Text style={styles.text}>{format(new Date(), 'HH:mm')}</Text>
            </Text>
          </View>
        </View>

        <View style={styles.container}>
          <View style={styles.menu}>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Cliente</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Endereço de Entrega</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Quantidade</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Pedido</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Valor Gerado</Text>
            </View>
          </View>
          {client.data.map((i: any) => (
            <View key={i.id} style={styles.content}>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.client}</Text>
              </View>
              <View style={styles.main}>
                {i.deliveryAddress.map((add: any) => (
                  <Text key={add.id} style={styles.text}>
                    {add.street}-{add.streetNumber} - {add.neighborhood}
                  </Text>
                ))}
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.totalDeliverys}</Text>
              </View>
              <View style={styles.main}>
                {i.lunchs.map((lunch: any) => (
                  <Text key={lunch.id} style={styles.text}>
                    {lunch.quantity} - {lunch.lunchType}
                  </Text>
                ))}
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.total}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}

export default PDFDeliveryManExplodeInicial;
