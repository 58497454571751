/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { addDays, format, subYears } from 'date-fns';
import parseISO from 'date-fns/esm/fp/parseISO/index.js';
import { CSVLink } from 'react-csv';
import DatePicker from '../../../components/DatePicker/index.js';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section, Calender } from './styles';
import PDFHistorico from './PDFHistorico';
import { convertDateString } from '../../../utils/convertDateString';

interface IHistoricAdmin {
  id: string;
  clientId: string;
  client: string;
  groupId: string;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  createdAt: string;
  address: string;
  apartment: string;
  totalCost: string;
  totalQuantity: number;
}

interface IHistoricAdminAll {
  data: IHistoricAdmin[];
}

const HistoricoAdmin: React.FC = () => {
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [initialHistory, setInitialHistory] = useState<IHistoricAdminAll>({
    data: [],
  });
  const [historic, setHistoric] = useState<IHistoricAdminAll>({ data: [] });
  const [startDate, setStartDate] = useState(subYears(new Date(), 1));
  const [lastDate, setLastDate] = useState(new Date());
  const [date, setDate] = useState<Date>(new Date());

  const changeDate = (selectDate: Date) => {
    setDate(selectDate);
  };

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IHistoricAdminAll>(
        `/reports/historic/adminday?start=${format(date, 'yyyy-MM-dd')}`,
      );
      setInitialHistory(response.data);
      setHistoric(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleSearch = (event: any) => {
    event.target.value;
    if (!event.target.value) {
      setHistoric(initialHistory!);
      return;
    }

    const filterClients = historic!.data.filter(
      // eslint-disable-next-line no-shadow
      (history: any) => history.name.includes(event.target.value),
    );

    if (filterClients && filterClients.length) {
      setHistoric({ data: filterClients });
    }
  };

  useEffect(didMount, [date]);

  const newHistoricData = historic.data.map(i => {
    const newCreatedAt = format(parseISO(i.createdAt), 'dd/MM/yyyy');
    const newDeliveryDate = format(parseISO(i.deliveryDate), 'dd/MM/yyyy');
    const newDeliveryTime = i.deliveryTime.substr(0, 5);
    return {
      ...i,
      newDeliveryTime,
      newDeliveryDate,
      newCreatedAt,
    };
  });

  const csvHeaders = [
    { label: 'Data do Pedido', key: 'newCreatedAt' },
    { label: 'Data de Entrega', key: 'newDeliveryDate' },
    {
      label: 'Cliente',
      key: 'client',
    },
    {
      label: 'Entregador',
      key: 'deliverymanName',
    },
    {
      label: 'Quantidade',
      key: 'totalQuantity',
    },
    {
      label: 'Apto',
      key: 'apartment',
    },
    {
      label: 'Endereço',
      key: 'address',
    },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Histórico de Pedidos</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="delivery-date">
              <div>
                <Calender>
                  <DatePicker
                    startDate={startDate}
                    lastDate={lastDate}
                    getSelectedDay={(e: Date) => changeDate(e)}
                    labelFormat="MMMM"
                    color="#BF1E2D"
                    selectDate={date}
                    endOfScrollCalendar
                  />
                </Calender>
              </div>
            </div>
            <h4>Data Selecionada: {format(date, 'dd/MM/yyyy')} </h4>
          </div>
          <div className="sectionCard">
            <h2>Últimos Pedidos</h2>
            <div className="flex-container">
              <div>
                <h3>Data do Pedido</h3>
              </div>
              <div>
                <h3>Data de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Entregador</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Apto</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {historic &&
              historic.data.map((i, index) => (
                <div className="flex-container" key={String(index)}>
                  <div>{format(parseISO(i.createdAt), 'dd/MM/yyyy')}</div>
                  <div>{convertDateString(i.deliveryDate)}</div>
                  {/* <div>{i.deliveryTime.substr(0, 5)}</div> */}
                  <div>{i.client}</div>
                  <div>
                    {i.deliverymanName === null
                      ? 'Não Possui'
                      : i.deliverymanName}
                  </div>
                  <div>{i.totalQuantity}</div>
                  <div>{i.apartment}</div>
                  <div>{i.address}</div>
                  <div>
                    <Link to={`/historico-final/${i.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {historic?.data.length === 0 && (
              <h2>Não há Pedidos entregues nessa data...</h2>
            )}
            {loadingV2 && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFHistorico
                        history={historic}
                        start={format(date, 'dd/MM/yyyy')}
                      />
                    }
                    fileName="historico-de-pedidos-admin.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Exportar PDF</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={newHistoricData}
                      headers={csvHeaders}
                      separator=";"
                      filename="historico-de-pedidos-admin.csv"
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default HistoricoAdmin;
