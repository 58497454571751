/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { formatDistance, parseISO } from 'date-fns/esm';
import { pt } from 'date-fns/esm/locale';
import { FiBell } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';
import 'react-dropdown/style.css';
import { formatStatus, confirmStatus } from '../../utils/formatStatus';
import { Container, Circle } from './styles';
import api from '../../services/api';

interface NotificationProps {
  message: string;
  status: string;
  orderId: string;
  userId: string;
  timeDistance: string;
}

const Notification: React.FC = () => {
  const [notification, setNotification] = useState<NotificationProps[]>([]);
  const [newNotication, setNewNotification] = useState(false);
  const [visible, setVisible] = useState(false);
  const mountedRef = useRef(true);

  const user = JSON.parse(window.localStorage.getItem('@BiB:user') || '{}');

  const handleClickNewNotification = useCallback(() => {
    setNewNotification(false);
  }, []);

  useEffect(() => {
   io('https://bib-api.herokuapp.com', {
   // io('http://localhost:5000', {
      query: {
        id: user.id,
      },
    }).on('notification', (data: any) => {
      setNewNotification(true);
      setNotification([data, ...notification]);
    });
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    async function loadNotifications() {
      const response = await api.get('/client/notifications');

      const data = response.data.map((notification: any) => ({
        ...notification,
        timeDistance: formatDistance(
          parseISO(notification.createdAt),
          new Date(),
          { addSuffix: true, locale: pt },
        ),
      }));
      if (data.length > 0) {
        setNotification(data);
        setNewNotification(true);
      } else {
        setNotification(data);
        setNewNotification(false);
      }
    }
    loadNotifications();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  return (
    <Container>
      <Circle onClick={handleToggleVisible} isVisible={newNotication} />
      <div className="notificationsDropdown">
        <FiBell />
        <div className="notifications">
          {notification.map((msg, index) => (
            <div key={String(index)} className="msgItem">
              <div>
                <Link
                  to={`/producao-do-dia-detalhe-prod/${msg.orderId}`}
                  onClick={handleClickNewNotification}
                >
                  <div className="time">
                    <h6>{msg.timeDistance}</h6>
                  </div>
                  <div className="item">
                    <span>{msg.message} para: </span>
                    <div className={confirmStatus(msg.status)}>
                      <span>{formatStatus(msg.status)}</span>
                    </div>
                  </div>
                </Link>
                <div className="grayLine" />
              </div>
            </div>
          ))}
          {notification?.length === 0 && (
            <h3>Sem notificações no momento...</h3>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Notification;
