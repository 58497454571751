/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode } from 'react';
import { useAuth } from '../../../hooks/auth';

import HeaderClient from '../../../components/HeaderClient';
import MenuClient from '../../../components/MenuClient/index';
import { Container, Content } from './styles';

interface SiteProps {
  children: ReactNode;
}

export default function Cliente({ children }: SiteProps) {
  const { user, signOut } = useAuth();
  if (user.role !== 'CLIENT') {
    signOut();
  }

  if (user.role !== 'CLIENT') {
    return <div />;
  }

  window.scrollTo(0, 0);
  return (
    <>
      <Container>
        <HeaderClient />
        <Content>
          <MenuClient />
          {children}
        </Content>
      </Container>
    </>
  );
}
