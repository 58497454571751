import React, { useEffect, useRef, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format, parse, subDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import Input from '../../../components/Input';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import PDFDeliveryManExplode from './PDFDeliveryManExplode';

import Button from '../../../components/Button';
import { Main, Section, ChevronLeftIcon, SourceGraphic } from './styles';

interface IDeliveryman {
  deliveryDate: string;
  deliverymanId: string;
  deliverymanName: string;
  quantity: number;
  total: number;
  totalCost: number;
  totalDeliverys: number;
  totalOrders: number;
}

interface IDeliverymanAll {
  [x: string]: any;
  deliverymanName: string;
  data: IDeliveryman[];
}

const RelatorioEntregadoresExplode: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [initialClient, setInitialClient] = useState<IDeliverymanAll>();
  const [deliverymanName, setDeliverymanName] = useState<IDeliveryman>();
  const [clients, setClients] = useState<IDeliverymanAll>({
    data: [],
    deliverymanName: '',
  });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const { id }: { id: string } = useParams();

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IDeliverymanAll>(
        `/reports/deliveryman?start=${format(
          dateFilter.start,
          'yyyy-MM-dd',
        )}&end=${format(dateFilter.end, 'yyyy-MM-dd')}&deliverymanId=${id}`,
      );

      setInitialClient(response.data);
      setClients(response.data);
      setDeliverymanName(deliverymanName);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleSearch = (event: any) => {
    event.target.value;
    if (!event.target.value) {
      setClients(initialClient!);
      return;
    }

    const filterClients = clients!.filter(
      // eslint-disable-next-line no-shadow
      (clients: any) => clients.name.includes(event.target.value),
    );
    setClients(filterClients!);
  };
  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };
  useEffect(didMount, [dateFilter]);

  const csvHeaders = [
    { label: 'Entregador', key: 'deliverymanName' },
    { label: 'Quantidade de Pequenos Almoços', key: 'totalDeliverys' },
    { label: 'Quantidade de Entregas', key: 'totalOrders' },
    { label: 'Valor Total Gerados', key: 'total' },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Entregadores</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <h4>Período específico</h4>
                  <select
                    defaultValue=""
                    name="horario-entrega"
                    id="horario-entrega"
                    onChange={e =>
                      handleDateFilter(e.target.value, e.target.id)
                    }
                  >
                    <option id="last" value={7}>
                      Últimos 07 dias
                    </option>
                    <option id="last" value={15}>
                      Últimos 15 dias
                    </option>
                    <option id="last" value={30}>
                      Últimos 30 dias
                    </option>
                    <option id="last" value={60}>
                      Últimos 60 dias
                    </option>
                    <option id="last" value={90}>
                      Últimos 90 dias
                    </option>
                  </select>
                </div>
                <div>
                  <div className="delivery-date">
                    <div>
                      <span>Inicio</span>
                      <DatePicker
                        className="datapicker"
                        selected={dateFilter.start}
                        maxDate={dateFilter.end}
                        onChange={date => handleStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Fim</span>
                      <DatePicker
                        className="datapicker"
                        minDate={dateFilter.start}
                        selected={dateFilter.end}
                        onChange={date => handleEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <div className="clientName">
              <Link to="/relatorio-entregadores">
                <ChevronLeftIcon />
                <h2>{clients.deliverymanName}</h2>
              </Link>
            </div>
            <div className="flex-container">
              <div>
                <h3>Data da Entrega</h3>
              </div>
              <div>
                <h3>Quantidade de Pequenos Almoços</h3>
              </div>
              <div>
                <h3>Quantidade de Entregas</h3>
              </div>
              <div>
                <h3>Valor total Gerado</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {clients?.data.map((i, index) => (
              <div key={String(index)} className="flex-container">
                <div>{i.deliveryDate}</div>
                <div>{i.totalDeliverys}</div>
                <div>{i.totalOrders}</div>
                <div>{i.total}</div>
                <div>
                  <Link
                    to={`/relatorio-entregadores-explode-inicial/${format(
                      parse(i.deliveryDate, 'dd/MM/yyyy', new Date()),
                      'yyyy-MM-dd',
                    )}/${id}`}
                  >
                    <Button className="button-plus">
                      <span>+</span>
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFDeliveryManExplode
                        client={clients}
                        startDate={format(dateFilter.start, 'dd/MM/yyyy')}
                        endDate={format(dateFilter.end, 'dd/MM/yyyy')}
                      />
                    }
                    fileName={`relatorio-entregador-${clients.deliverymanName}`}
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Exportar PDF</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clients.data}
                      headers={csvHeaders}
                      separator=";"
                      filename="relatorio-cliente.csv"
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioEntregadoresExplode;
