/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isAfter, format } from 'date-fns';
import api from '../../../services/api';
import Button from '../../../components/Button';
import { Main, Section, FormCadastroCliente } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import lunchImg from '../../../assets/lunch.png';

interface IAddressParams {
  id: string;
}

interface IClientAddress {
  id: string;
  street: string;
  streetNumber: string;
  city: string;
  neighborhood: string;
  doorCode: string;
  numberOfBedrooms: string;
  deliveryPrice: number;
  apartments: string[];
  addressType: string;
}

interface IClientLunch {
  id: string;
  customCost: number;
  lunch: {
    id: string;
    lunchType: string;
    description: string;
    photoUrl: string;
    smallLunchPrice: number;
  };
}

interface IClientFormData {
  client: {
    id: string;
    name: string;
    username: string;
    fiscalName: string;
    clientType: string;
    companyName: string;
  };
  address: IClientAddress[];
  lunchs: IClientLunch[];
}

interface INewRequestDataLunchs {
  lunchId: string;
  lunchType?: string;
  description?: string;
  lunchCost: number;
  lunchAmount: number;
}

interface INewRequestData {
  id: string;
  additionalData: string;
  checkinDate: string;
  checkoutDate: string;
  deliveryTime: string;
  receiverName: string;
  type: string;
  status: string;
  apartment: string;
  totalCost: number;
  addressId: string;
  deliverymanId: string;
  clientId: string;
  oneShot: boolean;
  lunches: INewRequestDataLunchs[];
}

interface ISelectedAddress {
  addressId: string;
  apartment: string;
  deliveryPrice: number;
}

const CadastrarNovoPedidoClient: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const userClient = JSON.parse(
    window.localStorage.getItem('@BiB:user') || '{}',
  );

  const [pickedLunchs, setPickedLunchs] = useState<INewRequestDataLunchs[]>([]);

  const [startDate, setStartDate] = useState<any>(new Date());
  const [checkoutDate, setCheckoutDate] = useState<any>(new Date(new Date()));
  const [deliveryTime, setDeliveryTime] = useState<any>();
  const [deliverymanId, setDeliverymanId] = useState<string>();
  const [selectedAddress, setSelectedAddress] = useState<ISelectedAddress>();

  const { id } = useParams<IAddressParams>();

  const [clientData, setClientData] = useState<IClientFormData>(
    {} as IClientFormData,
  );

  const readClientData = async () => {
    try {
      setLoading(true);

      const response = await api.get(`/client/read/${userClient.id}`);
      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: error.message || 'Oops ocorreu um erro ao obter os dados',
      });
    }
  };

  useEffect(() => {
    readClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const totalFoodCost = () => {
    const totalFood = pickedLunchs.reduce(
      (a, b) => a + b.lunchCost * b.lunchAmount,
      0,
    );

    return Math.round(totalFood * 100) / 100;
  };
  const totalDeliveryCost = () => {
    const totalFoodQuantity = pickedLunchs.reduce(
      (a, b) => a + b.lunchAmount,
      0,
    );

    const deliveryCost = selectedAddress ? selectedAddress.deliveryPrice : 0;

    return Math.round(totalFoodQuantity * deliveryCost * 100) / 100;
  };
  const totalCost = (foodCost: number, deliveryCost: number) => {
    return foodCost + deliveryCost;
  };

  function handleBack() {
    history.go(-1);
  }

  const handleSelectedAddress = (
    addressId: string,
    apartment: string,
    deliveryPrice: number,
  ) => {
    setSelectedAddress({ addressId, apartment, deliveryPrice });
  };

  const handleSubmit = async (data: INewRequestData) => {
    try {
      formRef.current?.setErrors({});

      data.additionalData = '';
      data.checkinDate = format(startDate, 'yyyy-MM-dd');
      data.checkoutDate = format(checkoutDate, 'yyyy-MM-dd');
      data.deliveryTime = deliveryTime;
      data.receiverName = '';
      data.type = clientData.client.clientType;
      data.status = 'A_CONFIRMAR';
      data.apartment = selectedAddress!.apartment;
      data.totalCost = totalCost(totalFoodCost(), totalDeliveryCost());
      data.addressId = selectedAddress!.addressId;
      data.clientId = userClient.id;
      data.lunches = pickedLunchs;
      data.oneShot = false;
      data.deliverymanId = deliverymanId || '';

      setLoading(true);

      await api.post('/food-order/create', data);
      window.scrollTo(0, 0);

      addToast({
        type: 'success',
        title: 'Novo pedido realizado',
        description: 'Pedido efetuado com sucesso',
      });

      setLoading(false);
      history.push('/');
    } catch (err) {
      if (err instanceof ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
      window.scrollTo(0, 0);
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description:
          JSON.parse(err.request.response).message ||
          'Houve um erro no cadastro',
      });
      history.push('/novo-pedido-cliente');
    }
  };

  const handleCheckinDate = (date: any) => {
    if (isAfter(date, checkoutDate)) {
      setCheckoutDate(date);
    }

    setStartDate(date);
  };
  const handleCheckoutDate = (date: any) => {
    setCheckoutDate(date);
  };

  const handleDeliveryTime = (time: string) => {
    setDeliveryTime(time);
  };

  const pickedLunchAmount = (lunchId: string) => {
    const filter = pickedLunchs.filter(i => i.lunchId === lunchId)[0];

    return filter && filter.lunchAmount ? filter.lunchAmount : 0;
  };

  const handlePickedLunch = ({
    lunchId,
    checked,
  }: {
    lunchId: string;
    checked: boolean;
  }) => {
    if (checked) {
      if (pickedLunchs.some(i => i.lunchId === lunchId)) {
        // setPickedLunchs(pickedLunchs.map((i) => i.lunchId === lunchId ? i : {.}))
      } else {
        const selectedLunch = clientData.lunchs.filter(
          lunch => lunch.lunch.id === lunchId,
        )[0];

        const correctedLunchValue = Number(
          selectedLunch.customCost !== null && selectedLunch.customCost !== 0
            ? selectedLunch.customCost
            : selectedLunch.lunch.smallLunchPrice,
        );

        const obj = {
          lunchId,
          lunchCost: correctedLunchValue,
          lunchAmount:
            pickedLunchAmount(lunchId) === 0 ? 1 : pickedLunchAmount(lunchId),
        };

        setPickedLunchs([...pickedLunchs, obj]);
      }
    } else {
      const selected = pickedLunchs.filter(lunch => lunch.lunchId !== lunchId);
      setPickedLunchs(selected);
    }
  };

  const increaseLunchAmount = (lunchId: string, amount: number) => {
    if (amount > 0) {
      if (pickedLunchs.some(i => i.lunchId === lunchId)) {
        const newArray = pickedLunchs.map(lunch =>
          lunch.lunchId === lunchId ? { ...lunch, lunchAmount: amount } : lunch,
        );
        setPickedLunchs(newArray);
      } else {
        const selectedLunch = clientData.lunchs.filter(
          lunch => lunch.lunch.id === lunchId,
        )[0];

        const correctedLunchValue = Number(
          selectedLunch.customCost !== null && selectedLunch.customCost !== 0
            ? selectedLunch.customCost
            : selectedLunch.lunch.smallLunchPrice,
        );

        const obj = {
          lunchId,
          lunchCost: correctedLunchValue,
          lunchAmount: 1,
        };

        setPickedLunchs([...pickedLunchs, obj]);
      }
    }

    if (amount === 0) {
      const selected = pickedLunchs.filter(lunch => lunch.lunchId !== lunchId);
      setPickedLunchs(selected);
    }
  };

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Novo Pedido</h1>
          </div>
          <Link to="/novo-pedido-cliente">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            {!loading && clientData.client.companyName && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormCadastroCliente>
                  <div className="destino">
                    <div>
                      <h2>Destino</h2>
                    </div>
                  </div>
                  <div className="client">
                    <div>
                      <h4>{clientData.client.companyName}</h4>
                    </div>
                    <div>
                      {clientData?.address.length > 0 &&
                        clientData?.address.map(add => (
                          <div key={add.id}>
                            <div className="addressList">
                              <h5>Endereço: </h5>
                              <h5>{add.street} -</h5>
                              <h5>{add.streetNumber}- </h5>
                              <h5>{add.neighborhood}- </h5>
                              <h5>{add.doorCode}- </h5>
                              <h5>{add.city}</h5>
                            </div>
                            <div>
                              <div className="apartamentsList">
                                <h5>Apartamento</h5>
                                <div className="apartaments">
                                  {add.apartments &&
                                  add.apartments.length > 0 ? (
                                    add.apartments.map((i, index) => (
                                      <div key={String(index)}>
                                        <input
                                          type="radio"
                                          id={add.id}
                                          name="apartment"
                                          value={i}
                                          onChange={event =>
                                            handleSelectedAddress(
                                              event.target.id,
                                              event.target.value,
                                              add.deliveryPrice,
                                            )
                                          }
                                        />
                                        <label>{i}</label>
                                      </div>
                                    ))
                                  ) : (
                                    <div>
                                      <p>Sem Apartamentos Cadastrados</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {clientData.address.length === 0 && (
                        <h3>Você não possui endereço cadastrado...</h3>
                      )}
                    </div>
                  </div>
                  <div className="lunchTitle">
                    <h2>Selecione a data a entregar</h2>
                  </div>
                  <div className="delivery-date">
                    <div>
                      <span>1º dia de Pequeno Almoço</span>
                      <DatePicker
                        allowSameDay
                        minDate={startDate}
                        className="datapicker"
                        selected={startDate}
                        onChange={date => handleCheckinDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Último dia de Pequeno Almoço</span>
                      <DatePicker
                        allowSameDay={false}
                        className="datapicker"
                        selected={checkoutDate}
                        onChange={date => handleCheckoutDate(date)}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                  <div className="delivery-time">
                    <span>Horário de Entrega</span>
                    <select
                      defaultValue=""
                      name="horario-entrega"
                      id="horario-entrega"
                      onChange={event => handleDeliveryTime(event.target.value)}
                    >
                      <option value="07:30">Selecionar Horário</option>
                      <option value="07:30">07:30</option>
                      <option value="08:00">08:00</option>
                      <option value="08:30">08:30</option>
                      <option value="09:00">09:00</option>
                      <option value="09:30">09:30</option>
                      <option value="10:00">10:00</option>
                      <option value="10:30">10:30</option>
                      <option value="11:00">11:00</option>
                    </select>
                    <span>Observações</span>
                    <textarea name="observacoes" id="observacoes" />
                  </div>

                  <div className="lunchs">
                    <h2>Selecione seu Pedido</h2>

                    <div className="lunch-list">
                      <div className="select-lunch">
                        {!loading &&
                          clientData.lunchs.length > 0 &&
                          clientData.lunchs.map(item => (
                            <div key={item.id} className="lunch-item">
                              <div className="lunch-img-title">
                                <div>
                                  <img src={lunchImg} alt="" />
                                </div>
                                <div>
                                  <h4>
                                    {item.lunch.lunchType
                                      ? item.lunch.lunchType
                                      : 'Nome do Pequeno Almoço'}
                                  </h4>
                                </div>
                              </div>
                              <div className="lunch-cost">
                                <span>Quantidade</span>
                                <input
                                  type="number"
                                  placeholder=""
                                  min={Number(0)}
                                  onKeyDown={event => event.preventDefault()}
                                  value={pickedLunchAmount(item.lunch.id)}
                                  name="lunchs.customLunchCost"
                                  id={item.lunch.id}
                                  onChange={event =>
                                    increaseLunchAmount(
                                      event.target.id,
                                      Number(event.target.value),
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  defaultValue={item.lunch.id}
                                  id={item.lunch.id}
                                  checked={pickedLunchs.some(
                                    i => i.lunchId === item.lunch.id,
                                  )}
                                  name="lunchs.lunchId"
                                  onChange={({ target }) =>
                                    handlePickedLunch({
                                      checked: target.checked,
                                      lunchId: target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        {clientData.lunchs?.length === 0 && (
                          <h4>
                            Não há Pequenos Almoços cadastrados, entre em
                            contato com a BiB...
                          </h4>
                        )}
                        {loading && (
                          <div className="loadingData">
                            <span>Carregando...</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <h4 style={{ marginTop: 15 }}>
                      Total: € {totalCost(totalFoodCost(), totalDeliveryCost())}
                    </h4>
                  </div>
                  <div className="bottomPedidos">
                    <Button loading={loading} type="submit">
                      Finalizar Pedido
                    </Button>
                    <Button className="allItems" onClick={handleBack}>
                      Voltar
                    </Button>
                  </div>
                </FormCadastroCliente>
              </Form>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default CadastrarNovoPedidoClient;
