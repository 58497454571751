/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DatePicker from 'react-horizontal-datepicker';
import { format, parseISO } from 'date-fns';

import Button from '../../../components/Button';
import Input from '../../../components/Input';

import {
  Main,
  Section,
  SearchIcon,
  Calender,
  ButtonWrapperHover,
  RedRoundButton,
  Tooltip,
} from './styles';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';
import api from '../../../services/api';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  createdAt: string;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  additionalData: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

const ProximosPedidosProduction: React.FC = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(true);
  const [allOrders, setAllOrders] = useState<ITodayOrders[]>();

  const changeDate = (day: Date) => {
    setDate(day);
  };

  const getDayOrders = async () => {
    const orders = await api.get<ITodayOrders[]>(
      `food-order/day/${format(date, 'yyyy-MM-dd')}`,
    );
    const orderArray = orders.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      // eslint-disable-next-line no-param-reassign
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setAllOrders(orderByTime);
  };

  const didMount = () => {
    setLoading(true);
    getDayOrders();
    setLoading(false);
  };

  useEffect(didMount, [date]);

  const getQuantity = (lunchs: ITodayOrdersLunch[]) => {
    let amount = 0;

    lunchs.forEach(lunch => (amount += Number(lunch.quantity)));

    return amount;
  };

  const pendingOrdersToDelivery = allOrders?.filter((order: any) => {
    return order.status !== 'ENTREGUE';
  });

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Produção Pendente</h1>
            <h3>Acompanhe a sua produção no período selecionado</h3>
          </div>
          <Link to="/novo-pedido-producao">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <Calender>
              <DatePicker
                getSelectedDay={(e: Date) => changeDate(e)}
                labelFormat="MMMM"
                color="#BF1E2D"
                endDate={90}
              />
            </Calender>
            <Form onSubmit={() => {}}>
              <div className="searchBar">
                <SearchIcon />
                <Input
                  name="search"
                  type="text"
                  placeholder="Procure por nome ou endereço"
                />
              </div>
            </Form>
            <h2>Produção Pendente</h2>
            <div className="flex-container">
              <div>
                <h3>Data da Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status da Produção</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {!loading &&
              pendingOrdersToDelivery?.length !== 0 &&
              pendingOrdersToDelivery !== undefined &&
              pendingOrdersToDelivery.map(order => (
                <div key={order.id} className="flex-container">
                  <div>
                    {format(parseISO(order.deliveryDate) as any, 'dd/MM/yyyy')}
                  </div>{' '}
                  <div>{order.deliveryTime}</div>
                  <div>
                    {order.client} - Apartamento {order.apartment}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '16px',
                    }}
                  >
                    {order.lunchs.map((i, index) => (
                      <div key={String(index)}>
                        {index < 3 ? `${i.quantity} - ${i.order}` : `...`}
                      </div>
                    ))}
                    <div
                      style={{ position: 'relative', display: 'inline-block' }}
                    >
                      {order.additionalData && (
                        <ButtonWrapperHover>
                          {order.additionalData && (
                            <>
                              <RedRoundButton>
                                <span>!</span>
                              </RedRoundButton>
                              <Tooltip>{order.additionalData}</Tooltip>
                            </>
                          )}
                        </ButtonWrapperHover>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={confirmStatus(order.status)}>
                      <span>{formatStatus(order.status)}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={`/producao-do-dia-detalhe-prod/${order.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {pendingOrdersToDelivery?.length === 0 && (
              <h2>Não há pedidos pendentes para essa data...</h2>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default ProximosPedidosProduction;
