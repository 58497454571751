import React, { useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import api from '../../../../services/api';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { useToast } from '../../../../hooks/toast';
import { IClientFormData, INewClientDataBasic } from '../types';

interface IProps {
  clientData: IClientFormData;
  loadingStatus(status: boolean): void;
  readClientData(): void;
}

const EditarClientBasic: React.FC<IProps> = ({
  clientData,
  loadingStatus,
  readClientData,
}) => {
  const { addToast } = useToast();

  const [clientBasic, setClientBasic] = useState<INewClientDataBasic | any>({
    companyName: clientData.client.companyName,
    nif: clientData.client.nif,
    contactEmail: clientData.client.contactEmail,
    contactName: clientData.client.contactName,
    fiscalName: clientData.client.fiscalName,
    password: clientData.client.password,
  });
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  const handleClientBasic = ({ input, data }: { input: any; data: any }) => {
    const main = clientBasic;

    main![input] = data;

    setClientBasic({ ...clientBasic, input: data });
  };

  const updateBasic = async () => {
    try {
      setLocalLoading(true);
      await api.put(`client/update/basic/${clientData.client.id}`, clientBasic);
      await readClientData();
      addToast({
        type: 'success',
        title: 'Edição Realizada!',
        description: 'Edição Realizada!',
      });
      window.scrollTo(0, 0);
      setLocalLoading(false);
    } catch (error) {
      setLocalLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na Atualização de Dados Básicos',
        description: 'Erro na Atualização de Dados Básicos',
      });
      window.scrollTo(0, 0);
    }
  };

  useEffect(
    () =>
      setClientBasic({
        companyName: clientData.client.companyName,
        contactEmail: clientData.client.contactEmail,
        nif: clientData.client.nif,
        contactName: clientData.client.contactName,
        contactPhone: clientData.client.contactPhone,
        fiscalName: clientData.client.fiscalName,
        password: clientData.client.password,
      }),
    [clientData],
  );

  return (
    <>
      <div className="formulario-cadastro">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Editar Dados Básicos</h2>
          <div style={{ marginLeft: 35 }}>
            {localLoading && <PulseLoader color="#bf1e2d" />}
          </div>
        </div>
        <div style={{ marginTop: 54 }}>
          <div className="form-inputs">
            <h5>Nome da Empresa</h5>
            <Input
              name="companyName"
              id="companyName"
              type="text"
              onChange={({ target: { id, value } }) =>
                handleClientBasic({ data: value, input: id })
              }
              defaultValue={clientBasic.companyName}
            />
          </div>
          <div className="form-inputs">
            <h5>Nome Fiscal</h5>
            <Input
              name="fiscalName"
              type="text"
              id="fiscalName"
              onChange={({ target: { id, value } }) =>
                handleClientBasic({ data: value, input: id })
              }
              defaultValue={clientBasic.fiscalName}
            />
          </div>
        </div>
        <div>
          <div>
            <div className="form-inputs">
              <h5>Nome do Contacto</h5>
              <Input
                name="contactName"
                type="text"
                onChange={({ target: { id, value } }) =>
                  handleClientBasic({ data: value, input: id })
                }
                id="contactName"
                defaultValue={clientBasic.contactName}
              />
            </div>
            <div className="form-inputs">
              <h5>Telefone de Contacto</h5>
              <Input
                name="contactPhone"
                type="text"
                onChange={({ target: { id, value } }) =>
                  handleClientBasic({ data: value, input: id })
                }
                id="contactPhone"
                defaultValue={clientBasic.contactPhone}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="form-inputs">
              <h5>NIF</h5>
              <Input
                name="nif"
                type="text"
                onChange={({ target: { id, value } }) =>
                  handleClientBasic({ data: value, input: id })
                }
                id="nif"
                defaultValue={clientBasic.nif}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="form-inputs">
              <h5>Email do Contato</h5>
              <Input
                name="email"
                className="input-email"
                id="contactEmail"
                type="text"
                onChange={({ target: { id, value } }) =>
                  handleClientBasic({ data: value, input: id })
                }
                defaultValue={clientBasic.contactEmail}
              />
            </div>
            <div className="form-inputs">
              <h5>Senha</h5>
              <Input
                name="password"
                className="input-password"
                id="password"
                type="text"
                onChange={({ target: { id, value } }) =>
                  handleClientBasic({ data: value, input: id })
                }
                defaultValue={clientBasic.password}
              />
            </div>
          </div>
        </div>
        <Button onClick={() => updateBasic()} className="buttonSave">
          Atualizar Dados Básicos
        </Button>
      </div>
    </>
  );
};

export default EditarClientBasic;
