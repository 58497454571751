import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Search } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;

  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    h2 {
      color: var(--primary);
    }
  }

  h2 {
    font-size: 2.4rem;
    margin: 44px 0 0 40px;
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;

    margin: 25px 0 20px 5px;
    padding: 40px 40px 300px 40px;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .searchBar {
      width: 589px;
      height: 47px;
      float: left;
      display: flex;
      margin-top: 40px;
      margin-bottom: 20px;
      align-items: right;

      h5 {
        font-size: 1.6rem;
      }

      > svg {
        position: absolute;
        float: right;
        width: 20px;
        height: 20px;
        margin: 15px 10px 0 30px;
      }

      input {
        float: right;
        padding-left: 50px;
        width: 560px;
        height: 47px;
      }
    }
    .box-list {
      display: flex;
      flex-direction: column;

      margin-top: 30px;
    }

    .menu-cadastro {
      width: 100%;
      max-width: 815px;
      border-bottom: 2px solid var(--line-table);
      display: flex;
      align-items: center;
      justify-content: left;
      text-decoration: none;
    }

    .item-cadastrados {
      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid var(--primary);
      margin-right: 47px;

      font-size: 1.8rem;
      font-weight: 400;
      color: var(--primary);
    }

    .item-novo-cadastro {
      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 400;
      border: 0;
      background: #e8ebf1;
      text-decoration: none;
      color: var(--secondary);

      &:hover {
        border-top: 2px solid var(--primary);
      }
    }

    .list-entregadores {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid var(--line-table);
      > div > div {
        margin-top: 24px;
        max-width: 500px;
        width: 100%;
      }
    }
    .bottomPedidos {
      display: flex;
      margin-top: 10px;
      padding-bottom: 15px;
      > button {
        margin-right: 40px;
        margin-top: 0;
        margin-bottom: 50px;
      }
    }
  }

  .tabs-container {
    position: relative;
    height: 1200px;
    width: 100%;
    max-width: 995px;
    left: 0;
    margin: 34px 0;
  }

  .tabs-container:after {
    content: '.';
    display: block;
    clear: both;
    height: 0;
    font-size: 0;
    line-height: 0;
    visibility: none;
  }
  input.tabs {
    display: none;
  }
  input.tabs + label {
    width: 320px;
    height: 60px;
    line-height: 40px;
    padding: 10px 10px;
    text-align: center;
    background: #f3f3f3;
    color: var(--secondary);
    cursor: pointer;
    transition: background ease-in-out 0.3s;
    font-size: 1.8rem;
  }

  input.tabs:checked + label {
    color: var(--primary);
    background: var(--background);
    border-top: 2px solid var(--primary);
  }

  input.tabs + label + div {
    margin-right: 100px;
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 150px;
    left: 0;
    height: 500px;
    padding: 10px;
    z-index: -1;
    transition: opacity ease-in-out 0.3s;
  }
  input.tabs:checked + label + div {
    & + input {
    }
    opacity: 1;
    z-index: 1000;
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const FormCadastroPequenosAlmocos = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .formulario-cadastro {
    margin-top: 10px;

    div {
      display: flex;
      align-items: left;
      justify-content: left;
      & + div {
        margin-bottom: 30px;
      }
    }

    .form-inputs {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      h5 {
        font-size: 1.6rem;
        font-weight: 400;
        margin: 20px 0 10px 10px;
      }

      input {
        border: 0;
        border-radius: 20px;
        width: 320px;
        height: 47px;
        padding: 10px 20px;
        background: var(--input);
      }
      input.input-veiculo {
        width: 200px;
      }
      input.input-contato {
        width: 250px;
      }
      input.form-info {
        border: 0;
        border-radius: 20px;
        padding: 10px 20px;
        max-width: 600px;
        width: 600px;
      }
    }
  }
`;
