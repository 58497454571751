import React from 'react';
import lunchImg from '../../../../assets/lunch.png';
import { ILunch, INewRequestDataLunchs } from '../types';

interface IProps {
  lunchList: ILunch[];
  pickedLunchAmount(id: string): number;
  increaseLunchAmount(id: string, value: number): void;
  pickedLunchs: INewRequestDataLunchs[];
  handlePickedLunch({
    checked,
    lunchId,
  }: {
    checked: boolean;
    lunchId: string;
  }): void;
}

const Normal: React.FC<IProps> = ({
  increaseLunchAmount,
  lunchList,
  handlePickedLunch,
  pickedLunchAmount,
  pickedLunchs,
}) => {
  return (
    <div className="lunch-list">
      <div className="select-lunch">
        {lunchList.length > 0 &&
          lunchList.map(item => (
            <div key={item.id} className="lunch-item">
              <div className="lunch-img-title">
                <div>
                  <img src={lunchImg} alt="" />
                </div>
                <div>
                  <h4>
                    {item.lunchType ? item.lunchType : 'Nome do Pequeno Almoço'}
                  </h4>
                </div>
              </div>
              <div className="lunch-cost">
                <span>Quantidade</span>
                <input
                  type="number"
                  placeholder=""
                  min={Number(0)}
                  onKeyDown={event => event.preventDefault()}
                  value={pickedLunchAmount(item.id)}
                  name="lunchs.customLunchCost"
                  id={item.id}
                  onChange={event =>
                    increaseLunchAmount(
                      event.target.id,
                      Number(event.target.value),
                    )
                  }
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  defaultValue={item.id}
                  id={item.id}
                  checked={pickedLunchs.some(i => i.lunchId === item.id)}
                  name="lunchs.lunchId"
                  onChange={({ target }) =>
                    handlePickedLunch({
                      checked: target.checked,
                      lunchId: target.value,
                    })
                  }
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Normal;
