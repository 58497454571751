import styled from 'styled-components';
import { shade } from 'polished';

import loginBackgroundImg from '../../assets/login-background.png';
import loginBackgroundMobile from '../../assets/login-cover-mobile.png';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  margin: auto;

  display: flex;
  align-items: stretch;

  .sideBar {
    width: 50%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80%;

    > div {
      width: 100%;

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        text-align: center;
        width: 100%;
        font-size: 2rem;
        font-weight: bold;

        color: #ffffff;
      }

      h2 {
        margin-top: 10px;
        text-align: center;
        width: 90%;
        font-size: 1.8rem;
        font-weight: 400;

        color: #ffffff;
      }
    }
  }
  @media (max-width: 1280px) {
    .sideBar {
      width: 50%;
      top: 70%;
      > div {
        > h1 {
          font-size: 1.8rem;
        }
        h2 {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (max-width: 980px) {
    .sideBar {
      width: 50%;
      top: 70%;
      > div {
        > h1 {
          font-size: 1.8rem;
        }
        h2 {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    .sideBar {
      width: 100%;
      top: 40%;
      > div {
        > h1 {
          margin-top: 25px;
          font-size: 1.4rem;
        }
        h2 {
          margin-top: 5px;
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (max-width: 530px) {
    .sideBar {
      width: 90%;
      top: 15%;

      > div {
        width: 200px;
        left: 50px;

        > h1 {
          text-align: left;
          font-size: 1.6rem;
        }

        h2 {
          display: none;
        }
      }
    }
  }

  @media (max-width: 375px) {
    .sideBar {
      width: 90%;
      top: 15%;
      > div {
        width: 200px;
        left: 40px;
        > h1 {
          text-align: left;
          font-size: 1.6rem;
        }

        h2 {
          display: none;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  margin: auto;

  h1 {
    margin-top: 50px;
    text-align: center;
    width: 100%;
    font-size: 1.8rem;
    font-weight: bold;

    color: var(--secondary);
  }

  img {
    width: 141px;
    margin-bottom: -20px;
  }

  form {
    margin: 40px;
    width: 449px;
    text-align: center;

    h5 {
      margin-left: 10px;
      margin-bottom: 5px;
      text-align: left;
      font-weight: 400;
      font-size: 1.6rem;
    }

    .checkbox {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;

      > h5 {
        font-size: 1.6rem;
        margin-left: 10px;
      }
    }

    a {
      margin-top: 5px;
      color: var(--title);
      text-decoration: none;

      &:hover {
        color: ${shade(0.2, '#C2C2C2')};
      }
    }

    .form-check-input {
      width: 19px;
      height: 19px;
      background: var(--primary);
    }

    button {
      background: var(--primary);
      border-radius: 20px;
      height: 47px;
      border: none;
      padding: 0 16px;
      width: 100%;
      margin-top: 20px;

      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 500;
      transition: background-color 0.2s;
      &:hover {
        color: ${shade(0.2, '#ffffff')};
      }
    }
    .formPass {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 23px;
    }
  }
  @media screen and (max-width: 980px) {
    img {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 1.4rem;
    }
    form {
      margin: 40px;
      width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    img {
      margin-bottom: 30px;
      width: 100px;
    }

    h1 {
      margin-top: 10px;
      margin-bottom: 40px;
      font-size: 1.4rem;
    }

    form {
      margin-top: -20px;
      width: 443px;
    }
  }
  @media screen and (max-width: 644px) {
    img {
      margin-bottom: 30px;
      width: 100px;
    }

    h1 {
      margin-top: 10px;
      margin-bottom: 40px;
      font-size: 1.4rem;
    }

    form {
      margin-top: -30px;
      width: 443px;
    }
  }

  @media screen and (max-width: 530px) {
    h1 {
      padding-top: 20px;
      font-size: 1.6rem;
    }
    img {
      width: 90px;
      margin-bottom: 10px;
    }
    form {
      margin: 60px 10px 50px 10px;
      width: 350px;
    }
  }

  @media screen and (max-width: 375px) {
    img {
      width: 100px;
      margin-bottom: -20px;
    }
    form {
      margin-top: 90px;
      width: 250px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${loginBackgroundImg}) no-repeat center;
  background-size: cover;

  @media screen and (max-width: 780px) {
    flex: 1;
    background: url(${loginBackgroundImg}) no-repeat center;
    background-size: cover;
  }

  @media screen and (max-width: 530px) {
    flex: 1;
    background: url(${loginBackgroundMobile}) no-repeat center;
    background-size: cover;
  }

  @media screen and (max-width: 375px) {
    flex: 1;
    background: url(${loginBackgroundMobile}) no-repeat center;
    background-size: cover;
  }
`;
