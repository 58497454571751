/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, subMonths, subDays, parseISO } from 'date-fns';
import Input from '../../../components/Input';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import PDFClient from './PDFPequenosAlmocos';

import Button from '../../../components/Button';
import { Main, Section, SearchIcon, SourceGraphic } from './styles';

interface ILunchs {
  foodId: string;
  lunchId: string;
  createdAt: string;
  totalCost: string;
  lunchName: string;
  totalQuantity: number;
}

interface ILunchsAll {
  data: ILunchs[];
}

const RelatorioPequenosAlmocos: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [initialClient, setInitialClient] = useState<ILunchsAll>();
  const [clients, setClients] = useState<ILunchsAll>({ data: [] });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<ILunchsAll>(
        `/reports/lunch?start=${format(
          dateFilter.start,
          'yyyy-MM-dd',
        )}&end=${format(dateFilter.end, 'yyyy-MM-dd')}`,
      );

      setInitialClient(response.data);
      setClients(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };
  useEffect(didMount, [dateFilter]);

  const csvHeaders = [
    { label: 'Data de Criação', key: 'createdAt' },
    { label: 'Pequeno Almoço', key: 'lunchName' },
    {
      label: 'Quantidade',
      key: 'totalQuantity',
    },
    {
      label: 'Valor Gerado',
      key: 'totalCost',
    },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Pequenos Almoços</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <h4>Período específico</h4>
                  <select
                    defaultValue=""
                    name="periodo-especifico"
                    id="periodo-especifico"
                    onChange={e =>
                      handleDateFilter(e.target.value, e.target.id)
                    }
                  >
                    <option id="last" value={7}>
                      Últimos 07 dias
                    </option>
                    <option id="last" value={15}>
                      Últimos 15 dias
                    </option>
                    <option id="last" value={30}>
                      Últimos 30 dias
                    </option>
                    <option id="last" value={60}>
                      Últimos 60 dias
                    </option>
                    <option id="last" value={90}>
                      Últimos 90 dias
                    </option>
                  </select>
                </div>
                <div>
                  <div className="delivery-date">
                    <div>
                      <span>Inicio</span>
                      <DatePicker
                        className="datapicker"
                        selected={dateFilter.start}
                        maxDate={dateFilter.end}
                        onChange={date => handleStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Fim</span>
                      <DatePicker
                        className="datapicker"
                        minDate={dateFilter.start}
                        selected={dateFilter.end}
                        onChange={date => handleEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <h2>Pequenos Almoços no Período</h2>
            {/* <Form ref={formRefSearch} onSubmit={() => {}}>
              <div className="searchBar">
                <SearchIcon />
                <Input
                  name="search"
                  type="text"
                  onChange={handleSearch}
                  placeholder="Procure por nome ou endereço"
                />
              </div>
            </Form> */}
            <div className="flex-container">
              <div>
                <h3>Data de Criação</h3>
              </div>
              <div>
                <h3>Pequeno Almoço</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Valor Gerado</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {clients.data.map(i => (
              <div key={i.lunchId} className="flex-container">
                <div>{format(parseISO(i.createdAt), 'dd/MM/yyyy')}</div>
                <div>{i.lunchName}</div>
                <div>{i.totalQuantity}</div>
                <div>{i.totalCost}</div>

                <div>
                  <Link to={`/relatorio-pequenos-almocos-explode/${i.lunchId}`}>
                    <Button className="button-plus">
                      <span>+</span>
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFClient
                        client={clients}
                        startDate={format(dateFilter.start, 'dd/MM/yyyy')}
                        endDate={format(dateFilter.end, 'dd/MM/yyyy')}
                      />
                    }
                    fileName="relatorio-cliente.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Exportar PDF</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clients.data}
                      headers={csvHeaders}
                      separator=";"
                      filename="relatorio-cliente.csv"
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}

            <div />
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioPequenosAlmocos;
