/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { format, isSameDay, parse, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import { Main, Section } from './styles';
import api from '../../../services/api';
import { convertDateString } from '../../../utils/convertDateString';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  id: string;
  order: string;
  quantity: string;
  description: string;
}

interface ITodayOrders {
  id: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

const DashboardClient: React.FC = () => {
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const userClient = JSON.parse(
    window.localStorage.getItem('@BiB:user') || '{}',
  );

  const today = new Date();

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/client/${userClient.id}/${formattedDate}`,
    );

    const orderArray = order.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setTodayOrders(orderByTime);
  };

  const getNextOrders = async () => {
    const order = await api.get<ITodayOrders[]>(
      `food-order/client/next/orders/${userClient.id}`,
    );

    const orderArray = order.data;

    const orderBySchedule = orderArray.sort((leftDate, rightDate) => {
      const leftTimestamp = Date.parse(leftDate.deliveryDate);
      const rightTimestamp = Date.parse(rightDate.deliveryDate);
      return leftTimestamp - rightTimestamp;
    });

    setNextOrders(orderBySchedule);
  };

  const didMount = () => {
    setLoading(true);
    getTodayOrders();
    getNextOrders();
    setLoading(false);
  };

  useEffect(didMount, []);

  const isNextDateAfterPrevious = (actualDate: string, nextDate: string) => {
    const formatActual = parse(actualDate, 'dd/MM/yyyy', new Date());
    const formatNext = parse(nextDate, 'dd/MM/yyyy', new Date());
    return isSameDay(formatActual, formatNext);
  };

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Olá, Seja bem vindo(a) de volta</h1>
            <h3>Acompanhe aqui tudo que acontece na sua conta</h3>
          </div>
          <Link to="/novo-pedido-cliente">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <h2>Entregas do dia {format(today, 'dd/MM/yyyy')}</h2>
            <div className="flex-container">
              <div>
                <h3>Data do pedido</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {!loading &&
              todayOrders?.length !== 0 &&
              todayOrders?.map((nextOrder, i) => (
                <div
                  key={nextOrder.id}
                  className="flex-container"
                  style={{
                    borderBottom:
                      todayOrders[i + 1] &&
                      nextOrders &&
                      isNextDateAfterPrevious(
                        nextOrder.deliveryDate,
                        todayOrders[i + 1].deliveryDate,
                      )
                        ? '1px solid var(--line)'
                        : '2px solid var(--secondary)',
                  }}
                >
                  <div>{convertDateString(nextOrder.createdAt)}</div>
                  <div>{nextOrder.deliveryTime.substr(0, 5)}</div>
                  <div>
                    {nextOrder.deliveryAddress[0].street}, n
                    {nextOrder.deliveryAddress[0].streetNumber}
                  </div>
                  <div>{nextOrder.apartment}</div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {nextOrder.lunchs.map((ni, index) => (
                      <div key={String(index)}>
                        {index < 3 ? `${ni.quantity} - ${ni.order}` : `...`}
                      </div>
                    ))}
                  </div>
                  <div>
                    <div className={confirmStatus(nextOrder.status)}>
                      <span>{formatStatus(nextOrder.status)}</span>
                    </div>
                  </div>
                  <div>
                    <Link
                      to={`/entregas-do-dia-detalhe-cliente/${nextOrder.id}`}
                    >
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {todayOrders && (
              <h2>Você não possui pedidos para serem entregues hoje...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
          <div className="sectionCard">
            <h2>Próximos Pedidos</h2>
            <div className="flex-container">
              <div>
                <h3>Data do pedido</h3>
              </div>
              <div>
                <h3>Data de Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {!loading &&
              nextOrders?.length !== 0 &&
              nextOrders?.map((nextOrder, i) => (
                <div
                  key={nextOrder.id}
                  className="flex-container"
                  style={{
                    borderBottom:
                      nextOrders[i + 1] &&
                      nextOrders &&
                      isNextDateAfterPrevious(
                        nextOrder.deliveryDate,
                        nextOrders[i + 1].deliveryDate,
                      )
                        ? '1px solid var(--line)'
                        : '2px solid var(--secondary)',
                  }}
                >
                  <div>{convertDateString(nextOrder.createdAt)}</div>
                  <div>{convertDateString(nextOrder.deliveryDate)}</div>
                  <div>{nextOrder.deliveryTime.substr(0, 5)}</div>
                  <div>
                    {nextOrder.deliveryAddress[0].street}, n
                    {nextOrder.deliveryAddress[0].streetNumber}
                  </div>
                  <div>{nextOrder.apartment}</div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {nextOrder.lunchs.map((ni, index) => (
                      <div key={String(index)}>
                        {index < 3 ? `${ni.quantity} - ${ni.order}` : `...`}
                      </div>
                    ))}
                  </div>
                  <div>
                    <div className={confirmStatus(nextOrder.status)}>
                      <span>{formatStatus(nextOrder.status)}</span>
                    </div>
                  </div>
                  <div>
                    <Link
                      to={`/entregas-do-dia-detalhe-cliente/${nextOrder.id}`}
                    >
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {nextOrders?.length === 0 && (
              <h2>Você não possui próximos pedidos...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <h4>Carregando...</h4>
              </div>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default DashboardClient;
