/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode, useEffect } from 'react';

import { useAuth } from '../../../hooks/auth';

import MenuAdmin from '../../../components/MenuAdmin';
import HeaderAdmin from '../../../components/HeaderAdmin';
import { Container, Content } from './styles';

interface SiteProps {
  children: ReactNode;
}

export default function Admin({ children }: SiteProps) {
  const { user, signOut } = useAuth();

  if (user.role !== 'ADMIN') {
    signOut();
  }

  if (user.role !== 'ADMIN') {
    return <div />;
  }

  window.scrollTo(0, 0);
  return (
    <>
      <Container>
        <HeaderAdmin />
        <Content>
          <MenuAdmin />
          {children}
        </Content>
      </Container>
    </>
  );
}
