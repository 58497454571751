import { shade } from 'polished';
import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  max-width: 1355px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }

    h1 {
      font-size: 3.6rem;
      font-weight: 500;
    }

    h3 {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const RedRoundButton = styled.button`
  background-color: #bf1e2d;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  cursor: pointer;
`;

export const Tooltip = styled.div`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: -60px; /* Posicionado abaixo do botão */
  left: 50%;
  transform: translateX(-50%);
  width: 150px; /* Tamanho mais extenso */
  opacity: 0;
  transition: opacity 0.3s;
`;

export const ButtonWrapperHover = styled(ButtonWrapper)`
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);
  }

  h2 {
    color: var(--primary);
    font-size: 2.4rem;
    margin: 44px 0 29px 40px;
  }

  .flex-container {
    border-bottom: 1px solid var(--line);
    display: flex;
    align-items: left;
    justify-content: space-between;
    text-align: left;
    width: 94%;
    background: var(--table-color);

    padding-right: 10px;
    margin: 5px 0 20px 40px;

    & > div {
      width: 140px;
      height: 100%;
      display: flex;
      align-items: left;
      justify-content: left;
      text-align: left;
      padding: 8px 10px;
      & div.statusFinished {
        width: 1px;
        display: hidden;
      }
    }

    .item {
      color: var(--background);
    }

    .listOrderLunch {
      display: flex;
      align-items: left;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 24px;
    }

    .lunchQtd {
      padding-left: 80px;
      margin-right: 40px;
      width: 50px;
      display: flex;
      align-items: left;
      justify-content: center;
    }

    .changeStatusSelect {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 14rem;
      padding-right: 20px;

      select {
        width: 14rem;
        height: 2.7rem;
        border-radius: 20px;
        border: 1px solid var(--line);
        background: var(--input);
        margin: -4px 0;
        padding: 0 10px;
      }
      button {
        margin: 10px 0;
        width: 7rem;
        height: 2.7rem;
      }
    }

    .formDeliveryMan {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      button {
        width: 100px;
        height: 27px;
        font-size: 1.2rem;
      }

      & div {
        float: right;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    select {
      width: 130px;
      height: 27px;
      border: 0;
      border-radius: 20px;
      padding: 0 10px;
      background: var(--input);
      font-size: 1.4rem;
      color: var(--secondary);
    }

    button.allItems {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 274px;
      height: 31px;
      background: transparent;
      border: 1px solid var(--primary);

      margin-top: 0px;
      transition: background-color 0.2s;

      & > span {
        font-size: 1.4rem;
        font-weight: 400;
        color: var(--primary);
        text-align: center;
      }

      &:hover {
        background: ${(shade(0.1), '#EB5757')};
      }
    }

    button.button-plus {
      width: 31px;
      height: 31px;
      background: var(--primary);

      margin-left: 40px;
      margin-top: 8px;
      transition: color 0.2s;

      & > span {
        margin: 1px 2px 2px -7px;
        font-size: 2.4rem;
        font-weight: 400;

        text-align: center;
      }
      &:hover {
        background: ${(shade(0.2), '#EB5757')};
      }
    }
  }

  .bottomPedidos {
    width: 100%;
    margin-bottom: 50px;
  }
`;
