import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { Search } from 'react-iconly';

export const Main = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;
const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const RedRoundButton = styled.button`
  background-color: #bf1e2d;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  cursor: pointer;
`;

export const Tooltip = styled.div`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: -60px; /* Posicionado abaixo do botão */
  left: 50%;
  transform: translateX(-50%);
  width: 150px; /* Tamanho mais extenso */
  opacity: 0;
  transition: opacity 0.3s;
`;

export const ButtonWrapperHover = styled(ButtonWrapper)`
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    h2 {
      color: var(--secondary);
      margin-left: 40px;
      margin-bottom: 40px;
    }
  }

  .searchBar {
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: right;
    margin-right: 20px;

    > svg {
      position: absolute;
      float: right;
      width: 20px;
      height: 20px;
      margin: 14px 0 0 20px;
    }
    input {
      float: right;
      padding-left: 50px;
      width: 560px;
    }
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    border-bottom: 1px solid var(--line);
    display: flex;
    align-items: start;
    justify-content: space-between;
    text-align: left;

    padding-right: 10px;
    margin: 5px 40px 20px 40px;

    & > div {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: left;
      justify-content: left;
      text-align: left;
    }

    .item {
      color: var(--background);
    }

    button.button-plus {
      width: 31px;
      height: 31px;
      background: var(--primary);

      margin-left: 40px;
      margin-top: 8px;
      transition: color 0.2s;

      & > span {
        margin: 1px 2px 2px -7px;
        font-size: 2.4rem;
        font-weight: 400;

        text-align: center;
      }
      &:hover {
        background: ${(shade(0.2), '#EB5757')};
      }
    }
  }

  .bottomPedidos {
    margin-bottom: 50px;
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;
export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const Calender = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1040px;
  height: 70px;

  margin: 20px 0 40px 40px;
  color: var(--secondary);
`;
