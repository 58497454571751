/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isAfter, format, parseISO } from 'date-fns';
import api from '../../../services/api';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Main, Section, FormCadastroCliente, EditIcon } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import { convertDateString } from '../../../utils/convertDateString';

interface IOrderParams {
  id: string;
}

interface IOrderData {
  id: string;
  deliveryTime: string;
  deliveryDate: string;
  deliverymanName: string;
  lunchs: [
    {
      lunchType: string;
      quantity: string;
    },
    {
      lunchType: string;
      quantity: string;
    },
    {
      lunchType: string;
      quantity: string;
    },
  ];
}

const EditarPedidoInicio: React.FC = () => {
  const { id }: { id: string } = useParams();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [startDate, setStartDate] = useState<any>(new Date());
  const [checkoutDate, setCheckoutDate] = useState<any>(new Date());
  const [orderData, setOrderData] = useState<IOrderData[]>();

  const readClientData = async () => {
    try {
      setLoading(true);

      const response = await api.get<IOrderData[]>(
        `/food-order/list/orders/${id}`,
      );

      const orderArray = response.data;

      const orderBySchedule = orderArray.sort((leftDate, rightDate) => {
        const leftTimestamp = Date.parse(leftDate.deliveryDate);
        const rightTimestamp = Date.parse(rightDate.deliveryDate);
        return leftTimestamp - rightTimestamp;
      });

      setOrderData(orderBySchedule);
      setLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao obter dados',
      });
    }
  };
  useEffect(() => {
    readClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function handleBack() {
    history.go(-1);
  }

  const handleCheckinDate = (date: any) => {
    if (isAfter(date, checkoutDate)) {
      setCheckoutDate(date);
    }

    setStartDate(date);
  };
  const handleCheckoutDate = (date: any) => {
    setCheckoutDate(date);
  };

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Editar Pedido</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <Form ref={formRef} onSubmit={() => {}}>
              <FormCadastroCliente>
                <div className="lunchTitle">
                  <h2>Selecione a data a entregar</h2>
                </div>

                <div className="delivery-date">
                  <div>
                    <span>1º dia de Pequeno Almoço</span>
                    <DatePicker
                      className="datapicker"
                      selected={
                        orderData && orderData.length > 0
                          ? parseISO(orderData[0].deliveryDate)
                          : startDate
                      }
                      onChange={date => handleCheckinDate(date)}
                      disabled
                      dateFormat="dd/MM/yyyy"
                      placeholderText="I have been cleared!"
                    />
                  </div>
                  <div>
                    <span>Último dia de Pequeno Almoço</span>
                    <DatePicker
                      className="datapicker"
                      selected={
                        orderData && orderData.length > 0
                          ? parseISO(
                              orderData[orderData.length - 1].deliveryDate,
                            )
                          : startDate
                      }
                      onChange={date => handleCheckoutDate(date)}
                      minDate={startDate}
                      disabled
                      dateFormat="dd/MM/yyyy"
                      placeholderText="I have been cleared!"
                    />
                  </div>
                </div>
                {orderData &&
                  orderData.length > 0 &&
                  orderData.map((i, index) => (
                    <div key={String(index)} className="cardClient">
                      <div className="listPedidos">
                        <div>
                          <div>
                            <h3>Data:</h3>
                          </div>

                          <span>{convertDateString(i.deliveryDate)}</span>
                        </div>
                        <div>
                          <div>
                            <h3>Horário de Entrega:</h3>
                          </div>

                          <span>{i.deliveryTime.substr(0, 5)}</span>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          {i.lunchs.map((lunch, ind) => (
                            <h5 key={String(ind)}>
                              {lunch.quantity} - {lunch.lunchType}
                            </h5>
                          ))}
                        </div>
                      </div>
                      <div className="cardClientSelect">
                        <Link to={`/editar-pedido-final/${i.id}`}>
                          <EditIcon />
                          <p>Editar Pedido</p>
                        </Link>
                      </div>
                    </div>
                  ))}

                <div className="bottomPedidos">
                  <Button className="allItems" onClick={handleBack}>
                    Voltar
                  </Button>
                </div>
              </FormCadastroCliente>
            </Form>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default EditarPedidoInicio;
