import styled, { css } from 'styled-components';
import { ChevronDown, PaperPlus, Category, Logout } from 'react-iconly';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  background: #ffffff;
  box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.03);

  > img {
    margin: 28px 0;
  }

  .menuContainer {
    display: flex;
    width: 100%;
    max-width: 400px;
    position: absolute;
    margin-left: 250px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 70px;

      width: 200px;
      height: 37px;
      border-radius: 20px;
      span {
        font-size: 1.8rem;
        font-weight: 500;
      }

      svg {
        width: 15px;
        height: 15px;
        font-weight: bold;
        margin-left: 10px;
      }
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 35px;
    width: 207px;
    background: var(--background);
    min-width: 180px;
    border-radius: 10px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    padding: 16px 16px;
    z-index: 1;
    border: 1px solid #c2c2c2;

    font-size: 1.2rem;
    font-weight: 500;
    color: #c2c2c2;

    span {
      div {
        margin-left: 19px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    a {
      color: #c2c2c2;
      text-decoration: none;

      &:hover {
        color: var(--primary);
        text-decoration: none;
        span {
          div {
            background: var(--primary);
          }
        }
      }
    }
  }

  .dropItem {
    display: flex;
    align-items: center;

    justify-content: space-between;
    padding: 10px 10px;
    border-bottom: 1px solid var(--line-table);
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
  //profile

  .profile {
    display: flex;
    width: 120px;

    button.logout {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px;
      width: 120px;
      height: 50px;
      background: transparent;

      h4 {
        margin-left: 10px;
        color: var(--secondary);
      }
      svg {
        color: var(--secondary);
      }
      transition: color 0.2s;
      &:hover {
        color: ${(shade(0.3), '#6d737c')};
      }
    }
  }
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
  }

  .settings {
    margin: 40px;
    margin-right: 42px;
  }

  .notifications {
    margin-top: 40px;
    margin-right: 85px;
  }

  .profile {
    margin-top: 28px;
    margin-right: 40px;

    img {
      width: 54px;
      height: 54px;
    }
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;
export const ChevronDownIcon = styled(ChevronDown)`
  ${iconCSS}
`;
export const LogoutIcon = styled(Logout)`
  ${iconCSS}
`;
export const PaperPlusIcon = styled(PaperPlus)`
  ${iconCSS}
  color: var(--primary);
  width: 34px;
  margin-right: 14px;
  font-weight: 400l;
`;

export const CategoryIcon = styled(Category)`
  ${iconCSS}
  color: var(--primary);
  width: 25px;
  margin-right: 14px;
  font-weight: 400l;
`;
