/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-sequences */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, subDays } from 'date-fns';
import { CSVLink } from 'react-csv';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import Button from '../../../components/Button';
import { Main, Section, ChevronLeftIcon, SourceGraphic } from './styles';
import PDFClientExplode from './PDFClientExplode';

interface IClientDelivery {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  doorCode: string;
}

interface IClientLunch {
  order: string;
  quantity: number;
  lunchCost: number;
  description: string;
}

interface IClient {
  id: string;
  deliveryTime: string;
  client: string;
  total: number;
  quantity: number;
  totalQuantity: number;
  deliveryDate: string;
  apartment: string;
  status: string;
  deliveryAddress: IClientDelivery[];
  lunchs: IClientLunch[];
}

interface IClientAll {
  totalCost: number;
  totalQuantity: number;
  client: string;
  data: IClient[];
}

const RelatorioClienteExplode: React.FC = () => {
  const history = useHistory();
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [initialClient, setInitialClient] = useState<IClientAll>();
  const [clients, setClients] = useState<IClientAll>({
    data: [],
    totalQuantity: 0,
    totalCost: 0,
    client: '',
  });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const { id }: { id: string } = useParams();

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IClientAll>(
        `reports/client?start=${format(
          dateFilter.start,
          'yyyy-MM-dd',
        )}&end=${format(dateFilter.end, 'yyyy-MM-dd')}&clientId=${id}`,
      );

      setInitialClient(response.data);
      setClients(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };
  useEffect(didMount, [dateFilter]);

  const clientDataList = clients.data.map(client => {
    const newDeliveryTime = client.deliveryTime.substr(0, 5);
    const address = client.deliveryAddress.map(add => {
      return {
        addresses: `${add.street} n${add.streetNumber} - ${add.neighborhood}`,
      };
    });

    let lc: any = [];
    client.lunchs.map(lunch => {
      if (lc.length > 0) {
        lc[0] = `${lc[0]} | ${lunch.quantity} - ${lunch.order}`;
      } else {
        lc[0] = `${lunch.quantity} - ${lunch.order}`;
      }
      return {
        ...lunch,
      };
    });

    return {
      ...client,
      newDeliveryTime,
      deliveryAddress: address[0].addresses,
      lunchs: lc[0],
    };
  });

  const csvHeaders = [
    { label: 'Cliente', key: 'client' },
    { label: 'Data de Entrega', key: 'deliveryDate' },
    { label: 'Horário de Entrega', key: 'newDeliveryTime' },
    { label: 'Endereco de Entrega', key: 'deliveryAddress' },
    { label: 'Quantidade', key: 'quantity' },
    { label: 'Pedido', key: 'lunchs' },
    { label: 'Valor Gerado', key: 'total' },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Cliente</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <h4>Período específico</h4>
                  <select
                    defaultValue=""
                    name="horario-entrega"
                    id="horario-entrega"
                    onChange={e =>
                      handleDateFilter(e.target.value, e.target.id)
                    }
                  >
                    <option id="last" value={7}>
                      Últimos 07 dias
                    </option>
                    <option id="last" value={15}>
                      Últimos 15 dias
                    </option>
                    <option id="last" value={30}>
                      Últimos 30 dias
                    </option>
                    <option id="last" value={60}>
                      Últimos 60 dias
                    </option>
                    <option id="last" value={90}>
                      Últimos 90 dias
                    </option>
                  </select>
                </div>
                <div>
                  <div className="delivery-date">
                    <div>
                      <span>Inicio</span>
                      <DatePicker
                        className="datapicker"
                        selected={dateFilter.start}
                        maxDate={dateFilter.end}
                        onChange={date => handleStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Fim</span>
                      <DatePicker
                        className="datapicker"
                        minDate={dateFilter.start}
                        selected={dateFilter.end}
                        onChange={date => handleEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <div className="clientName">
              <Link to="/relatorio-cliente">
                <ChevronLeftIcon />
                <h2>{clients.client}</h2>
              </Link>
            </div>

            <div className="flex-container">
              <div>
                <h3>Data da Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Endereço de Entrega</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Valor Gerado</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {clients.data.map(i => (
              <div key={i.id}>
                <div className="flex-container">
                  <div>{i.deliveryDate}</div>
                  <div>{i.deliveryTime.substr(0, 5)}</div>
                  <div>
                    {i.deliveryAddress[0].street} -{' '}
                    {i.deliveryAddress[0].streetNumber} -{' '}
                    {i.deliveryAddress[0].neighborhood}
                  </div>
                  <div>{i.quantity}</div>
                  <div className="listLunch">
                    {i.lunchs.map(z => (
                      <div key={z.order}>
                        {z.quantity} - {z.order}
                      </div>
                    ))}
                  </div>
                  <div>{i.total}</div>

                  <div>
                    <Link to={`/relatorio-cliente-explode-final/${i.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFClientExplode
                        client={clients}
                        startDate={format(dateFilter.start, 'dd/MM/yyyy')}
                        endDate={format(dateFilter.end, 'dd/MM/yyyy')}
                      />
                    }
                    fileName={`relatorio-cliente-detalhe-${clients.client}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Gerar Relatório</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clientDataList}
                      headers={csvHeaders}
                      separator=";"
                      filename={`relatorio-cliente-${clients.client}.csv`}
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
            {/* <SourceGraphic>
              <div className="leftGraphic">
                <h1>Preferências</h1>
                <PizzaGraphic />
              </div>
              <div className="rightGraphic">
                <h1>Preferências</h1>
                <AreaGraphic />
              </div>
            </SourceGraphic> */}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioClienteExplode;
