/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parseISO } from 'date-fns';
import api from '../../../services/api';
import Button from '../../../components/Button';
import { Main, Section, FormCadastroCliente } from './styles';
import { useToast } from '../../../hooks/toast';
import lunchImg from '../../../assets/lunch.png';
import { EditOrderModal } from '../../../components/EditOrderModal';

interface IUpdatePayload {
  deliveryTime: string;
  deliveryDate: string;
  deliverymanName: string;
  addressId: string;
  groupId: string;
  apartment: string;
  totalCost: number;
  client: string;
  additionalData: string;
  clientId: string;
  delivertmanList: [
    {
      id: string;
      name: string;
      documentNumber: string;
      licensePlate: string;
      vehicleDocument: string;
      referenceContactNumber: string;
      iban: string;
      additionalData: string;
    },
  ];
  allAddresses: [
    {
      id: string;
      street: string;
      streetNumber: string;
      city: string;
      neighborhood: string;
      doorCode: string;
      deliveryPlace: null;
      numberOfBedrooms: string;
      addressType: string;
      apartments: [string];
      deliveryPrice: number;
    },
  ];
  allLunchs: [
    {
      id: string;
      customCost: number;
      lunch: {
        id: string;
        lunchType: string;
        description: string;
        photoUrl: string;
        smallLunchPrice: number;
      };
    },
  ];
  selectedAddress: {
    id: string;
    street: string;
    streetNumber: string;
    city: string;
    neighborhood: string;
    doorCode: string;
    deliveryPlace: string;
    numberOfBedrooms: string;
    addressType: string;
    apartments: [string];
    deliveryPrice: number;
  };
  selectedLunchs: [
    {
      id: string;
      lunchType: string;
      photoUrl: string;
      smallLunchPrice: number;
      quantity: number;
    },
  ];
}

const EditarPedidoFinal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [additionalData, setAdditionalData] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeModal, setActiveModal] = useState<any>();
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [contentModal, setContentModal] = useState<any>();

  const history = useHistory();
  const { id }: { id: string } = useParams();

  const [clientData, setClientData] = useState<IUpdatePayload>(
    {} as IUpdatePayload,
  );
  function handleBack() {
    history.push(`/editar-pedido-inicio/${clientData.groupId}`);
  }
  const readData = async () => {
    try {
      setLoading(true);

      const response = await api.get<IUpdatePayload>(
        `food-order/list/orders/update/${id}`,
      );
      if (response.data && response.data.additionalData) {
        setAdditionalData(response.data.additionalData);
      }
      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      handleBack();
    }
  };

  const didUpdate = () => {
    readData();
  };

  useEffect(() => {
    didUpdate();
  }, [id]);

  useEffect(() => {
    modalConfirm(contentModal);
  }, [contentModal]);

  const handleSelectedAddress = async (
    addressId: string,
    apartment: string,
    deliveryPrice: number,
    oldApartent: string
  ) => {
    // executeUpdateAddress(addressId, apartment, false);
    setContentModal({
      addressId,
      apartment,
      oldApartent,
      type: 'address',
    })
  };

  async function executeUpdateAddress(
    addressId: string,
    apartment: string,
    updateAll: boolean,
    oldApartment: string
  ) {

    try {
      setLoading(true);
      const response = await api.put(
        `food-order/update/${id}/address/${addressId}/${apartment}`, {
          updateAll,
          oldApartment
        },
      );
      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na Atualização',
        description: 'Oops ocorreu um erro ao atualizar o pedido',
      });
    }
  }

  const handleDeliveryTime = async (time: string, apartment: string) => {  
    // executeUpdateTime(time, false);
    setContentModal({
      time,
      apartment,
      type: 'time',
    })
  };

  async function executeUpdateTime(time: any, updateAll: boolean, apartament: string){
      try {
        setLoading(true);

        const response = await api.put(`food-order/update/${id}/deliverytime`, {
          deliveryTime: time,
          updateAll,
          apartment: apartament
        });
        setClientData(response.data);
        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro na Atualização',
          description: 'Oops ocorreu um erro ao atualizar o pedido',
        });
      }
  }

  const handleDeliveryDate = async (date: any) => {
    if (date) {
      try {
        setLoading(true);
        const response = await api.put(
          `food-order/update/${id}/deliverydate/${format(date, 'yyyy-MM-dd')}`,
        );
        setClientData(response.data);
        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro na Atualização',
          description: 'Oops ocorreu um erro ao atualizar o pedido',
        });
      }
    }
  };

  const handleDeliveryman = async (value: string, apartament: string) => {
    // executeUpdateDeliveryMan(value, false);
    setContentModal({
      deliveryMan: value,
      type: 'deliveryMan',
      apartament
    })
  };

  async function executeUpdateDeliveryMan(deliveryMan: string, updateAll: boolean, apartament: string){
      try {
        setLoading(true);

        const response = await api.put(
          `food-order/update/${id}/deliveryman/${deliveryMan}`, {
            updateAll,
            oldApartment: apartament
          },
        );

        setClientData(response.data);
        setLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro na Atualização',
          description: 'Oops ocorreu um erro ao atualizar o pedido',
        });
      }
  }

  const handlePickedLunch = async ({
    lunchId,
    checked,
  }: {
    lunchId: string;
    checked: boolean;
  }) => {
    try {
      setLoading(true);
      const response = await api.put('food-order/update/lunch/checkbox', {
        checked,
        amount: checked ? 1 : 0,
        lunchId,
        orderId: id,
        clientId: clientData.clientId,
      });
      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (JSON.parse(error.request.response).message === 'empty') {
        history.push(`/editar-pedido-inicio/${clientData.groupId}`);
      } else {
        addToast({
          type: 'error',
          title: 'Erro na Atualização',
          description:
            JSON.parse(error.request.response).message ||
            'Falha em atualizar Pedido',
        });
      }
    }
  };

  const cancelOrder = async () => {
    setLoading(true);
    const response = await api.put(`food-order/update/status/${id}`, {
      status: 'CANCELADA',
    });

    setLoading(false);
    handleBack();
  };

  const increaseLunchAmount = async ({
    lunchId,
    amount,
  }: {
    lunchId: string;
    amount: number;
  }) => {
    // try {
    //   setLoading(true);
    //   const response = await api.put('food-order/update/lunch', {
    //     lunchId,
    //     orderId: id,
    //     amount,
    //     clientId: clientData.clientId
    //   });
    //   setClientData(response.data);
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    //   if (JSON.parse(error.request.response).message === 'empty') {
    //     history.push(`/editar-pedido-inicio/${clientData.groupId}`);
    //   } else {
    //     addToast({
    //       type: 'error',
    //       title: 'Erro na Atualização',
    //       description:
    //         JSON.parse(error.request.response).message ||
    //         'Falha em atualizar Pedido',
    //     });
    //   }
    // }

    setContentModal({
      lunchId,
      type: 'lauch',
      amount
    })
  };

  const handleAdditionalData = (value: string) => {
    setAdditionalData(value);
  };

  const updateAdditionalData = async (apartament: string) => {
    // executeUpdateAdditionalData(additionalData, false);
    setContentModal({
      additionalData,
      type: 'additionalData',
      apartament
    })
  };

  async function executeUpdateAdditionalData(value: string, updateAll: boolean, apartment: string){
    setLoading(true);
    const response = await api.put(`food-order/update/additionalData/${id}`, {
      additionalData,
      updateAll,
      apartment
    });
    setClientData(response.data);
    setLoading(false);
  }


  async function executeUpdateLauchQuantity(lunchId: string, updateAll: boolean, amount: number){
    try {
      setLoading(true);
      const response = await api.put('food-order/update/lunch', {
        lunchId,
        orderId: id,
        amount,
        clientId: clientData.clientId,
        updateAll
      });
      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (JSON.parse(error.request.response).message === 'empty') {
        history.push(`/editar-pedido-inicio/${clientData.groupId}`);
      } else {
        addToast({
          type: 'error',
          title: 'Erro na Atualização',
          description:
            JSON.parse(error.request.response).message ||
            'Falha em atualizar Pedido',
        });
      }
    }
}

  function handleCloseModal() {
    setIsOrderModalOpen(false);
    setIsOpen(false);
  }

  function modalConfirm(content:any) {
    setActiveModal(content);
    setIsOpen(true);
  }

  function updateAllFunction(updateAll: boolean) {

    if (contentModal && contentModal.type === "address")  {
      executeUpdateAddress(contentModal.addressId, contentModal.apartment, updateAll, contentModal.oldApartent);
    }

    if (contentModal && contentModal.type === "time")  {
      executeUpdateTime(contentModal.time, updateAll, contentModal.apartment);
    }

    if (contentModal && contentModal.type === "deliveryMan")  {
      executeUpdateDeliveryMan(contentModal.deliveryMan, updateAll, contentModal.apartament);
    }

    if (contentModal && contentModal.type === "additionalData")  {
      executeUpdateAdditionalData(contentModal.additionalData, updateAll, contentModal.apartament);
    }

    if (contentModal && contentModal.type === "lauch")  {
      executeUpdateLauchQuantity(contentModal.lunchId, updateAll, contentModal.amount);
    }
  }

  return ( 
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Editar Pedido</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="cancelButton">
              <Button onClick={() => cancelOrder()}>Cancelar Pedido</Button>
            </div>
            {loading && <h2>Carregando...</h2>}
            {!loading && clientData.client && (
              <Form ref={formRef} onSubmit={() => {}}>
                <FormCadastroCliente>
                  <div className="destino">
                    <div>
                      <h2>Destino</h2>
                    </div>
                  </div>
                  <div className="client">
                    <div>
                      <h4>{clientData.client}</h4>
                    </div>
                    <div className="cardClient">
                      <div>
                        {clientData.allAddresses.length > 0 &&
                          clientData.allAddresses.map(address => (
                            <div key={address.id}>
                              <div className="addressList">
                                <h5>{address.addressType}</h5>
                                <h5>{address.street} -</h5>
                                <h5>{address.streetNumber}- </h5>
                                <h5>{address.neighborhood}- </h5>
                                <h5>{address.doorCode}- </h5>
                                <h5>{address.city}</h5>
                              </div>
                              <div>
                                <div className="apartamentsList">
                                  <h5>Apartamento:</h5>
                                  <div className="apartaments">
                                    {address.apartments &&
                                    address.apartments.length > 0 ? (
                                      address.apartments.map((i, index) => (
                                        <div key={String(index)}>
                                          <input
                                            type="radio"
                                            id={address.id}
                                            name="apartment"
                                            checked={
                                              address.id ===
                                                clientData.addressId &&
                                              i === clientData.apartment
                                            }
                                            value={i}
                                            onChange={event =>
                                              handleSelectedAddress(
                                                event.target.id,
                                                event.target.value,
                                                address.deliveryPrice,
                                                clientData.apartment
                                              )
                                            }
                                          />
                                          <label>{i}</label>
                                        </div>
                                      ))
                                    ) : (
                                      <div>
                                        <p>Sem Apartamentos Cadastrados</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        {!clientData.allAddresses && (
                          <h3>Cliente não possui endereço cadastrado...</h3>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="delivery-date" style={{ display: 'flex' }}>
                    <div>
                      <span>Data de Entrega</span>
                      <h4>
                        {format(
                          parseISO(clientData.deliveryDate),
                          'dd/MM/yyyy',
                        )}
                      </h4>
                    </div>
                    <div>
                      <span>Editar Dia de Entrega</span>
                      <DatePicker
                        className="datapicker"
                        minDate={new Date()}
                        selected={parseISO(clientData.deliveryDate)}
                        onChange={date => handleDeliveryDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                  <div className="delivery-time">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Horário de Entrega</span>
                        <h4>{clientData.deliveryTime.substr(0, 5)}</h4>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Editar Horário de Entrega</span>
                        <select
                          name="horario-entrega"
                          id="horario-entrega"
                          defaultValue={clientData.deliveryTime}
                          onChange={event =>
                            handleDeliveryTime(event.target.value, clientData.apartment)
                          }
                        >
                          <option>Editar Horário</option>
                          <option value="05:00">05:00</option>
                          <option value="05:30">05:30</option>
                          <option value="06:00">06:00</option>
                          <option value="06:30">06:30</option>
                          <option value="07:00">07:00</option>
                          <option value="07:15">07:15</option>
                          <option value="07:30">07:30</option>
                          <option value="07:45">07:45</option>
                          <option value="08:00">08:00</option>
                          <option value="08:15">08:15</option>
                          <option value="08:30">08:30</option>
                          <option value="08:45">08:45</option>
                          <option value="09:00">09:00</option>
                          <option value="09:30">09:30</option>
                          <option value="10:00">10:00</option>
                          <option value="10:30">10:30</option>
                          <option value="11:00">11:00</option>
                          <option value="11:30">11:30</option>
                          <option value="12:00">12:00</option>
                          <option value="15:00">15:00</option>
                          <option value="16:00">16:00</option>
                          <option value="17:00">17:00</option>
                        </select>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Entregador</span>
                        <h4 style={{ marginRight: '20px' }}>
                          {clientData.deliverymanName
                            ? clientData.deliverymanName
                            : 'Nenhum selecionado'}
                        </h4>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          {clientData.deliverymanName
                            ? 'Editar Entregador: '
                            : 'Selecionar Entregador'}
                        </span>
                        <select
                          defaultValue=""
                          name="deliverymanname"
                          id="horario-entrega"
                          onChange={event =>
                            handleDeliveryman(event.target.value, clientData.apartment)
                          }
                        >
                          <option value="">
                            {clientData.deliverymanName
                              ? 'Editar Entregador'
                              : 'Selecionar Entregador'}
                          </option>
                          {clientData.delivertmanList &&
                            clientData.delivertmanList?.map(driver => (
                              <option key={driver.id} value={driver.id}>
                                {driver.name} - {driver.licensePlate}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <span>Observações</span>
                    <textarea
                      name="additionalData"
                      id="additionalData"
                      value={additionalData}
                      onChange={({ target: { value } }) =>
                        handleAdditionalData(value)
                      }
                    />
                    <Button
                      onClick={() => updateAdditionalData(clientData.apartment)}
                      disabled={clientData.additionalData === additionalData}
                    >
                      Atualizar Observações
                    </Button>
                  </div>

                  <div className="lunchs">
                    <h2>Selecione seu Pedido</h2>

                    <div className="lunch-list">
                      <div className="select-lunch">
                        {clientData.allLunchs.length > 0 &&
                          clientData.allLunchs.map(item => (
                            <div key={item.id} className="lunch-item">
                              <div className="lunch-img-title">
                                <div>
                                  <img src={lunchImg} alt="" />
                                </div>
                                <div>
                                  <h4>
                                    {item.lunch.lunchType
                                      ? item.lunch.lunchType
                                      : 'Nome do Pequeno Almoço'}
                                  </h4>
                                </div>
                              </div>
                              <div className="lunch-cost">
                                <span>Quantidade</span>
                                <input
                                  type="number"
                                  placeholder=""
                                  defaultValue={
                                    clientData.selectedLunchs.find(
                                      i => i.id === item.lunch.id,
                                    )?.quantity
                                  }
                                  min={Number(0)}
                                  onKeyDown={event => event.preventDefault()}
                                  name="lunchs.customLunchCost"
                                  id={item.lunch.id}
                                  disabled={
                                    !clientData.selectedLunchs.some(
                                      i => i.id === item.lunch.id,
                                    )
                                  }
                                  onChange={event =>
                                    increaseLunchAmount({
                                      lunchId: event.target.id,
                                      amount: Number(event.target.value),
                                    })
                                  }
                                />
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  defaultValue={item.lunch.id}
                                  id={item.lunch.id}
                                  name="lunchs.lunchId"
                                  checked={clientData.selectedLunchs.some(
                                    i => i.id === item.lunch.id,
                                  )}
                                  onChange={({ target }) =>
                                    handlePickedLunch({
                                      checked: target.checked,
                                      lunchId: target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    <h4 style={{ marginTop: 15 }}>
                      Total: € {clientData.totalCost}
                    </h4>
                  </div>
                  <div className="bottomPedidos">
                    <Button className="allItems" onClick={() => history.go(-1)}>
                      Voltar
                    </Button>
                  </div>
                </FormCadastroCliente>
              </Form>
            )}
          </div>
        </Section>

        {activeModal && (
          <EditOrderModal
            content={contentModal}
            isOpen={open}
            onRequestClose={handleCloseModal}
            verifyIfCancelAll={updateAllFunction}
          />
        )}
      </Main>
    </>
  );
};

export default EditarPedidoFinal;
