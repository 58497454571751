/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import api from '../../../services/api';
import { Main, Section, SearchIcon } from './styles';

interface IClientAddress {
  address: [
    id: string,
    city: string,
    doorCode: string,
    neighborhood: string,
    numberOfBedrooms: number,
    street: string,
    streetNumber: string,
    addressType: string,
    deliveryPrice: number,
    apartments: string[],
  ];
}

const NovoPedidoClient: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<any>();
  const userClient = JSON.parse(
    window.localStorage.getItem('@BiB:user') || '{}',
  );

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IClientAddress>(
        `/client/read/${userClient.id}`,
      );
      setClients(response.data);
      setDataSearch(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  useEffect(didMount, []);

  const debouncedData = useCallback(
    debounce(() => {
      if (search !== '') {
        if (clients && clients.address && clients.address.length > 0){
          const mySearch = clients.address.filter((c: any) => 
            c.street.toLowerCase().includes(search.toLocaleLowerCase())
          )

          if (mySearch.length === 0){
            setDataSearch(null)
          } else {
            clients.address = mySearch;
            setDataSearch(clients);
          }          
        }

      } else {
        setDataSearch(clients);
      }
    }, 500),
    [clients, search],
  );

  useEffect(() => {
    debouncedData();
  }, [search, didMount]);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Novo Pedido</h1>
          </div>
          <Link to="/novo-pedido-cliente">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="flex-container">
              <div>
                <h2>Selecione o Destino</h2>
              </div>
              <div />
              <Form ref={formRefSearch} onSubmit={() => {}}>
                <div className="searchBar">
                  <SearchIcon />
                  <Input
                    name="search"
                    type="text"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Procure por nome da rua"
                  />
                </div>
              </Form>
              <div>
                <div className="list-clientes">
                  <div>
                    {!loading &&
                      dataSearch?.address.map((add: any) => (
                        <div key={add.id}>
                          <div className="client-item">
                            <div>
                              <span>
                                <strong>
                                  {add.street} -{add.neighborhood} - Apartamento{' '}
                                  {add.doorCode}
                                </strong>
                              </span>
                            </div>
                            <div className="bottomPedidos">
                              <Link
                                to={`/cadastro-novo-pedido-cliente/${userClient.id}/${add.id}`}
                              >
                                <Button className="allItems">Selecionar</Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    {clients?.length === 0 && (
                      <h4>Você não possui endereços cadastrados...</h4>
                    )}
                    {loading && (
                      <div className="loadingData">
                        <span>Carregando...</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default NovoPedidoClient;
