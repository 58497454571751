import styled, { css } from 'styled-components';
import { InfoCircle } from 'react-iconly';

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const RedRoundButton = styled.button`
  background-color: #bf1e2d;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  cursor: pointer;
`;

export const Tooltip = styled.div`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: -60px; /* Posicionado abaixo do botão */
  left: 50%;
  transform: translateX(-50%);
  width: 150px; /* Tamanho mais extenso */
  opacity: 0;
  transition: opacity 0.3s;
`;

export const ButtonWrapperHover = styled(ButtonWrapper)`
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

export const Main = styled.div`
  width: 100%;
  max-width: 1355px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  //@media screen and (max-width: 1201px) {
  //  width: 96%;
  //  max-width: 1068px;
  //}

  @media (min-width: 1024px) and (max-width: 1200px) {
    width: 80%;
    max-width: 1068px;
    font-size: 88%;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    max-width: 850px;
    font-size: 84%;
  }

  @media screen and (max-width: 768px) {
    max-width: 591px;
    font-size: 80%;
  }

  @media screen and (max-width: 414px) {
    max-width: 351px;
    font-size: 78%;
  }

  // iPad Portrait
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 1) {
  }

  // iPad Landscape
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 1) {
  }
  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);
  }

  h2 {
    color: var(--primary);
    font-size: 2.4rem;
    margin: 44px 0 29px 40px;
  }

  .flex-container {
    border-bottom: 1px solid var(--line);
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    width: 94%;
    background: var(--table-color);

    padding: 4px;
    margin: 5px 0 20px 40px;

    & > div {
      width: 140px;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: left;
      text-align: left;
      padding: 8px 4px 0 0;
    }
    a {
      width: 130px;
      height: 100%;
      text-decoration: none;
      color: var(--secondary);
    }

    .nextOrderAddress {
      width: 100%;
      max-width: 230px;
      padding-left: 30px;
    }

    .orderDataPicker {
      margin: -10px -32px 0 -70px;
      padding: 0 -40px;
      width: 530px;
      max-width: 530px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: var(--table-color);
      }
    }

    .item {
      color: var(--background);
    }

    .changeStatusSelect {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 14rem;
      padding-right: 20px;

      select {
        width: 14rem;
        height: 2.7rem;
        border-radius: 20px;
        border: 1px solid var(--line);
        background: var(--input);
        margin: -4px 0;
        padding: 0 10px;
      }
      button {
        margin: 10px 0;
        width: 7rem;
        height: 2.7rem;
      }
    }
    .lastDiv {
      width: 3rem;
    }
  }
  .flex-container-production {
    border-radius: 6px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    width: 94%;

    padding: 4px 10px 0;
    margin: 4px 0 12px 40px;

    & > div {
      width: 140px;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: left;
      text-align: left;
      padding: 8px 4px 0 0;
    }
    a {
      width: 130px;
      height: 100%;
      text-decoration: none;
      color: var(--secondary);
    }

    .nextOrderAddress {
      width: 100%;
      max-width: 230px;
      padding-left: 30px;
    }

    .orderDataPicker {
      margin: -10px -32px 0 -70px;
      padding: 0 -40px;
      width: 530px;
      max-width: 530px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: var(--table-color);
      }
    }

    .item {
      color: var(--background);
    }

    .changeStatusSelect {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 14rem;
      padding-right: 20px;

      select {
        width: 14rem;
        height: 2.7rem;
        border-radius: 20px;
        border: 1px solid var(--line);
        background: var(--input);
        margin: -4px 0;
        padding: 0 10px;
      }
      button {
        margin: 10px 0;
        width: 7rem;
        height: 2.7rem;
      }
    }
    .lastDiv {
      width: 3rem;
    }
  }
`;

export const Calender = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 590px;
  //max-width: 590px;

  margin: -25px -30px 20px -60px;
  color: var(--secondary);

  .dataPickerCalendar {
    color: #eb5757;
  }
`;

const iconCSS = css`
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  margin: 8px 2px;
  color: var(--blue);
  font-weight: bold;
`;

export const InfoIcon = styled(InfoCircle)`
  ${iconCSS}
`;
