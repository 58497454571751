/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { debounce } from 'lodash';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section, SearchIcon } from './styles';
import Input from '../../../components/Input/index';

interface DeliverymanFormData {
  id: string;
  name: string;
  documentNumber: string;
  licensePlate: string;
  vehicleDocument: string;
  contactNumber: string;
  referenceContactNumber: string;
  additionalData: string;
}

const Entregadores: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [initialDeliveryman, setInitialDeliveryman] = useState<any>([]);
  const [deliverymans, setDeliverymans] = useState<any>();

  const fetchDeliveryman = async () => {
    try {
      const response = await api.get<DeliverymanFormData>('/deliveryman/all');
      setInitialDeliveryman(response.data);
      setDeliverymans(response.data);
      setDataSearch(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchDeliveryman();
  };
  useEffect(didMount, []);

  const debouncedData = useCallback(
    debounce(() => {
      if (search !== '') {
        const mySearch = deliverymans.filter((c: { name: string }) =>
          c.name.toLowerCase().includes(search.toLocaleLowerCase()),
        );
        if (search.length) {
          setDataSearch(mySearch);
        }
      } else {
        setDataSearch(deliverymans);
      }
    }, 500),
    [deliverymans, search],
  );

  useEffect(() => {
    debouncedData();
  }, [search, fetchDeliveryman]);

  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Acompanhe o Cadastro de seus entregadores</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>

      <Section>
        <div className="sectionCard">
          <h2>Cadastro de Entregadores</h2>
          <div className="flex-container">
            <div />
            <div className="menu-cadastro">
              <div className="item-cadastrados">Entregadores Cadastrados</div>
              <Link to="/cadastro-entregadores">
                <div className="item-novo-cadastro">Novo Entregador</div>
              </Link>
            </div>
            <Form ref={formRefSearch} onSubmit={() => {}}>
              <div className="searchBar">
                <SearchIcon />
                <Input
                  name="search"
                  type="text"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Procure por nome ou endereço"
                />
              </div>
            </Form>
            <div className="box-list">
              <div>
                {!loading &&
                  dataSearch?.map((item: any) => {
                    return (
                      <div key={item.id} className="list-entregadores">
                        <div>
                          <div>
                            <h3>{item.name}</h3>
                            <span>Placa {item.licensePlate}</span>
                          </div>
                        </div>
                        <div>
                          <div className="bottomPedidos">
                            <Link to={`/editar-entregador/${item.id}`}>
                              <Button className="allItems">Editar</Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {deliverymans?.length === 0 && (
                  <h4>Você não possui entregadores cadastrados...</h4>
                )}
                {loading && (
                  <div className="loadingData">
                    <span>Carregando...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default Entregadores;
