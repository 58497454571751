import styled from 'styled-components';

export const Container = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.04);

  height: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
`;
