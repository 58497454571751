/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-horizontal-datepicker';
import { format, parseISO } from 'date-fns';
import { debounce } from 'lodash';

import { FormHandles } from '@unform/core';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

import { Main, Section, SearchIcon, Calender } from './styles';
import api from '../../../services/api';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  quantity: string;
  description: string;
}

interface ITodayOrders {
  id: string;
  createdAt: string;
  deliveryDate: string;
  deliveryTime: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

const ProximosPedidosClient: React.FC = () => {
  const [date, setDate] = useState<Date>(new Date());
  const formRefSearch = useRef<FormHandles>(null);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [allOrders, setAllOrders] = useState<ITodayOrders[]>();
  const userClient = JSON.parse(
    window.localStorage.getItem('@BiB:user') || '{}',
  );

  const changeDate = (day: Date) => {
    setDate(day);
  };

  const getDayOrders = async () => {
    const orders = await api.get<ITodayOrders[]>(
      `food-order/client/${userClient.id}/${format(date, 'yyyy-MM-dd')}`,
    );
    setAllOrders(orders.data);
    setDataSearch(orders.data);
  };

  const didMount = () => {
    setLoading(true);
    getDayOrders();
    setLoading(false);
  };

  useEffect(didMount, [date]);

  const getQuantity = (lunchs: ITodayOrdersLunch[]) => {
    let amount = 0;

    lunchs.forEach(lunch => (amount += Number(lunch.quantity)));

    return amount;
  };

  const debouncedData = useCallback(
    debounce(() => {
      let allOrders2: any[] = []

      if (search !== '') {
        allOrders?.forEach((order:any) => {
          if (order.deliveryAddress.length > 0) {
            const result = order?.deliveryAddress?.filter((add: any) => (
              add.street.toLowerCase().includes(search.toLocaleLowerCase())
            ))

            if (result.length > 0) {
              allOrders2.push(order)
            } else {
              allOrders2 = []
            }
            setDataSearch(allOrders2);
          }        
        })
      } else {
        setDataSearch(allOrders);
      }
    }, 500),
    [allOrders, search],
  );

  useEffect(() => {
    debouncedData();
  }, [search, didMount]);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Próximos Pedidos</h1>
            <h3>Selecione o Período e acompanhe seus próximos pedidos</h3>
          </div>
          <Link to="/novo-pedido-cliente">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <Form ref={formRefSearch} onSubmit={() => {}}>
              <div className="searchBar">
                <SearchIcon />
                <Input
                  name="search"
                  type="text"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Procure pelo endereço"
                />
              </div>
            </Form>
            <Calender>
              <DatePicker
                getSelectedDay={(e: Date) => changeDate(e)}
                labelFormat="MMMM"
                color="#BF1E2D"
                endDate={90}
              />
            </Calender>
            <div className="flex-container">
              <div>
                <h3>Data do pedido</h3>
              </div>
              <div>
                <h3>Data de Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {!loading &&
              allOrders?.length !== 0 &&
              allOrders !== undefined &&
              dataSearch?.map((order: any) => (
                <div key={order.id} className="flex-container">
                  <div>{format(parseISO(order.createdAt), 'dd/MM/yyyy')}</div>
                  <div>{order.deliveryDate}</div>
                  <div>{order.deliveryTime.substr(0, 5)}</div>
                  <div>{order.deliveryAddress[0].street}</div>
                  <div>{order.apartment}</div>
                  <div>{getQuantity(order.lunchs)}</div>
                  <div>
                    <Link to={`/entregas-do-dia-detalhe-cliente/${order.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {allOrders?.length === 0 && (
              <h2>
                Você não possui Próximos Pedidos cadastrados nessa data...
              </h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default ProximosPedidosClient;
