import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { FormHandles } from '@unform/core';
import { format, parse, subDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import PDFNegocio from './PDFNegocio';

import Button from '../../../components/Button';
import { Main, Section, SearchIcon, SourceGraphic } from './styles';

interface IBusiness {
  id: string;
  deliveryDate: string;
  total: number;
  totalDeliverys: number;
  totalOrders: number;
}

interface IAllBusiness {
  data: IBusiness[];
}

const RelatorioNegocio: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<IAllBusiness>({ data: [] });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IAllBusiness>(
        `/reports/business?start=${format(
          dateFilter.start,
          'yyyy-MM-dd',
        )}&end=${format(dateFilter.end, 'yyyy-MM-dd')}`,
      );
      setClients(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };

  useEffect(didMount, [dateFilter]);

  const csvHeaders = [
    { label: 'Data', key: 'deliveryDate' },
    { label: 'Quantidade de Pedidos', key: 'totalOrders' },
    {
      label: 'Quantidade de Pequenos Almoços',
      key: 'totalDeliverys',
    },
    {
      label: 'Valor total Gerado',
      key: 'total',
    },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Negócios</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <h4>Período específico</h4>
                  <select
                    defaultValue=""
                    name="periodo-especifico"
                    id="periodo-especifico"
                    onChange={e =>
                      handleDateFilter(e.target.value, e.target.id)
                    }
                  >
                    <option id="last" value={7}>
                      Últimos 07 dias
                    </option>
                    <option id="last" value={15}>
                      Últimos 15 dias
                    </option>
                    <option id="last" value={30}>
                      Últimos 30 dias
                    </option>
                    <option id="last" value={60}>
                      Últimos 60 dias
                    </option>
                    <option id="last" value={90}>
                      Últimos 90 dias
                    </option>
                  </select>
                </div>
                <div>
                  <div className="delivery-date">
                    <div>
                      <span>Inicio</span>
                      <DatePicker
                        className="datapicker"
                        selected={dateFilter.start}
                        maxDate={dateFilter.end}
                        onChange={date => handleStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Fim</span>
                      <DatePicker
                        className="datapicker"
                        minDate={dateFilter.start}
                        selected={dateFilter.end}
                        onChange={date => handleEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <h2>Pedidos no Período</h2>
            <div className="flex-container">
              <div>
                <h3>Data</h3>
              </div>
              <div>
                <h3>Quantidade de Pedidos</h3>
              </div>
              <div>
                <h3>Quantidade de Pequenos Almoços</h3>
              </div>
              <div>
                <h3>Valor total Gerado</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {clients?.data.map((i, index) => (
              <div key={String(index)} className="flex-container">
                <div>{i.deliveryDate}</div>
                <div>{i.totalOrders}</div>
                <div>{i.totalDeliverys}</div>
                <div>{i.total}</div>
                <div>
                  <Link
                    to={`/relatorio-negocio-explode/${format(
                      parse(i.deliveryDate, 'dd/MM/yyyy', new Date()),
                      'yyyy-MM-dd',
                    )}`}
                  >
                    <Button className="button-plus">
                      <span>+</span>
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFNegocio
                        client={clients}
                        startDate={format(dateFilter.start, 'dd/MM/yyyy')}
                        endDate={format(dateFilter.end, 'dd/MM/yyyy')}
                      />
                    }
                    fileName="relatorio-cliente.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Exportar PDF</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clients.data}
                      headers={csvHeaders}
                      separator=";"
                      filename="relatorio-cliente.csv"
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioNegocio;
