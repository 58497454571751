/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format, subDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import parseISO from 'date-fns/esm/fp/parseISO/index.js';
import { CSVLink } from 'react-csv';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Input from '../../../components/Input';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section, SearchIcon, SourceGraphic } from './styles';
import PDFHistorico from './PDFHistorico';
import { convertDateString } from '../../../utils/convertDateString';

interface IHistoricProduction {
  id: string;
  clientId: string;
  client: string;
  groupId: string;
  deliveryDate: string;
  deliveryTime: string;
  createdAt: string;
  address: string;
  totalCost: string;
  totalQuantity: number;
}

interface IHistoricHistoricAll {
  data: IHistoricProduction[];
}

const HistoricoProduction: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [initialHistory, setInitialHistory] = useState<IHistoricHistoricAll>({
    data: [],
  });
  const [historic, setHistoric] = useState<IHistoricHistoricAll>({ data: [] });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IHistoricHistoricAll>(
        `/reports/historic/admin?start=${format(
          dateFilter.start,
          'yyyy-MM-dd',
        )}&end=${format(dateFilter.end, 'yyyy-MM-dd')}`,
      );

      setHistoric(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };
  useEffect(didMount, [dateFilter]);

  const newHistoricData = historic.data.map(i => {
    const newCreatedAt = format(parseISO(i.createdAt), 'dd/MM/yyyy');
    const newDeliveryDate = format(parseISO(i.deliveryDate), 'dd/MM/yyyy');
    const newDeliveryTime = i.deliveryTime.substr(0, 5);
    return {
      ...i,
      newDeliveryTime,
      newDeliveryDate,
      newCreatedAt,
    };
  });

  const csvHeaders = [
    { label: 'Data do Pedido', key: 'newCreatedAt' },
    { label: 'Data de Entrega', key: 'newDeliveryDate' },
    {
      label: 'Horário de Entrega',
      key: 'newDeliveryTime',
    },
    {
      label: 'Cliente',
      key: 'client',
    },
    {
      label: 'Quantidade',
      key: 'totalQuantity',
    },
    {
      label: 'Endereço',
      key: 'address',
    },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Histórico de Pedidos</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <h4>Período específico</h4>
                  <select
                    defaultValue=""
                    name="periodo-especifico"
                    id="periodo-especifico"
                    onChange={e =>
                      handleDateFilter(e.target.value, e.target.id)
                    }
                  >
                    <option id="last" value={7}>
                      Últimos 07 dias
                    </option>
                    <option id="last" value={15}>
                      Últimos 15 dias
                    </option>
                    <option id="last" value={30}>
                      Últimos 30 dias
                    </option>
                    <option id="last" value={60}>
                      Últimos 60 dias
                    </option>
                    <option id="last" value={90}>
                      Últimos 90 dias
                    </option>
                  </select>
                </div>
                <div>
                  <div className="delivery-date">
                    <div>
                      <span>Inicio</span>
                      <DatePicker
                        className="datapicker"
                        selected={dateFilter.start}
                        maxDate={dateFilter.end}
                        onChange={date => handleStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Fim</span>
                      <DatePicker
                        className="datapicker"
                        minDate={dateFilter.start}
                        selected={dateFilter.end}
                        onChange={date => handleEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <h2>Últimos Pedidos</h2>
            <div className="flex-container">
              <div>
                <h3>Data do Pedido</h3>
              </div>
              <div>
                <h3>Data de Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {historic &&
              historic.data.map(i => (
                <div className="flex-container" key={i.id}>
                  <div>{format(parseISO(i.createdAt), 'dd/MM/yyyy')}</div>
                  <div>{convertDateString(i.deliveryDate)}</div>
                  <div>{i.deliveryTime.substr(0, 5)}</div>
                  <div>{i.client}</div>
                  <div>{i.totalQuantity}</div>
                  <div>{i.address}</div>

                  <div>
                    <Link to={`/historico-final-producao/${i.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}

            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFHistorico
                        history={historic}
                        start={format(dateFilter.start, 'dd/MM/yyyy')}
                        end={format(dateFilter.end, 'dd/MM/yyyy')}
                      />
                    }
                    fileName="historico-producao.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Exportar PDF</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={newHistoricData}
                      headers={csvHeaders}
                      separator=";"
                      filename="historico-producao.csv"
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default HistoricoProduction;
