/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-sequences */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, subDays } from 'date-fns';
import { CSVLink } from 'react-csv';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import Button from '../../../components/Button';
import { Main, Section, ChevronLeftIcon, SourceGraphic } from './styles';
import PDFClientExplode from './PDFPequenosAlmocosExplode';

interface ILunchsClient {
  foodId: string;
  clientId: string;
  clientName: string;
  totalCost: string;
  totalQuantity: number;
}

interface ILunchsClientALL {
  lunchName: string;
  data: ILunchsClient[];
}

const RelatorioPequenosAlmocosExplode: React.FC = () => {
  const history = useHistory();
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [initialClient, setInitialClient] = useState<ILunchsClientALL>();
  const [clients, setClients] = useState<ILunchsClientALL>({
    data: [],
    lunchName: '',
  });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const { id }: { id: string } = useParams();

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<ILunchsClientALL>(
        `reports/lunch/list?start=${format(
          dateFilter.start,
          'yyyy-MM-dd',
        )}&end=${format(dateFilter.end, 'yyyy-MM-dd')}&lunchId=${id}`,
      );

      setInitialClient(response.data);
      setClients(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };
  useEffect(didMount, [dateFilter]);

  const csvHeaders = [
    { label: 'Cliente', key: 'clientName' },
    { label: 'Quantidade', key: 'totalQuantity' },
    { label: 'Valor Gerado', key: 'totalCost' },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Pequenos Almoços</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <h4>Período específico</h4>
                  <select
                    defaultValue=""
                    name="horario-entrega"
                    id="horario-entrega"
                    onChange={e =>
                      handleDateFilter(e.target.value, e.target.id)
                    }
                  >
                    <option id="last" value={7}>
                      Últimos 07 dias
                    </option>
                    <option id="last" value={15}>
                      Últimos 15 dias
                    </option>
                    <option id="last" value={30}>
                      Últimos 30 dias
                    </option>
                    <option id="last" value={60}>
                      Últimos 60 dias
                    </option>
                    <option id="last" value={90}>
                      Últimos 90 dias
                    </option>
                  </select>
                </div>
                <div>
                  <div className="delivery-date">
                    <div>
                      <span>Inicio</span>
                      <DatePicker
                        className="datapicker"
                        selected={dateFilter.start}
                        maxDate={dateFilter.end}
                        onChange={date => handleStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Fim</span>
                      <DatePicker
                        className="datapicker"
                        minDate={dateFilter.start}
                        selected={dateFilter.end}
                        onChange={date => handleEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <div className="clientName">
              <Link to="/relatorio-pequenos-almocos">
                <ChevronLeftIcon />
                <h2>{clients.lunchName}</h2>
              </Link>
            </div>

            <div className="flex-container">
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Valor Gerado</h3>
              </div>
            </div>
            {clients.data.map(i => (
              <div key={i.clientId} className="flex-container">
                <div>{i.clientName}</div>
                <div>{i.totalQuantity}</div>
                <div>{i.totalCost}</div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFClientExplode
                        client={clients}
                        startDate={format(dateFilter.start, 'dd/MM/yyyy')}
                        endDate={format(dateFilter.end, 'dd/MM/yyyy')}
                      />
                    }
                    fileName={`relatorio-cliente-detalhe-${clients.lunchName}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Gerar Relatório</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clients.data}
                      headers={csvHeaders}
                      separator=";"
                      filename={`relatorio-pequeno-almoco-${clients.lunchName}.csv`}
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioPequenosAlmocosExplode;
