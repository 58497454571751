import { format, isSameDay, parse, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import api from '../../../services/api';
import {
  ButtonWrapperHover,
  Main,
  RedRoundButton,
  Section,
  Tooltip,
} from './styles';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  additionalData?: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

const ProducaoDoDiaProducao: React.FC = () => {
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const today = new Date();

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );

    setTodayOrders(order.data);
  };

  const getNextOrders = async () => {
    const order = await api.get<ITodayOrders[]>('food-order/all/next');

    setNextOrders(order.data);
  };

  const didMount = () => {
    setLoading(true);
    getTodayOrders();
    getNextOrders();
    setLoading(false);
  };

  useEffect(didMount, []);

  const isNextDateAfterPrevious = (actualDate: string, nextDate: string) => {
    const formatActual = parse(actualDate, 'dd/MM/yyyy', new Date());
    const formatNext = parse(nextDate, 'dd/MM/yyyy', new Date());
    return isSameDay(formatActual, formatNext);
  };

  const filteredTodayOrders = todayOrders?.filter((order: any) => {
    return order.status !== 'ENTREGUE' && order.status !== 'CANCELADA';
  });

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe sua produção de hoje</h1>
            <h3>
              Você possui <span>{filteredTodayOrders?.length} pedidos</span>{' '}
              pendentes para produzir
            </h3>
          </div>
          <Link to="/novo-pedido-production">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <h2>Produção do dia {format(today, 'dd/MM/yyyy')}</h2>
            <div className="flex-container">
              <div>
                <h3>Data da Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status da Produção</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {!loading &&
              filteredTodayOrders?.length !== 0 &&
              filteredTodayOrders?.map(order => (
                <div key={order.id} className="flex-container">
                  <div>
                    {format(parseISO(order.deliveryDate) as any, 'dd/MM/yyyy')}
                  </div>
                  <div>{order.deliveryTime.substr(0, 5)}</div>
                  <div>{order.client} </div>
                  <div>{order.apartment}</div>

                  <div className="listOrderLunch">
                    {order.lunchs.map((i, index) => (
                      <div key={String(index)}>
                        {index < 3 ? `${i.quantity} - ${i.order}` : `...`}
                      </div>
                    ))}
                    <div
                      style={{ position: 'relative', display: 'inline-block' }}
                    >
                      {order.additionalData && (
                        <ButtonWrapperHover>
                          {order.additionalData && (
                            <>
                              <RedRoundButton>
                                <span>!</span>
                              </RedRoundButton>
                              <Tooltip>{order.additionalData}</Tooltip>
                            </>
                          )}
                        </ButtonWrapperHover>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={confirmStatus(order.status)}>
                      <span>{formatStatus(order.status)}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={`/producao-do-dia-detalhe-prod/${order.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {filteredTodayOrders?.length === 0 && (
              <h2>Não há pedidos nesse momento para serem produzidos...</h2>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default ProducaoDoDiaProducao;
