import styled, { css } from 'styled-components';
import { darken, shade, transparentize } from 'polished';
import { Search } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;

  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.08);

    .extraButton {
      position: absolute;
      right: 40px;
      float: right;
    }

    .flex-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      background: var(--table-color);

      padding: 0 40px 100px 40px;

      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .searchBar {
        width: 589px;
        height: 47px;
        float: left;
        display: flex;
        margin-top: 60px;
        margin-bottom: 20px;
        align-items: right;

        h5 {
          font-size: 1.6rem;
        }

        > svg {
          position: absolute;
          float: right;
          width: 20px;
          height: 20px;
          margin: 15px 10px 0 30px;
        }

        input {
          float: right;
          padding-left: 50px;
          width: 560px;
          text-transform: capitalize;
        }
      }

      .menu-cadastro {
        width: 100%;
        max-width: 815px;
        border-bottom: 2px solid var(--line-table);
        display: flex;
        align-items: center;
        justify-content: left;
        text-decoration: none;
        margin-top: 30px;
      }

      .item-cadastrados {
        width: 320px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid var(--primary);
        margin-right: 47px;

        font-size: 1.8rem;
        font-weight: 400;
        color: var(--primary);
      }

      .item-novo-cadastro {
        width: 320px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        font-weight: 400;
        border: 0;
        background: #e8ebf1;
        text-decoration: none;
        color: var(--secondary);

        &:hover {
          border-top: 2px solid var(--primary);
        }
      }

      .list-clientes {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid var(--input);

        .client-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid var(--line-table);
        }

        h4 {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 5px;
        }

        span {
          font-size: 1.6rem;
          font-weight: 400;
        }

        > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
        }

        .box-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
      }
      .bottomPedidos {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        padding-bottom: 8px;
        > button {
          margin-top: 0;
          margin-bottom: 30px;
        }
      }
    }
  }

  h2 {
    color: var(--primary);
    font-size: 2.4rem;
    margin: 44px 0 29px 40px;
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }
`;

export const TransactionTypeContainer = styled.div`
  width: 70%;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  border-bottom: 1px solid var(--line-table);
`;

interface RadioBoxProps {
  isActive: boolean;
  activeColor: 'old' | 'new';
}

const colors = {
  old: '#ffffff',
  new: '#474B4C',
};
export const RadioBox = styled.button<RadioBoxProps>`
  margin: 45px;
  width: 320px;
  height: 60px;
  border: none;
  border-top: 1px solid var(--primary);

  background: ${props =>
    props.isActive
      ? transparentize(0.9, colors[props.activeColor])
      : 'transparent'};

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.2s;

  &:hover {
    border-top: ${darken(0.1, '#E52E4D')};
  }

  img {
    width: 20px;
    height: 20px;
  }

  span {
    display: inline-block;
    margin-left: 1rem;
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--primary);
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SearchIcon = styled(Search)`
  ${iconCSS}
`;
