/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode } from 'react';
import { useAuth } from '../../../hooks/auth';

import HeaderProduction from '../../../components/HeaderProduction';
import MenuProduction from '../../../components/MenuProduction';
import { Container, Content } from './styles';

interface DashboardProps {
  children: ReactNode;
}

export default function Producao({ children }: DashboardProps) {
  const { user, signOut } = useAuth();
  if (user.role !== 'PRODUCTION') {
    signOut();
  }

  if (user.role !== 'PRODUCTION') {
    return <div />;
  }

  window.scrollTo(0, 0);
  return (
    <>
      <Container>
        <HeaderProduction />
        <Content>
          <MenuProduction />
          {children}
        </Content>
      </Container>
    </>
  );
}
