/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { format, parse, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import api from '../../../services/api';
import { Main, Section } from './styles';
import { useToast } from '../../../hooks/toast';
import { DeliveryManModal } from '../../../components/DeliveryManModal';
import { CancelOrderModal } from '../../../components/CancelOrderModal';
import lunchImg from '../../../assets/lunch.png';
import { convertDateString } from '../../../utils/convertDateString';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  client: string;
  order: string;
  lunchType: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  additionalData: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  name: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

interface IOrderParams {
  id: string;
}

const ProducaoDoDiaDetalhe: React.FC = () => {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<ITodayOrders | undefined>();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [order, setOrder] = useState<ITodayOrders>();
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<IOrderParams>();
  const [open, setIsOpen] = useState(false);

  const requestData = async () => {
    setLoading(true);
    const response = await api.get<ITodayOrders[]>(`/food-order/get/${id}`);
    setOrder(response.data[0]);
    setLoading(false);
  };

  const today = new Date();

  const parseDate = (date: string) => {
    return parse(date, 'yyyy-MM-dd', new Date());
  };

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );

    setTodayOrders(order.data);
  };

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar dados',
        description: 'Oops ocorreu um erro ao carregar dados de entregadores',
      });
    }
  };

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  const handleChangeStatus = (value: string) => {
    setOrderStatus(value);
  };

  const didUpdate = () => {
    requestData();
    getTodayOrders();
    getAllDeliveryman();
  };

  function handleBack() {
    history.go(-1);
  }

  function handleOpenModal(order: ITodayOrders) {
    setActiveModal(order);
    setIsOrderModalOpen(true);
  }

  function handleCloseModal() {
    setIsOrderModalOpen(false);
    setIsOpen(false);
  }

  function modalConfirm(order: ITodayOrders, apto:string) {
    setActiveModal(order);
    setIsOpen(true);
    console.log('Aqui abre a modal', order);
  }

  function cancelAll(deleteAll: boolean) {
    setLoading(true);

    if (!deleteAll) {
      api.put(`food-order/update/status/${id}`, {
        status: 'CANCELADA',
      });
    } else {
      api.put(`food-order/cancel/cancel-all/${id}`, {
        status: 'CANCELADA',
        apartment: order?.deliveryAddress[0].apartment
      });
    }

    addToast({
      type: 'success',
      title: 'Status Alterado!',
      description: 'Status alterado com sucesso',
    });

    setLoading(false);

    setTimeout(() => {
      didUpdate();
      history.push(`/producao-do-dia-detalhe/${id}`);
    }, 1000);
  }

  function handleChangeStatusConfirm(
    status: string,
    id: string,
    order: ITodayOrders,
    apto: string
  ) {
    try {
      if (status === 'openModal') {
        handleOpenModal(order);
        return;
      }

      if (status === 'CANCELADA') {
        modalConfirm(order, apto);
        return;
      }

      setLoading(true);
      api.put(`food-order/update/status/${id}`, {
        status,
      });

      addToast({
        type: 'success',
        title: 'Status Alterado!',
        description: 'Status alterado com sucesso',
      });
      setLoading(false);

      setTimeout(() => {
        didUpdate();
        history.push(`/producao-do-dia-detalhe/${id}`);
      }, 1000);
    } catch (err: any) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar Status',
        description:
          err.data.message || 'Oops ocorreu um erro ao realizar o cadastro',
      });
    }
  }

  function handleOnConfirmDeliveryMan(deliverymanId: string) {
    setLoading(true);
    api.put(
      `/food-order/update/${id}/deliveryman/${deliverymanId}`,
      deliverymanId,
    );

    addToast({
      type: 'success',
      title: 'Entregador adicionado!',
      description: 'Entregador adicionado ao pedido com sucesso',
    });
    setLoading(false);
    setTimeout(() => {
      didUpdate();
      history.push(`/producao-do-dia-detalhe/${id}`);
    }, 1000);
  }

  const filteredTodayOrders = todayOrders?.filter((order: any) => {
    return order.status === 'A_CONFIRMAR';
  });

  useEffect(didUpdate, [id]);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe sua produção de hoje</h1>
            {filteredTodayOrders?.length !== 0 ? (
              <h3>
                Você possui <span>{filteredTodayOrders?.length} pedidos</span>{' '}
                pendentes para produzir.
              </h3>
            ) : (
              <h4>Não há pedidos pendentes para produzir.</h4>
            )}
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>

        <Section>
          <div className="sectionCard">
            <div>
              <div className="editStatus">
                <div className="cancelButton">
                  <Link to={`/editar-pedido-final/${id}`}>
                    <Button type="button">
                      <p>Editar Pedido</p>
                    </Button>
                  </Link>
                </div>
                {!loading && order && (
                  <div className="formChangeStatus">
                    <h3>Altere o status do pedido:</h3>
                    <div>
                      <select
                        name="changeStatus"
                        id="changeStatus"
                        defaultValue=""
                        onChange={event =>
                          handleChangeStatus(event.target.value)
                        }
                      >
                        <option value={order?.status}>Selecionar</option>

                        <option value="A_CONFIRMAR">A Confirmar</option>
                        <option value="EM_PRODUCAO">Em Produção</option>
                        <option value="FINALIZADO">Finalizado</option>
                        {order?.deliverymanName === '' ? (
                          <option value="openModal">Entregue</option>
                        ) : (
                          <option value="ENTREGUE">Entregue</option>
                        )}
                        <option value="CANCELADA">Cancelada</option>
                      </select>
                      <Button
                        onClick={() =>
                          handleChangeStatusConfirm(
                            orderStatus,
                            order?.id,
                            order,
                            order.apartment
                          )
                        }
                      >
                        <span>OK</span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {!loading && order && (
                <div className="flex-container">
                  <div className="orderContainer">
                    <h2>Status da Produção</h2>
                    <div className={confirmStatus(order.status)}>
                      <span>{formatStatus(order.status)}</span>
                    </div>
                    <div className="grayLine" />
                    <h2>
                      {order.name} - Apartamento{' '}
                      {order.deliveryAddress[0].apartment}
                    </h2>
                    <div>
                      <h4>Data do Pedido:</h4>
                      <h5>{format(parseISO(order.createdAt), 'dd/MM/yyyy')}</h5>
                    </div>
                    <div>
                      <h4>Data de Entrega:</h4>
                      <h5>{convertDateString(order.deliveryDate)}</h5>
                    </div>
                    <h4>Endereço</h4>
                    <h5>
                      {order.deliveryAddress[0].street} n
                      {order.deliveryAddress[0].streetNumber}
                    </h5>
                    <h5>Morada {order.deliveryAddress[0].streetNumber}</h5>

                    <h5>Código da porta {order.deliveryAddress[0].doorCode}</h5>
                    <h4>Observações: </h4>
                    {order.additionalData === '' ? (
                      <>
                        <h5>{`${'NENHUMA OBSERVAÇÃO ADICIONADA'}`}</h5>
                      </>
                    ) : (
                      <>
                        <h5>{`${order.additionalData}`}</h5>
                      </>
                    )}
                    <div>
                      <h4>Horário</h4>
                      <h5>{order.deliveryTime.substr(0, 5)}</h5>
                    </div>
                    <div>
                      <h4>Morada:</h4>
                      <h5>{order.deliveryAddress[0].doorCode}</h5>
                    </div>
                    <div>
                      <h4>Responsável pela entrega:</h4>
                      {order.deliverymanName === '' ? (
                        <>
                          <div className="formDeliveryMan">
                            <h5>
                              Não há Entregador selecionado para este pedido
                            </h5>
                            <div>
                              <select
                                name="deliverymanname"
                                id="deliverymanname"
                                defaultValue=""
                                onChange={event =>
                                  handleDeliveryman(event.target.value)
                                }
                              >
                                <option value="">Selecionar</option>
                                {deliverymanList &&
                                  deliverymanList?.map(driver => (
                                    <option key={driver.id} value={driver.id}>
                                      {driver.name} - {driver.licensePlate}
                                    </option>
                                  ))}
                              </select>
                              <Button
                                onClick={() =>
                                  handleOnConfirmDeliveryMan(deliverymanId)
                                }
                              >
                                Confirmar
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <h5>{order.deliverymanName}</h5>
                      )}
                    </div>
                    <div className="grayLine" />
                    <h4>Entrega</h4>
                  </div>
                  <div className="lunchContainer">
                    {order.lunchs.length !== 0 &&
                      order.lunchs !== undefined &&
                      order.lunchs.map((lunch, index) => (
                        <div key={String(index)} className="contentLunch">
                          <div className="lunchDetail">
                            <div className="orderImg">
                              <img src={lunchImg} alt="" />
                            </div>
                          </div>
                          <div className="orderTitle">
                            <h4>{lunch.lunchType}</h4>
                            <span>{lunch.description}</span>
                          </div>
                          <div className="orderQtd">
                            <h4>Quantidade</h4>
                            <p>{lunch.quantity}</p>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="grayLine" />
                  <div className="statusBadge">
                    <h2>Status da Produção</h2>
                    <div className={confirmStatus(order.status)}>
                      <span>{formatStatus(order.status)}</span>
                    </div>
                  </div>
                  <div className="formChangeStatusBottom">
                    <h3>Altere o status do pedido:</h3>
                    <div>
                      <select
                        name="changeStatus"
                        id="changeStatus"
                        defaultValue=""
                        onChange={event =>
                          handleChangeStatus(event.target.value)
                        }
                      >
                        <option value={order.status}>Selecionar</option>

                        <option value="A_CONFIRMAR">A Confirmar</option>
                        <option value="EM_PRODUCAO">Em Produção</option>
                        <option value="FINALIZADO">Finalizado</option>
                        {order?.deliverymanName === '' ? (
                          <option value="openModal">Entregue</option>
                        ) : (
                          <option value="ENTREGUE">Entregue</option>
                        )}
                        <option value="CANCELADA">Cancelada</option>
                      </select>
                      <Button
                        onClick={() =>
                          handleChangeStatusConfirm(
                            orderStatus,
                            order.id,
                            order,
                            order.apartment
                          )
                        }
                      >
                        Confirmar
                      </Button>
                    </div>

                    <div className="bottomPedidos">
                      <Button
                        className="allItems"
                        type="button"
                        onClick={() => history.go(-1)}
                      >
                        Voltar
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Section>
        {activeModal?.id && (
          <DeliveryManModal
            id={activeModal?.id}
            isOpen={isOrderModalOpen}
            onRequestClose={handleCloseModal}
            onConfirmDeliveryMan={handleOnConfirmDeliveryMan}
            onCancelDeliveryMan={handleCloseModal}
          />
        )}

        {activeModal?.id && (
          <CancelOrderModal
            id={activeModal.id}
            isOpen={open}
            onRequestClose={handleCloseModal}
            client={activeModal.name}
            verifyIfCancelAll={cancelAll}
          />
        )}
      </Main>
    </>
  );
};

export default ProducaoDoDiaDetalhe;
