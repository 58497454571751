import { shade } from 'polished';
import styled, { css } from 'styled-components';
import { Search, ChevronLeft } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;

  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    h2 {
      color: var(--primary);
    }
  }

  .totalValue {
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 24px;
      color: var(--secondary);
      margin-left: 70px;
    }
  }

  h2 {
    font-size: 2.4rem;
    margin: 64px 0 29px 71px;
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    margin-right: 10px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    border-bottom: 1px solid var(--line);
    display: flex;
    flex-direction: column;
    align-items: left;

    text-align: left;

    padding: 10px;
    margin: 20px 0 40px 0px;

    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
      }
    }

    .orderContainer {
      display: flex;
      flex-direction: column;
      margin: 16px 0 8px 40px;

      h2 {
        font-weight: 700;
      }

      & > div {
        display: flex;
        h4 {
          margin-right: 10px;
        }
        h5 {
          margin-top: 2px;
        }
      }
      h4 {
        font-weight: 700;
        margin-bottom: 18px;
      }

      h5 {
        font-weight: 400;
        margin-bottom: 10px;
      }

      .formDeliveryMan {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        margin-left: 0 auto;

        h5 {
          margin-bottom: 4px;
        }

        button {
          width: 130px;
          height: 37px;
          margin-left: 10px;
        }

        & div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      select {
        width: 230px;
        height: 47px;
        border: 0;
        border-radius: 20px;
        margin-top: 20px;
        padding: 0 20px;
        background: var(--input);
        color: var(--secondary);
      }
    }

    .aConfirmar {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 20px;
      width: 135px;
      height: 33px;
      background: var(--blue);
      margin: 28px 29px 0 0px;
      span {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .comPendencia {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      width: 135px;
      height: 33px;
      background: var(--red);
      margin: 28px 29px 0 0px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .emProducao {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green-light);
      margin: 28px 29px 0 0px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--secondary);
      }
    }

    .finalizado {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green-dark-plus);
      margin: 28px 29px 0 0px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .entregue {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green);
      margin: 28px 29px 0 0px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .cancelado {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--primary);
      margin: 28px 29px 0 0px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    button.button-plus {
      width: 31px;
      height: 31px;
      background: var(--primary);

      margin-left: 10px;
      margin-top: -10px;
      transition: color 0.2s;

      & > span {
        margin: 1px 2px 2px -7px;
        font-size: 2.4rem;
        font-weight: 400;

        text-align: center;
      }
      &:hover {
        background: ${(shade(0.2), '#EB5757')};
      }
    }

    .lunchContainer {
      width: 100%;
      max-width: 980px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      margin: 0 0 20px 40px;

      .contentLunch {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        width: 100%;
      }

      .lunchDetail {
        width: 100%;
        max-width: 100px;

        display: flex;
        justify-content: space-between;

        .orderImg {
          width: 100%;
          max-width: 80px;
          img {
            width: 80px;
            display: flex;
            align-items: center;
            margin: 0 16px 30px 0;
          }
        }
      }
      .orderTitle {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-bottom: 8px;

        span {
          margin: 8px 10px;
        }
        h4 {
          margin: 8px 10px;
        }
      }
      .orderQtd {
        width: 100%;
        max-width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      p {
        font-size: 6.4rem;
        font-weight: 700;
      }
    }

    .statusBadge {
      display: flex;
      flex-direction: column;
      margin: 16px 0 8px 40px;

      > div {
        display: flex;
        margin-right: 8px;
      }
      h2 {
        font-size: 2.4rem;
        color: var(--secondary);
        font-weight: 700;
        margin: 0 8px 10px 0;
      }

      h5 {
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
  }

  .bottomPedido {
    display: flex;
    align-items: left;
    bottom: 0;
    margin: 40px 0 50px 70px;
    float: left;
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;
export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
  ${iconCSS}
`;

export const SourceGraphic = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
  h1 {
    margin: 0 29px 14px 35px;

    font-size: 2.4rem;
    font-weight: 500;
    color: var(--primary);
  }

  .leftGraphic {
    width: 560px;
    height: 330px;
    padding: 44px 43px;
    background: var(--background);
    box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.05);
  }

  .rightGraphic {
    width: 560px;
    height: 330px;
    padding: 44px 43px;
    background: var(--background);
    box-shadow: 0px 4px 21px -2px rgba(0, 0, 0, 0.05);
  }
`;
