/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { format, isSameDay, parse, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import api from '../../../services/api';
import { Main, Section } from './styles';
import { useToast } from '../../../hooks/toast';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

interface IOrderParams {
  id: string;
}

const EntregasPendentesProduction: React.FC = () => {
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const { addToast } = useToast();
  const history = useHistory();
  const { id } = useParams<IOrderParams>();

  const today = new Date();

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao obter a lista de Entregadores',
      });
    }
  };

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );

    const orderArray = order.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setTodayOrders(orderByTime);
  };

  const getNextOrders = async () => {
    const order = await api.get<ITodayOrders[]>('food-order/all/next');
    const orderArray = order.data;

    const orderBySchedule = orderArray.sort((leftDate, rightDate) => {
      const leftTimestamp = Date.parse(leftDate.deliveryDate);
      const rightTimestamp = Date.parse(rightDate.deliveryDate);
      return leftTimestamp - rightTimestamp;
    });
    setNextOrders(orderBySchedule);
  };

  const didMount = () => {
    setLoading(true);
    getTodayOrders();
    getNextOrders();
    getAllDeliveryman();
    setLoading(false);
  };

  useEffect(didMount, []);

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  const isNextDateAfterPrevious = (actualDate: string, nextDate: string) => {
    const formatActual = parse(actualDate, 'dd/MM/yyyy', new Date());
    const formatNext = parse(nextDate, 'dd/MM/yyyy', new Date());
    return isSameDay(formatActual, formatNext);
  };

  function handleOnConfirmDeliveryMan(deliverymanId: string, id: string) {
    setLoading(true);
    api.put(
      `/food-order/update/${id}/deliveryman/${deliverymanId}`,
      deliverymanId,
    );

    addToast({
      type: 'success',
      title: 'Entregador adicionado!',
      description: 'Entregador adicionado ao pedido com sucesso',
    });
    setLoading(false);
    setTimeout(() => {
      didMount();
      history.push('/entregas-pendentes-producao');
    }, 500);
  }

  const pendingOrdersToDelivery = nextOrders?.filter((order: any) => {
    return order.status !== 'ENTREGUE';
  });

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Entregas pendentes</h1>
            <h3>
              Acompanhe os pedidos produzidos que estão pendentes de entrega
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <h2>Entregas Pendentes</h2>
            <div className="flex-container">
              <div>
                <h3>Data do Pedido</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Motorista</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {!loading &&
              pendingOrdersToDelivery?.length !== 0 &&
              pendingOrdersToDelivery?.map(order => (
                <div key={order.id} className="flex-container">
                  <div>
                    {format(parseISO(order.deliveryDate) as any, 'dd/MM/yyyy')}
                  </div>
                  <div>{order.deliveryTime.substr(0, 5)}</div>
                  <div>{order.client}</div>

                  <div>
                    {order.deliveryAddress[0].street} n
                    {order.deliveryAddress[0].streetNumber} -
                    {order.deliveryAddress[0].neighborhood}
                  </div>
                  <div>{order.apartment}</div>
                  <div>
                    {order.deliverymanName === '' ? (
                      <>
                        <div className="changeStatusSelect">
                          <h5>Selecione um motorista</h5>
                          <div>
                            <select
                              defaultValue=""
                              name="deliverymanname"
                              id="deliverymanname"
                              onChange={event =>
                                handleDeliveryman(event.target.value)
                              }
                            >
                              <option value="">Selecionar</option>
                              {deliverymanList &&
                                deliverymanList?.map(driver => (
                                  <option key={driver.id} value={driver.id}>
                                    {driver.name} - {driver.licensePlate}
                                  </option>
                                ))}
                            </select>
                            <Button
                              onClick={() =>
                                handleOnConfirmDeliveryMan(
                                  deliverymanId,
                                  order.id,
                                )
                              }
                            >
                              Ok
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <h5>{order.deliverymanName}</h5>
                    )}
                  </div>
                  <div>
                    <Link to={`/producao-do-dia-detalhe-prod/${order.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {pendingOrdersToDelivery?.length === 0 && (
              <h2>Não há pedidos nesse momento para serem produzidos...</h2>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default EntregasPendentesProduction;
