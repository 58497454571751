/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import PulseLoader from 'react-spinners/PulseLoader';
import api from '../../../services/api';
import Button from '../../../components/Button';
import { Main, Section, FormCadastroCliente } from './styles';
import { useToast } from '../../../hooks/toast';
import EditarClienteEndereco from './EditarClienteEndereco';
import EditarClientePequenosAlmocos from './EditarClientePequenosAlmocos';
import EditarClientBasic from './EditarClientBasic';
import {
  IClientFormData,
  IClientLunchsUpdate,
  ILunchFromAPIList,
  INewClientDataAddress,
  INewClientDataBasic,
} from './types';

const EditarCliente: React.FC = () => {
  const { id }: { id: string } = useParams();

  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const history = useHistory();

  const [lunchOptions, setLunchOptions] = useState<ILunchFromAPIList[]>();
  const [clientData, setClientData] = useState<IClientFormData>();

  const loadingStatus = (status: boolean) => {
    return setLoading(status);
  };
  const readClientData = async () => {
    try {
      const response = await api.get(`/client/read/${id}`);
      setClientData(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Editar Cliente',
        description: 'Oops ocorreu um erro',
      });
    }
  };

  const getAllLunchs = async () => {
    try {
      const response = await api.get(`/lunch/list`);
      setLunchOptions(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Editar Cliente',
        description: 'Oops ocorreu um erro',
      });
    }
  };

  const removeClient = async (clientId: string) => {
    try {
      setRemoveLoading(true);
      const response = await api.post(`/client/delete`, { clientId });
      setRemoveLoading(false);
      addToast({
        type: 'success',
        title: 'Cliente Excluído',
        description: 'Cliente Excluído com sucesso!',
      });
      history.push('/clientes');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao excluir o cliente',
      });
      window.scrollTo(0, 0);
    }
  };

  const sendClientCredentialsReset = async () => {
    try {
      setRemoveLoading(true);

      await api.post(`/client/resetcredentials`, {
        id: clientData?.client.id,
        clientEmail: clientData?.client.contactEmail,
      });

      setRemoveLoading(false);
      addToast({
        type: 'success',
        title: 'Reset de Credenciais',
        description: 'Reset de Credenciais Enviada Para o Cliente!',
      });
      history.push('/clientes');
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Reset de Credenciais',
        description:
          JSON.parse(error.request.response).message ||
          'Oops ocorreu um erro ao resetar credenciais do cliente!',
      });
      window.scrollTo(0, 0);
      history.push('/clientes');
    }
  };

  const didMount = () => {
    loadingStatus(true);
    readClientData();
    getAllLunchs();
    loadingStatus(false);
  };

  useEffect(didMount, []);

  function handleBack() {
    history.go(-1);
  }
  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe o cadastro de seus clientes</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="flex-container">
              <div>
                <h2>Editar Cadastro</h2>
              </div>

              <div className="menu-cadastro">
                <Link to="/clientes">
                  <div className="item-novo-cadastro">Cadastrados</div>
                </Link>
                <div className="item-cadastrados">Novo Cadastro</div>
              </div>
              <div>
                {!loading && clientData && (
                  <Form onSubmit={() => {}}>
                    <FormCadastroCliente>
                      <EditarClientBasic
                        clientData={clientData}
                        loadingStatus={loadingStatus}
                        readClientData={readClientData}
                      />
                      <div className="redLine" style={{ marginTop: 32 }} />
                      <EditarClienteEndereco
                        clientData={clientData}
                        loadingStatus={loadingStatus}
                        readClientData={readClientData}
                      />
                      <div className="redLine" style={{ marginTop: 32 }} />
                      <EditarClientePequenosAlmocos
                        loadingStatus={loadingStatus}
                        clientData={clientData}
                        lunchOptions={lunchOptions!}
                      />
                    </FormCadastroCliente>
                    <div className="redLine" style={{ marginTop: 32 }} />
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 50,
                        alignItems: 'center',
                      }}
                    >
                      <h2>Remover Cliente</h2>
                      <div style={{ marginLeft: 35 }}>
                        {removeLoading && <PulseLoader color="#bf1e2d" />}
                      </div>
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <Button
                        onClick={() => removeClient(clientData.client.id)}
                        className="buttonSave"
                      >
                        Remover
                      </Button>
                      <Button
                        style={{ marginLeft: 20 }}
                        onClick={() => sendClientCredentialsReset()}
                        className="buttonSave"
                      >
                        Resetar Credenciais
                      </Button>
                      <Button className="allItems" onClick={handleBack}>
                        Voltar
                      </Button>
                    </div>
                  </Form>
                )}
                {loading && <h4>Carregando dados para edição...</h4>}
              </div>
            </div>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default EditarCliente;
