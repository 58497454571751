/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { debounce } from 'lodash';
import api from '../../../services/api';
import { showUserType } from '../../../utils/formatStatus';

import Button from '../../../components/Button';
import { Main, Section, SearchIcon } from './styles';
import Input from '../../../components/Input/index';

interface IProfileData {
  id: string;
  name: string;
  contactNumber: string;
  job: string;
  userType: string;
  password: string;
}

const Painel: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [initialProfile, setInitialProfile] = useState<any>([]);
  const [profiles, setProfiles] = useState<any>();

  const fetchProfiles = async () => {
    try {
      const response = await api.get<IProfileData>('/control-panel/list');
      setInitialProfile(response.data);
      setProfiles(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchProfiles();
  };
  useEffect(didMount, []);

  const debouncedData = useCallback(
    debounce(() => {
      if (search !== '') {
        const mySearch = profiles.filter((c: { name: string }) =>
          c.name.toLowerCase().includes(search.toLocaleLowerCase()),
        );
        if (search.length) {
          setDataSearch(mySearch);
        }
      } else {
        setDataSearch(profiles);
      }
    }, 500),
    [profiles, search],
  );

  useEffect(() => {
    debouncedData();
  }, [search, fetchProfiles]);

  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Gerenciar Usuários</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>
      <Section>
        <div className="sectionCard">
          <h2>Cadastro de Usuários</h2>
          <div className="flex-container">
            <div />
            <div className="menu-cadastro">
              <div className="item-cadastrados">Usuários Cadastrados</div>
              <Link to="/cadastro-usuario">
                <div className="item-novo-cadastro">Novo Usuário</div>
              </Link>
            </div>
            <Form ref={formRefSearch} onSubmit={() => {}}>
              <div className="searchBar">
                <SearchIcon />
                <Input
                  name="search"
                  type="text"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Procure por nome ou endereço"
                />
              </div>
            </Form>
            <div className="box-list">
              <div>
                {!loading &&
                  dataSearch?.map((item: any) => {
                    return (
                      <div key={item.id} className="list-entregadores">
                        <div>
                          <div>
                            <h3>{item.name}</h3>
                            <span>
                              <div>
                                <span>{showUserType(item.userType)}</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <div>
                          <div className="bottomPedidos">
                            <Link to={`/editar-usuario/${item.id}`}>
                              <Button className="allItems">Editar</Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {profiles?.length === 0 && (
                  <h4>Você não possui Usuários cadastrados...</h4>
                )}
                {loading && (
                  <div className="loadingData">
                    <span>Carregando...</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default Painel;
