import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';

import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { EditIcon, DeleteIcon } from '../styles';
import { IClientFormData, INewClientDataAddress } from '../types';

interface IProps {
  clientData: IClientFormData;
  loadingStatus(status: boolean): void;
  readClientData(): void;
}

const generateEmptyAddress = () => {
  const data = {
    city: '',
    doorCode: '',
    neighborhood: '',
    number: '',
    numberOfBedrooms: '',
    street: '',
    streetNumber: '',
    deliveryPlace: '',
    addressType: 'DELIVERY',
    deliveryPrice: 0,
    apartments: [{ aptId: 1, value: '' }],
  } as any;

  return data;
};

const EditarClienteEndereco: React.FC<IProps> = ({
  clientData,
  loadingStatus,
  readClientData,
}) => {
  const { addToast } = useToast();

  const [localLoading, setLocaLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<INewClientDataAddress | any>(
    generateEmptyAddress(),
  );

  const removeAddress = async (addressId: string) => {
    try {
      setRemoveLoading(true);
      const request = await api.delete(`client/delete/address/${addressId}`);
      await readClientData();

      addToast({
        type: 'success',
        title: 'Endereço Excluído!',
        description: 'Endereco Excluído!',
      });
      setRemoveLoading(false);
    } catch (error) {
      setRemoveLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na Atualização Endereco',
        description:
          JSON.parse(error.request.response).message ||
          'Falha em atualizar endereco',
      });
      window.scrollTo(0, 0);
    }
  };

  const changeApartmentNumber = (aptId: number, apt: string) => {
    const obj = {
      aptId,
      value: apt,
    };

    setAddress({
      ...address,
      apartments: address.apartments.map((place: any) =>
        place.aptId === aptId ? obj : place,
      ),
    });
  };

  const addApartment = () => {
    const newApt = { aptId: uuidv4(), value: '' };

    setAddress({
      ...address,
      apartments: [...address.apartments, newApt],
    });
  };

  const changeAddressData = ({ input, data }: { input: any; data: any }) => {
    const main: any = address;

    main![input] = data;

    setAddress({ ...address, input: data });
  };

  const removeApt = ({ aptId }: { aptId: number }) => {
    if (address.apartments.length > 1) {
      setAddress({
        ...address,
        apartments: address.apartments.filter(
          (apt: any) => apt.aptId !== aptId,
        ),
      });
    }
  };

  const saveAddress = async () => {
    setLocaLoading(true);
    try {
      const transformArray: any = {
        ...address,
        apartments: address.apartments.map((item: any) => item.value),
      };
      await api.post(
        `client/add/address/${clientData.client.id}`,
        transformArray,
      );
      setAddress(generateEmptyAddress());
      await readClientData();
      setLocaLoading(false);
      addToast({
        type: 'success',
        title: 'Edição Realizada!',
        description: 'Edição Realizada!',
      });
    } catch (error) {
      setLocaLoading(false);
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao realizar o cadastro',
      });
    }
  };

  return (
    <>
      <div className="morada">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Editar ou Excluir Morada(s)</h2>
          <div style={{ marginLeft: 35 }}>
            {removeLoading && <PulseLoader color="#bf1e2d" />}
          </div>
        </div>
        <div style={{ marginTop: 30, opacity: removeLoading ? 0.3 : 1 }}>
          {clientData &&
            clientData.address &&
            clientData.address.map(morada => (
              <div key={morada.id} className="listMoradas">
                <div>
                  <div>
                    <h3>Endereço</h3>
                  </div>
                  <div>
                    <h4>
                      {morada.street}, {morada.streetNumber},
                      <br /> {morada.neighborhood}
                    </h4>
                  </div>
                </div>
                <div>
                  <Link
                    to={`/editar-morada/${clientData.client.id}/${morada.id}`}
                  >
                    <EditIcon />
                  </Link>
                  <DeleteIcon
                    onClick={() => removeAddress(morada.id)}
                    disable={clientData.address.length < 2}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="redLine" />
      <div className="morada">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Cadastrar Nova Morada</h2>
          <div style={{ marginLeft: 35, verticalAlign: 'middle' }}>
            {localLoading && <PulseLoader color="#bf1e2d" />}
          </div>
        </div>
        <div
          className="formulario-cadastro"
          style={{ opacity: localLoading ? 0.3 : 1 }}
        >
          <div>
            <div className="form-inputs">
              <h5>Rua</h5>
              <Input
                name="street"
                id="street"
                type="text"
                value={address.street}
                onChange={e =>
                  changeAddressData({
                    data: e.target.value,
                    input: e.target.id,
                  })
                }
              />
            </div>
            <div className="form-inputs">
              <h5>Número</h5>
              <Input
                id="streetNumber"
                name="streetNumber"
                type="text"
                className="input-numero"
                value={address.streetNumber}
                onChange={e =>
                  changeAddressData({
                    data: e.target.value,
                    input: e.target.id,
                  })
                }
              />
            </div>
          </div>
          <div>
            <div>
              <div className="form-inputs">
                <h5>Bairro</h5>
                <Input
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  value={address.neighborhood}
                  onChange={e =>
                    changeAddressData({
                      data: e.target.value,
                      input: e.target.id,
                    })
                  }
                />
              </div>
              <div className="form-inputs">
                <h5>Cidade</h5>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  value={address.city}
                  onChange={e =>
                    changeAddressData({
                      data: e.target.value,
                      input: e.target.id,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="form-inputs">
                <h5>Morada de Entrega</h5>
                <select
                  name="deliveryPlace"
                  id="deliveryPlace"
                  defaultValue={address?.deliveryPlace}
                  onChange={e =>
                    changeAddressData({
                      data: e.target.value,
                      input: e.target.id,
                    })
                  }
                >
                  <option value="Morada de Entrega">Morada de Entrega</option>
                  <option value="FISCAL">FISCAL</option>
                  <option value="DELIVERY">DELIVERY</option>
                </select>
              </div>
              <div className="form-inputs">
                <h5>Código da Porta</h5>
                <Input
                  id="doorCode"
                  name="doorCode"
                  type="text"
                  value={address.doorCode}
                  onChange={e =>
                    changeAddressData({
                      data: e.target.value,
                      input: e.target.id,
                    })
                  }
                />
              </div>
              <div className="form-inputs">
                <h5>Custo de Entrega</h5>
                <Input
                  id="deliveryPrice"
                  name="deliveryPrice"
                  type="number"
                  className="input-numero"
                  min={0}
                  value={address.deliveryPrice}
                  onChange={e =>
                    changeAddressData({
                      data: e.target.value,
                      input: e.target.id,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div className="form-inputs">
              <h4>Quantidade de Apartamentos</h4>
              <div className="form-inputs">
                <h5>Clique no botão Abaixo para adicionar apartamentos</h5>
                <Button
                  className="buttonSave"
                  type="button"
                  onClick={() => addApartment()}
                >
                  Adicionar apartamentos
                </Button>
                <div className="form-inputs-apartments">
                  {address.apartments.length > 0 &&
                    address.apartments.map((i: any, index: any) => (
                      <div key={i.aptId}>
                        <div className="form-input-numbers">
                          <h5>Apt {index + 1}</h5>
                          <div>
                            <Input
                              className="apartment"
                              onChange={e =>
                                changeApartmentNumber(i.aptId, e.target.value)
                              }
                              value={i.value}
                              name="listApartments"
                              type="text"
                            />
                            <DeleteIcon
                              className="buttonDeleted"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                removeApt({
                                  aptId: i.aptId,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Button onClick={() => saveAddress()} className="buttonSave">
            Adicionar Morada
          </Button>
          <div />
        </div>
      </div>
    </>
  );
};

export default EditarClienteEndereco;
