/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 8,
    marginLeft: 6,
    marginRight: 6,
    backgroundColor: '#FCFCFC',
  },

  header: {
    flexDirection: 'row',
  },

  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  menu: {
    width: '100%',
    paddingRight: 9,
    flexDirection: 'row',
    borderTop: '1px solid #2E3135',
    borderBottom: '1px solid #2E3135',
  },

  content: {
    width: '100%',
    paddingRight: 4,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  section: {
    margin: 8,
    maxWidth: 255,
    paddingLeft: 9,
    paddingRight: 9,
    paddingTop: 4,
    paddingBottom: 4,
    flexGrow: 1,
  },

  main: {
    alignContent: 'flex-start',
    textAlign: 'center',
    width: 180,
    maxWidth: 270,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    flexGrow: 1,
    borderBottom: '1px solid #E7E7E7',
  },

  image: {
    maxWidth: 180,
    objectFit: 'cover',
    width: '60%',
    height: 50,
  },

  title: {
    fontSize: 12,
    marginBottom: 6,
    fontWeight: 'bold',
  },

  subtitle: {
    fontSize: 10.5,
    fontWeight: 'normal',
    marginRight: 8,
  },

  boxtitle: {
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 9,
  },

  text: {
    textAlign: 'left',
    alignItems: 'flex-start',
    fontSize: 9,
    marginLeft: 8,
  },
});

interface IDeliveryman {
  deliverymanId: string;
  deliverymanName: string;
  total: number;
  totalOrders: number;
  totalDeliverys: number;
}

interface IDeliverymanAll {
  data: IDeliveryman[];
}

interface IProps {
  startDate: string;
  endDate: string;
  client: IDeliverymanAll;
}

function PDFDeliveryManExplode({ client, endDate, startDate }: IProps) {
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View wrap={false} style={styles.header}>
          <View style={styles.section}>
            <Image style={styles.image} src="/img/logo.png" />
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Relatório de Entregadores</Text>
            <Text style={styles.title}>Pedidos por Período</Text>
            <Text style={styles.subtitle}>
              Período de {startDate} a {endDate}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              Emissão:
              <Text style={styles.text}>
                {format(new Date(), 'dd/MM/yyyy')}
              </Text>
            </Text>
            <Text style={styles.subtitle}>
              Horário:{' '}
              <Text style={styles.text}>{format(new Date(), 'HH:mm')}</Text>
            </Text>
          </View>
        </View>

        <View style={styles.container}>
          <View style={styles.menu}>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Nome do Entregador</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>
                Quantidade de Pequenos Almoços
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Quantidade de Entregas</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Total Valor Gerado</Text>
            </View>
          </View>
          {client.data.map((i, index) => (
            <View key={String(index)} style={styles.content}>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.deliverymanName}</Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.totalDeliverys}</Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.totalOrders}</Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.total}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}

export default PDFDeliveryManExplode;
