/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format, parseISO } from 'date-fns';
import { CSVLink } from 'react-csv';
import Input from '../../../components/Input';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import PDFNegocioExplode from './PDFNegocioExplode';

import Button from '../../../components/Button';
import { Main, Section, ChevronLeftIcon, SourceGraphic } from './styles';

interface IBusinessSingleDayAddress {
  doorCode: string;
  neighborhood: string;
  street: string;
  streetNumber: string;
  deliveryPlace: string;
}
interface IBusinessSingleDayLunchs {
  order: string;
  quantity: string;
  description: string;
}
interface IBusinessSingleDay {
  id: string;
  client: string;
  totalCost: number;
  quantity: number;
  lunchs: IBusinessSingleDayLunchs[];
  address: IBusinessSingleDayAddress[];
}
interface IBusinessAllSingleDay {
  data: IBusinessSingleDay[];
}

const RelatorioNegocioExplode: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<IBusinessAllSingleDay>({ data: [] });

  const { date }: { date: string } = useParams();

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IBusinessAllSingleDay>(
        `/reports/business/single?start=${date}`,
      );
      setClients(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  useEffect(didMount, []);

  const clientDataList = clients.data.map(client => {
    const address = client.address.map(add => {
      return {
        addresses: `${add.street} n${add.streetNumber} - ${add.neighborhood}`,
      };
    });

    let lc: any = [];
    client.lunchs.map(lunch => {
      if (lc.length > 0) {
        lc[0] = `${lc[0]} | ${lunch.quantity} - ${lunch.order}`;
      } else {
        lc[0] = `${lunch.quantity} - ${lunch.order}`;
      }
      return {
        ...lunch,
      };
    });

    return {
      ...client,
      deliveryAddress: address[0].addresses,
      lunchs: lc[0],
    };
  });

  const csvHeaders = [
    { label: 'Cliente', key: 'client' },
    { label: 'Endereço de Entrega', key: 'deliveryAddress' },
    { label: 'Quantidade', key: 'quantity' },
    { label: 'Pedido', key: 'lunchs' },
    { label: 'Valor Total Gerados', key: `€'total'` },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Negócios</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="clientName">
              <Link to="/relatorio-negocio">
                <ChevronLeftIcon />
                <h2>{format(parseISO(date), 'dd/MM/yyyy')}</h2>
              </Link>
            </div>
            <div className="flex-container">
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço de Entrega</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Valor Gerado</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {clients?.data.map(i => (
              <div key={i.id} className="flex-container">
                <div>{i.client}</div>
                <div>
                  {i.address[0].street} {i.address[0].streetNumber} -{' '}
                  {i.address[0].neighborhood} - Morada
                  {i.address[0].deliveryPlace}
                </div>
                <div>{i.quantity}</div>
                <div className="listLunch">
                  {i.lunchs.map(z => (
                    <div key={z.order}>
                      {z.quantity} - {z.order}
                    </div>
                  ))}
                </div>
                <div>{i.totalCost}</div>

                <div>
                  <Link to={`/relatorio-negocio-explode-final/${i.id}`}>
                    <Button className="button-plus">
                      <span>+</span>
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFNegocioExplode client={clients} date={date} />
                    }
                    fileName="relatorio-negocio.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Exportar PDF</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clientDataList}
                      headers={csvHeaders}
                      separator=";"
                      filename="relatorio-negocio.csv"
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioNegocioExplode;
