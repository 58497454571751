/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { subDays, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVLink } from 'react-csv';
import Input from '../../../components/Input';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';

import PDFDeliveryMan from './PDFDeliveryMan';

import Button from '../../../components/Button';
import { Main, Section, SearchIcon, SourceGraphic } from './styles';

interface IDeliveryman {
  deliverymanId: string;
  deliverymanName: string;
  total: number;
  totalDeliverys: number;
  totalOrders: number;
}

interface IDeliverymanAll {
  data: IDeliveryman[];
}

const RelatorioEntregadores: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [initialClient, setInitialClient] = useState<IDeliverymanAll>();
  const [clients, setClients] = useState<IDeliverymanAll>({ data: [] });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IDeliverymanAll>(
        `deliveryman/all/report?start=${format(
          dateFilter.start,
          'yyyy-MM-dd',
        )}&end=${format(dateFilter.end, 'yyyy-MM-dd')}`,
      );
      let sorted = response.data;
      if (response.data.data.length) {
        sorted = {
          data: response.data.data.sort((a, b) => {
            return a.total - b.total;
          }),
        };
      }

      setInitialClient(sorted);
      setClients(sorted);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };
  useEffect(didMount, [dateFilter]);

  const csvHeaders = [
    { label: 'Entregador', key: 'deliverymanName' },
    { label: 'Quantidade de Entregas', key: 'totalDeliverys' },
    { label: 'Quantidade de Pequenos Almoços', key: 'totalOrders' },
    { label: 'Valor Total Gerados', key: 'total' },
  ];

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Entregadores</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <h4>Período específico</h4>
                  <select
                    defaultValue=""
                    name="periodo-especifico"
                    id="periodo-especifico"
                    onChange={e =>
                      handleDateFilter(e.target.value, e.target.id)
                    }
                  >
                    <option id="last" value={7}>
                      Últimos 07 dias
                    </option>
                    <option id="last" value={15}>
                      Últimos 15 dias
                    </option>
                    <option id="last" value={30}>
                      Últimos 30 dias
                    </option>
                    <option id="last" value={60}>
                      Últimos 60 dias
                    </option>
                    <option id="last" value={90}>
                      Últimos 90 dias
                    </option>
                  </select>
                </div>
                <div className="delivery-date">
                  <div>
                    <span>Inicio</span>
                    <DatePicker
                      className="datapicker"
                      selected={dateFilter.start}
                      maxDate={dateFilter.end}
                      onChange={date => handleStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="I have been cleared!"
                    />
                  </div>
                  <div>
                    <span>Fim</span>
                    <DatePicker
                      className="datapicker"
                      minDate={dateFilter.start}
                      selected={dateFilter.end}
                      onChange={date => handleEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="I have been cleared!"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <h2>Entregas no Período</h2>
            <div className="flex-container">
              <div>
                <h3>Entregador</h3>
              </div>
              <div>
                <h3>Quantidade de Entregas Feitas</h3>
              </div>
              <div>
                <h3>Quantidade de Pequenos Almoços</h3>
              </div>
              <div>
                <h3>Valor Total Gerado</h3>
              </div>
              <div className="item">Item</div>
            </div>
            {clients?.data.map(i => (
              <div key={i.deliverymanId} className="flex-container">
                <div>{i.deliverymanName}</div>
                <div>{i.totalOrders}</div>
                <div>{i.totalDeliverys}</div>

                <div>{i.total}</div>

                <div>
                  <Link
                    to={`/relatorio-entregadores-explode/${i.deliverymanId}`}
                  >
                    <Button className="button-plus">
                      <span>+</span>
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
            {!loadingV2 && (
              <>
                <div>
                  <PDFDownloadLink
                    document={
                      <PDFDeliveryMan
                        client={clients}
                        startDate={format(dateFilter.start, 'dd/MM/yyyy')}
                        endDate={format(dateFilter.end, 'dd/MM/yyyy')}
                      />
                    }
                    fileName="relatorio-entregadores.pdf"
                  >
                    {({ blob, url, loading, error }) => (
                      <Button className="allItems">Exportar PDF</Button>
                    )}
                  </PDFDownloadLink>
                  <div>
                    <CSVLink
                      data={clients.data}
                      headers={csvHeaders}
                      separator=";"
                      filename="relatorio-cliente.csv"
                      target="_blank"
                    >
                      <Button className="allItems">Exportar CSV</Button>
                    </CSVLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioEntregadores;
