/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { ReactNode } from 'react';

interface SiteProps {
  children: ReactNode;
}

export default function Login({ children }: SiteProps) {
  return <>{children}</>;
}
