/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import api from '../../../services/api';
import { Main, Section, SearchIcon } from './styles';

interface IClientFormData {
  client: {
    clientList: {
      id: string;
      name: string;
      userType: string;
      status: string;
    };
    address: Array<{
      id: string;
      street: string;
      doorCode: string;
      apartments: Array<string>;
      neighborhood: string;
    }>;
  };
}

const NovoPedidoProduction: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<any>();

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IClientFormData>('/client/all');
      setClients(response.data.client.clientList!);
      setDataSearch(response.data.client.clientList!);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  useEffect(didMount, []);

  const debouncedData = useCallback(
    debounce(() => {
      if (search !== '') {
        const mySearch = clients.filter((c: { name: string }) =>
          c.name.toLowerCase().includes(search.toLocaleLowerCase()),
        );
        if (search.length) {
          setDataSearch(mySearch);
        }
      } else {
        setDataSearch(clients);
      }
    }, 500),
    [clients, search],
  );

  useEffect(() => {
    debouncedData();
  }, [search, didMount]);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Novo Pedido</h1>
          </div>
          <Link to="/novo-pedido-producao">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="flex-container">
              <div>
                <h2>Selecione o Destino</h2>
              </div>
              <div />
              <Form ref={formRefSearch} onSubmit={() => {}}>
                <div className="searchBar">
                  <SearchIcon />
                  <Input
                    name="search"
                    type="text"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Procure por nome ou endereço"
                  />
                </div>
              </Form>
              <div>
                <div className="list-clientes">
                  <div>
                    {!loading &&
                      dataSearch?.map((c: any) => (
                        <div key={c.id}>
                          <div className="client-item">
                            <div>
                              <h4>{c.name}</h4>
                            </div>
                            <div className="bottomPedidos">
                              <Link
                                to={`/cadastro-novo-pedido-producao/${c.id}`}
                              >
                                <Button className="allItems">Selecionar</Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    {clients?.length === 0 && (
                      <h4>Não há Clientes cadastrados...</h4>
                    )}
                    {loading && (
                      <div className="loadingData">
                        <span>Carregando...</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default NovoPedidoProduction;
