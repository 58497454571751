/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Button from '../../../components/Button';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';
import api from '../../../services/api';
import { Main, Section } from './styles';
import { convertDateString } from '../../../utils/convertDateString';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  quantity: string;
  description: string;
}

interface ITodayOrders {
  data: any;
  id: string;
  createdAt: string;
  deliveryDate: string;
  deliveryTime: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

const DeliveryDayClient: React.FC = () => {
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const userClient = JSON.parse(
    window.localStorage.getItem('@BiB:user') || '{}',
  );

  const today = new Date();

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/client/${userClient.id}/${formattedDate}`,
    );
    setTodayOrders(order.data);
  };

  const getNextOrders = async () => {
    const order = await api.get<ITodayOrders[]>(
      `food-order/client/next/orders/${userClient.id}`,
    );

    setNextOrders(order.data);
  };

  const didMount = () => {
    setLoading(true);
    getTodayOrders();
    getNextOrders();
    setLoading(false);
  };

  useEffect(didMount, []);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Entregas do dia</h1>
            <h3>
              Acompanhe as entregas que estão sendo realizadas no dia de hoje
            </h3>
          </div>
          <Link to="/novo-pedido-cliente">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          {!loading && todayOrders?.length === 0 ? (
            <>
              <div className="sectionCard">
                <h4 style={{ marginTop: 20, marginLeft: 20, color: '#BF1E2D' }}>
                  Não há nenhum pedido para ser entregue na data de hoje.
                </h4>
              </div>
            </>
          ) : (
            <>
              <div className="sectionCard">
                <h2>Entregas do dia {format(new Date(), 'dd/MM/yyyy')}</h2>
                <div className="flex-container">
                  <div>
                    <h3>Data do pedido</h3>
                  </div>
                  <div>
                    <h3>Horário de Entrega</h3>
                  </div>
                  <div>
                    <h3>Endereço</h3>
                  </div>
                  <div>
                    <h3>Apartamento</h3>
                  </div>
                  <div>
                    <h3>Pedido</h3>
                  </div>
                  <div>
                    <h3>Status</h3>
                  </div>
                  <div className="item">Item</div>
                </div>
                {!loading && <h4>...Carregando</h4> &&
                  todayOrders?.length !== 0 &&
                  todayOrders?.map(order => (
                    <div key={order.id} className="flex-container">
                      <div>{convertDateString(order.createdAt)}</div>
                      <div>{order.deliveryTime.substr(0, 5)}</div>
                      <div>
                        {order.deliveryAddress[0].street}, n
                        {order.deliveryAddress[0].streetNumber}
                      </div>
                      <div>{order.apartment}</div>
                      <div className="clientLunchList">
                        <div>
                          {order.lunchs.length !== 0 &&
                            order.lunchs !== undefined &&
                            order.lunchs.map((lunch, index) => {
                              return (
                                <div
                                  key={String(index)}
                                  className="lunchsPerClient"
                                >
                                  <div>{lunch.quantity} - </div>
                                  <div>{lunch.order}</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div>
                        <div className={confirmStatus(order.status)}>
                          <span>{formatStatus(order.status)}</span>
                        </div>
                      </div>
                      <div>
                        <Link
                          to={`/entregas-do-dia-detalhe-cliente/${order.id}`}
                        >
                          <Button className="button-plus">
                            <span>+</span>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </Section>
      </Main>
    </>
  );
};

export default DeliveryDayClient;
