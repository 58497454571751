/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import { DeliveryManModal } from '../../../components/DeliveryManModal';
import api from '../../../services/api';
import { Main, Section } from './styles';
import { useToast } from '../../../hooks/toast';
import lunchImg from '../../../assets/lunch.png';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  lunchType: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  additionalData: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

interface IOrderParams {
  id: string;
}

const ProducaoDoDiaDetalheProduction: React.FC = () => {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(true);
  const [activeModal, setActiveModal] = useState<ITodayOrders | undefined>();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [order, setOrder] = useState<ITodayOrders>();
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<IOrderParams>();

  const requestData = async () => {
    setLoading(true);
    const response = await api.get<ITodayOrders[]>(`/food-order/get/${id}`);
    setOrder(response.data[0]);
    setLoading(false);
  };

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar dados',
        description: 'Oops ocorreu um erro ao carregar dados de entregadores',
      });
    }
  };

  const today = new Date();

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const orders = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );

    setTodayOrders(orders.data);
  };

  const getNextOrders = async () => {
    const orders = await api.get<ITodayOrders[]>('food-order/all/next');

    setNextOrders(orders.data);
  };

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  const handleChangeStatus = (value: string) => {
    setOrderStatus(value);
  };

  const didUpdate = () => {
    requestData();
    getTodayOrders();
    getNextOrders();
    getAllDeliveryman();
  };

  useEffect(didUpdate, [id]);

  function handleBack() {
    history.go(-1);
  }

  function handleOpenModal(order: ITodayOrders) {
    setActiveModal(order);
    setIsOrderModalOpen(true);
  }

  function handleCloseModal() {
    setIsOrderModalOpen(false);
  }

  function handleChangeStatusConfirm(
    status: string,
    orderId: string,
    order: ITodayOrders,
  ) {
    if (status === 'openModal') {
      handleOpenModal(order);
      return;
    }

    setLoading(true);
    api.put(`food-order/update/status/${id}`, {
      status,
      id,
    });

    addToast({
      type: 'success',
      title: 'Status Alterado!',
      description: 'Status alterado com sucesso',
    });
    setLoading(false);
    setIsOrderModalOpen(false);

    window.scrollTo(0, 0);
    setTimeout(() => {
      didUpdate();
      history.push(`/producao-do-dia-detalhe-prod/${id}`);
    }, 1000);
  }

  function handleOnConfirmDeliveryMan(deliverymanId: string, id: string) {
    setLoading(true);
    api.put(
      `/food-order/update/${id}/deliveryman/${deliverymanId}`,
      deliverymanId,
    );
    setIsOrderModalOpen(false);
    addToast({
      type: 'success',
      title: 'Entregador adicionado!',
      description: 'Entregador adicionado ao pedido com sucesso',
    });
    setLoading(false);
    window.scrollTo(0, 0);
    setTimeout(() => {
      didUpdate();
      history.push(`/producao-do-dia-detalhe-prod/${id}`);
    }, 1000);
  }

  const filteredTodayOrders = todayOrders?.filter((order: any) => {
    return order.status === 'A_CONFIRMAR';
  });

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe sua produção de hoje</h1>
            <h3>
              Você possui <span>{filteredTodayOrders?.length} pedidos</span>{' '}
              pendentes para produzir.
            </h3>
          </div>
          <Link to="/novo-pedido-producao">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            {!loading && order && (
              <div className="flex-container">
                <div className="orderContainer">
                  <div>
                    <h4>Data do Pedido:</h4>
                    <h5>{format(parseISO(order.createdAt), 'dd/MM/yyyy')}</h5>
                  </div>
                  <div>
                    <h4>Data de Entrega:</h4>
                    <h5>
                      {format(parseISO(order.deliveryDate), 'dd/MM/yyyy')}
                    </h5>
                  </div>
                  <h3>
                    {order.client} Apartamento:{' '}
                    {order.deliveryAddress[0].apartment}
                  </h3>

                  <h4>Endereço</h4>
                  <h5>
                    {order.deliveryAddress[0].street} n
                    {order.deliveryAddress[0].streetNumber}
                  </h5>
                  <h5>Morada {order.deliveryAddress[0].streetNumber}</h5>

                  <h5>Código da porta {order.deliveryAddress[0].doorCode}</h5>
                  <h5>Observações</h5>
                  <h5>{order.additionalData}</h5>
                  <div>
                    <h4>Horário</h4>
                    <h5>{order.deliveryTime.substr(0, 5)}</h5>
                  </div>
                  <div>
                    <h4>Morada:</h4>
                    <h5>{order.deliveryAddress[0].doorCode}</h5>
                  </div>
                  <div>
                    <h4>Responsável pela entrega:</h4>
                    {order.deliverymanName === '' ? (
                      <>
                        <div className="formDeliveryMan">
                          <h5>
                            Não há Entregador selecionado para este pedido
                          </h5>
                          <div>
                            <select
                              defaultValue=""
                              name="deliverymanname"
                              id="deliverymanname"
                              onChange={event =>
                                handleDeliveryman(event.target.value)
                              }
                            >
                              <option value="">Selecionar</option>
                              {deliverymanList &&
                                deliverymanList?.map(driver => (
                                  <option key={driver.id} value={driver.id}>
                                    {driver.name} - {driver.licensePlate}
                                  </option>
                                ))}
                            </select>
                            <Button
                              onClick={() =>
                                handleOnConfirmDeliveryMan(
                                  deliverymanId,
                                  order.id,
                                )
                              }
                            >
                              Confirmar
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <h5>{order.deliverymanName}</h5>
                    )}
                  </div>
                  <div className="grayLine" />
                  <h4>Entrega</h4>
                </div>
                <div className="lunchContainer">
                  {order.lunchs.length !== 0 &&
                    order.lunchs !== undefined &&
                    order.lunchs.map((lunch, index) => (
                      <div key={String(index)} className="contentLunch">
                        <div className="lunchDetail">
                          <div className="orderImg">
                            <img src={lunchImg} alt="" />
                          </div>
                        </div>
                        <div className="orderTitle">
                          <h4>{lunch.lunchType}</h4>
                          <span>{lunch.description}</span>
                        </div>
                        <div className="orderQtd">
                          <h4>Quantidade</h4>
                          <p>{lunch.quantity}</p>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="grayLine" />
                <div className="statusBadge">
                  <h2>Status da Produção</h2>
                  <div className={confirmStatus(order.status)}>
                    <span>{formatStatus(order.status)}</span>
                  </div>
                </div>
                <div className="bottomPedidos">
                  {order.status !== 'ENTREGUE' && (
                    <>
                      <div className="formChangeStatus">
                        <h3>Altere o status do pedido:</h3>
                        <div>
                          <select
                            name="changeStatus"
                            id="changeStatus"
                            defaultValue={order.status}
                            onChange={event =>
                              handleChangeStatus(event.target.value)
                            }
                          >
                            <option value="A Confirmar">Mudar Status</option>
                            {order.status === 'A_CONFIRMAR' && (
                              <>
                                <option value="A_CONFIRMAR">A Confirmar</option>
                                <option value="EM_PRODUCAO">Em Produção</option>
                              </>
                            )}
                            {order.status === 'EM_PRODUCAO' && (
                              <>
                                <option value="EM_PRODUCAO">Em Produção</option>
                                <option value="FINALIZADO">Finalizado</option>
                              </>
                            )}
                            {order.status === 'FINALIZADO' &&
                              order.deliverymanName !== '' && (
                                <>
                                  <option value="FINALIZADO">Finalizado</option>
                                  <option value="ENTREGUE">Entregue</option>
                                </>
                              )}
                            {order.status === 'FINALIZADO' &&
                              order.deliverymanName === '' && (
                                <option value="openModal">Entregue</option>
                              )}
                          </select>
                          <Button
                            onClick={() =>
                              handleChangeStatusConfirm(
                                orderStatus,
                                order.id,
                                order,
                              )
                            }
                          >
                            Confirmar
                          </Button>
                        </div>
                      </div>
                    </>
                  )}

                  <Button
                    className="allItems"
                    type="button"
                    onClick={handleBack}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Section>
        {activeModal?.id && (
          <DeliveryManModal
            id={activeModal?.id}
            isOpen={isOrderModalOpen}
            onRequestClose={handleCloseModal}
            onConfirmDeliveryMan={handleOnConfirmDeliveryMan}
            onCancelDeliveryMan={handleCloseModal}
          />
        )}
      </Main>
    </>
  );
};

export default ProducaoDoDiaDetalheProduction;
