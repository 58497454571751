import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Container,
  Content,
  HomeIcon,
  BuyIcon,
  BagIcon,
  CalendarIcon,
  DocumentIcon,
  ActivityIcon,
} from './styles';

const MenuAdmin: React.FC = () => {
  return (
    <Container>
      <Content>
        <div className="topMenu" />
        <div className="menuBox">
          <div className="menuItem">
            <NavLink to="/admin" activeClassName="selected">
              <HomeIcon size={20} />
              <div className="titleMenu">
                <h5>HOME</h5>
              </div>
            </NavLink>
          </div>

          <div className="menuItem">
            <NavLink to="/novo-pedido" activeClassName="selected">
              <BuyIcon />
              <div className="titleMenu">
                <h5>NOVO PEDIDO</h5>
              </div>
            </NavLink>
          </div>
          <div className="menuItem">
            <NavLink to="/producao-do-dia" activeClassName="selected">
              <ActivityIcon />
              <div className="titleMenu">
                <h5>PRODUÇÃO</h5>
              </div>
            </NavLink>
          </div>
          <div className="menuItem">
            <NavLink to="/entregas-pendentes" activeClassName="selected">
              <BagIcon />
              <div className="titleMenu">
                <h5>ENTREGAS</h5>
              </div>
            </NavLink>
          </div>
          <div className="menuItem">
            <NavLink to="/proximos-pedidos" activeClassName="selected">
              <CalendarIcon />
              <div className="titleMenu">
                <h5>PROX PEDIDOS</h5>
              </div>
            </NavLink>
          </div>
          <div className="menuItem">
            <NavLink to="/historico" activeClassName="selected">
              <DocumentIcon />
              <div className="titleMenu">
                <h5>HISTÓRICO</h5>
              </div>
            </NavLink>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default MenuAdmin;
