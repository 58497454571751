/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { format, isSameDay, parse, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import api from '../../../services/api';
import { Main, Section } from './styles';
import { useToast } from '../../../hooks/toast';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

interface IOrderParams {
  id: string;
}

const EntregasPendentesAdmin: React.FC = () => {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<ITodayOrders | undefined>();
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const { addToast } = useToast();
  const history = useHistory();
  const { id } = useParams<IOrderParams>();

  const today = new Date();

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao obter a lista de Entregadores',
      });
    }
  };

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );

    const orderArray = order.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setTodayOrders(orderByTime);
  };

  const getNextOrders = async () => {
    const order = await api.get<ITodayOrders[]>('food-order/all/next');
    const orderArray = order.data;

    const orderBySchedule = orderArray.sort((leftDate, rightDate) => {
      const leftTimestamp = Date.parse(leftDate.deliveryDate);
      const rightTimestamp = Date.parse(rightDate.deliveryDate);
      return leftTimestamp - rightTimestamp;
    });
    setNextOrders(orderBySchedule);
  };

  const didMount = () => {
    setLoading(true);
    getTodayOrders();
    getNextOrders();
    getAllDeliveryman();
    setLoading(false);
  };

  useEffect(didMount, []);

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  const isNextDateAfterPrevious = (actualDate: string, nextDate: string) => {
    const formatActual = parse(actualDate, 'dd/MM/yyyy', new Date());
    const formatNext = parse(nextDate, 'dd/MM/yyyy', new Date());
    return isSameDay(formatActual, formatNext);
  };

  const filteredTodayOrders = nextOrders?.filter((order: any) => {
    return order.status === 'EM_PRODUCAO';
  });

  function handleOnConfirmDeliveryMan(deliverymanId: string, id: string) {
    setLoading(true);
    api.put(
      `/food-order/update/${id}/deliveryman/${deliverymanId}`,
      deliverymanId,
    );

    addToast({
      type: 'success',
      title: 'Entregador adicionado!',
      description: 'Entregador adicionado ao pedido com sucesso',
    });
    setLoading(false);
    setTimeout(() => {
      didMount();
      history.push('/entregas-pendentes');
    }, 500);
  }

  const pendingOrdersToDelivery = nextOrders?.filter((order: any) => {
    return order.status !== 'ENTREGUE';
  });

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Entregas pendentes</h1>
            <h3>
              Acompanhe os pedidos produzidos que estão pendentes de entrega
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
            <h2>Entregas Pendentes</h2>
            <div className="flex-container">
              <div>
                <h3>Data do Pedido</h3>
              </div>
              <div>
                <h3>Data de Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Motorista</h3>
              </div>
              <div style={{ width: '30px' }} />
            </div>
            {!loading &&
              pendingOrdersToDelivery?.length !== 0 &&
              pendingOrdersToDelivery?.map((nextOrder, i) => (
                <div
                  key={nextOrder.id}
                  className="flex-container"
                  style={{
                    borderBottom:
                      pendingOrdersToDelivery[i + 1] &&
                      pendingOrdersToDelivery &&
                      isNextDateAfterPrevious(
                        nextOrder.deliveryDate,
                        pendingOrdersToDelivery[i + 1].deliveryDate,
                      )
                        ? '1px solid var(--line)'
                        : '2px solid var(--secondary)',
                  }}
                >
                  <div>
                    {format(parseISO(nextOrder.createdAt), 'dd/MM/yyyy')}
                  </div>

                  <div>
                    {format(parseISO(nextOrder.deliveryDate), 'dd/MM/yyyy')}
                  </div>
                  <div>{nextOrder.deliveryTime.substr(0, 5)}</div>
                  <div>{nextOrder.client}</div>
                  <div className="nextOrderAddress">
                    {nextOrder.deliveryAddress[0].street} n
                    {nextOrder.deliveryAddress[0].streetNumber}-
                    {nextOrder.deliveryAddress[0].neighborhood}
                  </div>
                  <div>
                    <div>
                      {nextOrder.deliverymanName === '' ? (
                        <>
                          <div className="changeStatusSelect">
                            <select
                              name="deliverymanname"
                              id="deliverymanname"
                              defaultValue="Selecionar"
                              onChange={event =>
                                handleDeliveryman(event.target.value)
                              }
                            >
                              <option value="">Selecionar</option>
                              {deliverymanList &&
                                deliverymanList?.map(driver => (
                                  <option key={driver.id} value={driver.id}>
                                    {driver.name} - {driver.licensePlate}
                                  </option>
                                ))}
                            </select>
                            <Button
                              onClick={() =>
                                handleOnConfirmDeliveryMan(
                                  deliverymanId,
                                  nextOrder.id,
                                )
                              }
                            >
                              Ok
                            </Button>
                          </div>
                        </>
                      ) : (
                        <h5>{nextOrder.deliverymanName}</h5>
                      )}
                    </div>
                  </div>
                  <div className="lastDiv">
                    <Link to={`/producao-do-dia-detalhe/${nextOrder.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {pendingOrdersToDelivery?.length === 0 && (
              <h2>Você não possui Pedidos para entrega...</h2>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default EntregasPendentesAdmin;
