/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { IClientFormData } from '..';

interface IProps {
  clientData: IClientFormData;
  handleSelectedAddress(id: string, value: any, price: any): void;
}

const NaoAvulso: React.FC<IProps> = ({ clientData, handleSelectedAddress }) => {
  return (
    <div className="client">
      <div>
        <h4>{clientData.client.companyName}</h4>
      </div>
      <div className="cardClient">
        <div>
          {clientData.address.length > 0 &&
            clientData.address.map(address => (
              <div key={address.id}>
                <div className="addressList">
                  <h5>{address.addressType}</h5>
                  <h5>{address.street} -</h5>
                  <h5>{address.streetNumber}- </h5>
                  <h5>{address.neighborhood}- </h5>
                  <h5>{address.doorCode}- </h5>
                  <h5>{address.city}</h5>
                </div>
                <div>
                  <div className="apartamentsList">
                    <h5>Apartamento</h5>
                    <div className="apartaments">
                      {address.apartments && address.apartments.length > 0 ? (
                        address.apartments.map((i, index) => (
                          <div key={index}>
                            <input
                              className="apartment"
                              type="radio"
                              id={address.id}
                              name="apartment"
                              value={i}
                              onChange={event =>
                                handleSelectedAddress(
                                  event.target.id,
                                  event.target.value,
                                  address.deliveryPrice,
                                )
                              }
                            />
                            <label>{i}</label>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>Sem Apartamentos Cadastrados</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {clientData.address.length === 0 && (
            <h3>Cliente não possui endereço cadastrado...</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default NaoAvulso;
