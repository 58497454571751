import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Search, Delete, EditSquare } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 0 40px 20px 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);
  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    color: var(--primary);
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background: var(--table-color);
    box-shadow: 0px 20px 20px -2px rgba(0, 0, 0, 0.05);

    margin: 25px 0 20px 5px;
    padding: 40px;

    > div {
      display: flex;
      justify-content: space-between;
    }
    .menu-cadastro {
      width: 100%;
      max-width: 815px;
      border-bottom: 2px solid var(--line-table);
      display: flex;
      align-items: center;
      justify-content: left;
      text-decoration: none;
      margin-top: 30px;
    }

    .item-cadastrados {
      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid var(--primary);

      font-size: 1.8rem;
      font-weight: 400;
      color: var(--primary);
    }

    .item-novo-cadastro {
      margin-right: 47px;

      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 400;
      border: 0;
      background: #e8ebf1;
      text-decoration: none;
      color: var(--secondary);

      &:hover {
        border-top: 2px solid var(--primary);
      }
    }

    .bottomPedidos {
      display: flex;
      margin-top: 45px;
      padding-bottom: 10px;
      > button {
        margin-right: 44px;
        margin-top: 0;
        margin-bottom: 60px;
      }
    }
  }

  .box-lunch {
    width: 100%;
    height: 600px;
    max-width: 1079px;
    margin-top: 55px;
    display: flex;
    flex-direction: column;

    .list-lunchs {
      display: flex;

      > div {
        width: 100%;
        height: 100%;
        display: flex;

        justify-content: space-between;

        &:first-child {
          margin-right: 160px;
          padding-bottom: 20px;
        }
        &:last-child {
          padding-bottom: 20px;
        }
      }
      .lunch-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 60px;
        }

        input.input-lunch-cost {
          width: 122px;
        }
      }
    }
  }
  .headLunch {
    margin-top: 32px;
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const EditIcon = styled(EditSquare)`
  ${iconCSS}
`;
export const DeleteIcon = styled(Delete)`
  ${iconCSS}
  width: 30px;
  color: var(--primary);
  margin-left: 10px;
`;

export const FormCadastroCliente = styled.div`
  display: flex;
  flex-direction: column;
  .listMoradas {
    display: flex;
    margin-bottom: 32px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 1.4rem;
      color: var(--primary);
    }
    h3 {
      font-size: 1.6rem;
      color: var(--primary);
    }

    > div:first-child {
      width: 150px;
    }
    > div {
      margin-right: 18px;
    }
  }

  .formulario-cadastro {
    margin-top: 54px;

    div {
      display: flex;
      align-items: left;
      justify-content: left;
      & + div {
        margin-bottom: 30px;
      }
    }

    .form-inputs {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      h5 {
        font-size: 1.6rem;
        font-weight: 400;
        margin-left: 20px;
        margin-bottom: 4px;
      }

      select {
        border: 0;
        width: 320px;
        height: 47px;
        border-radius: 20px;
        background: var(--input);
        padding: 0 10px;
      }

      input {
        border: 0;
        border-radius: 20px;
        width: 320px;
        height: 28px;
        padding: 10px 20px;
        background: var(--input);
      }
      input.input-email {
        width: 450px;
      }
      input.input-numero {
        width: 100px;
      }

      input.apartment {
        width: 120px;
      }

      .select-options {
        border: 0;
        border-radius: 20px;
        width: 320px;
        height: 47px;
        background: var(--input);
        padding: 10px 20px;
        ::placeholder {
          font-size: 1.6px;
          font-weight: 400;
          color: var(--secondary);
        }
      }
    }
  }

  .form-inputs-apartments {
    display: flex;

    flex-wrap: wrap;
    flex-direction: row;
    h5 {
      margin: 10px 10px 20px 0;
    }

    .form-input-numbers {
      display: flex;
      flex-direction: column;
      margin: 10px 10px 0 0;
    }
  }

  .morada {
    margin-top: 30px;

    h2 {
      font-size: 2.4rem;
      font-weight: 500;
    }
  }
`;
