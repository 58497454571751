import React, { useEffect, useRef, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Input';
import api from '../../../services/api';
import PizzaGraphic from '../../../components/Graphics/PizzaGraphic/index.js';
import AreaGraphic from '../../../components/Graphics/AreaGraphic/index.js';
import lunchImg from '../../../assets/lunch.png';

import Button from '../../../components/Button';
import { Main, Section, ChevronLeftIcon, SourceGraphic } from './styles';
import { useToast } from '../../../hooks/toast';

interface IOrderAddress {
  id: string;
  street: string;
  streetNumber: string;
  apartment: string;
  doorCode: string;
  deliveryPrice: number;
  deliveryPlace: string;
}

interface IOrderLunchs {
  id: string;
  lunchType: string;
  quantity: number;
  lunchCost: number;
  description: string;
}

interface IOrder {
  id: string;
  deliveryTime: string;
  name: string;
  totalOrder: number;
  totalDelivery: number;
  additionalData: string;
  totalFoodCost: number;
  deliveryDate: string;
  status: string;
  deliverymanName: string;
  deliveryAddress: IOrderAddress[];
  lunchs: IOrderLunchs[];
}

const RelatorioNegocioExplodeFinal: React.FC = () => {
  const formRefSearch = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<IOrder>();

  const { id }: { id: string } = useParams();

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await api.get<IOrder[]>(`/food-order/get/${id}`);
      setClients(response.data[0]!);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const didMount = () => {
    fetchClients();
  };

  useEffect(didMount, []);

  function handleBack() {
    history.go(-1);
  }
  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Relatório de Negócios</h1>
            <h3>
              Acompanhe todas entregas realizadas pra você no período
              selecionado
            </h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <h2>
              {clients?.name} - Apartamento{' '}
              {clients?.deliveryAddress[0].apartment}
            </h2>
            <div className="flex-container">
              <div className="orderContainer">
                <h4>Endereço</h4>
                <h5>
                  {clients?.deliveryAddress[0].street}{' '}
                  {clients?.deliveryAddress[0].streetNumber}
                </h5>
                <h5>Morada {clients?.deliveryAddress[0].deliveryPlace}</h5>

                <h5>Código da porta: {clients?.deliveryAddress[0].doorCode}</h5>
                <h5>Observações</h5>
                <h5>{clients?.additionalData}</h5>
                <div>
                  <h4>Horário</h4>
                  <h5>{clients?.deliveryTime.substr(0, 5)}</h5>
                </div>
                <div>
                  <h4>Morada:</h4>
                  <h5>{clients?.deliveryAddress[0].deliveryPlace}</h5>
                </div>
                <div>
                  <h4>Responsável pela entrega:</h4>
                  {clients?.deliverymanName ? (
                    <h5>{clients.deliverymanName}</h5>
                  ) : (
                    <h5>Não há Entregador selecionado para este pedido</h5>
                  )}
                </div>
                <div className="grayLine" />
                <h4>Entrega</h4>
              </div>
              <div className="lunchContainer">
                {clients?.lunchs.map(i => (
                  <div key={i.id} className="contentLunch">
                    <div className="lunchDetail">
                      <div className="orderImg">
                        <img src={lunchImg} alt="" />
                      </div>
                    </div>
                    <div className="orderTitle">
                      <h4>{i.lunchType}</h4>
                      <span>{i.description}</span>
                    </div>
                    <div className="orderQtd">
                      <h4>Quantidade</h4>
                      <p>{i.quantity}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="grayLine" />
              <div className="totalValue">
                <h2>Valor Gerado</h2>
                <h3>{clients?.totalFoodCost}</h3>
              </div>
              <div className="bottomPedido">
                <Button className="allItems" type="button" onClick={handleBack}>
                  Voltar
                </Button>
              </div>
            </div>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default RelatorioNegocioExplodeFinal;
