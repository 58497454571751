/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { convertDateString } from '../../../utils/convertDateString';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';
import Button from '../../../components/Button';
import api from '../../../services/api';
import { Main, Section } from './styles';
import lunchImg from '../../../assets/lunch.png';

interface ITodayOrdersDeliveryAddress {
  apartment: string;
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  id: string;
  lunchType: string;
  quantity: string;
  description: string;
}

interface ITodayOrders {
  createdAt: any;
  isUpdateble: boolean;
  id: string;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  additionalData: string;
  street: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IOrderParams {
  id: string;
}

const EntregasDoDiaDetalhesClient: React.FC = () => {
  const [order, setOrder] = useState<ITodayOrders>({} as ITodayOrders);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { id } = useParams<IOrderParams>();

  const requestData = async () => {
    setLoading(true);
    const response = await api.get<ITodayOrders[]>(`/food-order/get/${id}`);
    setOrder(response.data[0]);
    setLoading(false);
  };

  const didUpdate = () => {
    requestData();
  };

  useEffect(didUpdate, [id]);

  function handleBack() {
    history.go(-1);
  }

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Entregas do dia</h1>
            <h3>
              Acompanhe as entregas que estão sendo realizadas no dia de hoje
            </h3>
          </div>
          <Link to="/novo-pedido-cliente">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            {order.isUpdateble && order.status !== 'CANCELADA' ? (
              <div className="cancelButton">
                <Link to={`/editar-pedido-cliente/${order.id}`}>
                  <Button type="button">
                    <p>Editar Pedido</p>
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="cancelButton">
                <h4>
                  Só é permitido editar este pedido, 12h antes do horário de
                  entrega.
                </h4>
              </div>
            )}
            {!loading && order && (
              <div className="flex-container">
                <div className="content">
                  <div className="status">
                    <h5>Status Atual:</h5>
                    <div className={confirmStatus(order.status)}>
                      <span>{formatStatus(order.status)}</span>
                    </div>
                  </div>
                </div>
                <div className="orderContainer">
                  <div>
                    <h4>Data de Pedido:</h4>
                    <h5>{convertDateString(order.createdAt)}</h5>
                  </div>
                  <div>
                    <h4>Data de Entrega:</h4>
                    <h5>{convertDateString(order.deliveryDate)}</h5>
                  </div>
                  <div>
                    <h4>Horário de Entrega</h4>
                    <h5>{order.deliveryTime.substr(0, 5)}</h5>
                  </div>
                  <div className="grayLine" />
                  <h5>
                    Endereço: {order.deliveryAddress[0].street} n
                    {order.deliveryAddress[0].streetNumber}
                  </h5>
                  <h5>Morada: {order.deliveryAddress[0].apartment}</h5>
                  <h5>Código da Porta: {order.deliveryAddress[0].doorCode}</h5>
                  <h5>Observações: {order.additionalData}</h5>
                  <div className="grayLine" />
                  <h4>Entrega</h4>
                </div>

                <div className="lunchContainer">
                  {order.lunchs.length !== 0 &&
                    true &&
                    order.lunchs.map((lunch, index) => (
                      <div key={String(index)} className="contentLunch">
                        <div className="lunchDetail">
                          <div className="orderImg">
                            <img src={lunchImg} alt="" />
                          </div>
                        </div>
                        <div className="orderTitle">
                          <h4>{lunch.lunchType}</h4>
                          <span>{lunch.description}</span>
                        </div>
                        <div className="orderQtd">
                          <h4>Quantidade</h4>
                          <p>{lunch.quantity}</p>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="orderFooter">
                  <div>
                    <h4>Endereço:</h4>
                    <h5>
                      {order.deliveryAddress[0].street} n
                      {order.deliveryAddress[0].streetNumber}
                    </h5>
                  </div>
                  <div>
                    <h4>Morada:</h4>
                    <h5>{order.deliveryAddress[0].apartment}</h5>
                  </div>
                  <div>
                    <h4>Responsável pela entrega:</h4>

                    {order.deliverymanName === '' ? (
                      <h5>O Seu pedido ainda não possui um entregador.</h5>
                    ) : (
                      <h5>{order.deliverymanName}</h5>
                    )}
                  </div>
                  <div className="grayLine" />
                </div>
                <div className="bottomPedidos">
                  <Button
                    className="allItems"
                    type="button"
                    onClick={handleBack}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default EntregasDoDiaDetalhesClient;
