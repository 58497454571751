/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import api from '../../../services/api';
import { convertDateString } from '../../../utils/convertDateString';
import { Main, Section, EditIcon } from './styles';

interface IOrdersList {
  groupId: string;
  client: string;
  orders: [
    {
      deliveryTime: string;
      deliveryDate: string;
      quantity: string;
      order: string;
    },
  ];
}

const PedidosAdmin: React.FC = () => {
  const [ordersList, setOrdersList] = useState<IOrdersList[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const getListOrders = async () => {
    const order = await api.get<IOrdersList[]>(`food-order/list/orders`);
    const orderArray = order.data;

    const orderBySchedule = orderArray.sort((leftDate, rightDate) => {
      const leftTimestamp = Date.parse(leftDate.orders[0].deliveryDate);
      const rightTimestamp = Date.parse(rightDate.orders[0].deliveryDate);
      return leftTimestamp - rightTimestamp;
    });
    setOrdersList(orderBySchedule);
  };

  const didMount = () => {
    setLoading(true);
    getListOrders();
    setLoading(false);
  };

  const totalQuantity = (order: IOrdersList) => {
    let total = 0;

    order.orders.forEach(i => (total += Number(i.quantity)));

    return total;
  };

  useEffect(didMount, []);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Edite o Pedido de seus Clientes</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <h2>Editar Pedido</h2>
            <div className="flex-container">
              <div>
                <h3>Cód do Pedido</h3>
              </div>
              <div>
                <h3>Pŕoximo Entrega</h3>
              </div>
              <div>
                <h3>Última Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Quantidade</h3>
              </div>
              <div className="item">edit</div>
            </div>
            {!loading &&
              ordersList?.length !== 0 &&
              ordersList?.map((order, index) => (
                <div key={order.groupId}>
                  <div className="flex-container">
                    <div>{order.groupId.substr(1, 11)}</div>
                    <div>{convertDateString(order.orders[0].deliveryDate)}</div>
                    <div>
                      {format(
                        parseISO(
                          order.orders[
                            ordersList.length > 1
                              ? ordersList[index].orders.length - 1
                              : index
                          ].deliveryDate,
                        ) as any,
                        'dd/MM/yyyy',
                      )}
                    </div>
                    <div>{order.client}</div>
                    <div>{totalQuantity(order)}</div>
                    <div className="editOrder">
                      <Link to={`/editar-pedido-inicio/${order.groupId}`}>
                        <EditIcon />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            {ordersList?.length === 0 && (
              <h4>Você não possui Pedidos cadastrados...</h4>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default PedidosAdmin;
