import React, { useState } from 'react';
import { IClientFormData, INewRequestDataLunchs } from '..';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { DeleteIcon } from '../../CadastroCliente/styles';
import { FormCadastroPequenosAlmocos } from '../../CadastroPequenosAlmocos/styles';

interface IProps {
  clientData: IClientFormData;
  pickedLunchAmount(id: string): number;
  increaseLunchAmount(id: string, value: number): void;
  pickedLunchs: INewRequestDataLunchs[];
  handleOneShotInput(input: any, value: any): void;
}

interface IPrePickedLunch {
  lunchType: string;
  description: string;
  lunchCost: number;
  lunchAmount: number;
}

const generatePrePickedLunch = () => {};

const OneShot: React.FC<IProps> = ({
  clientData,
  increaseLunchAmount,
  pickedLunchAmount,
  pickedLunchs,
  handleOneShotInput,
}) => {
  return (
    <FormCadastroPequenosAlmocos>
      <div className="formulario-cadastro">
        <div>
          <div className="form-inputs">
            <h5>Nome do Pequeno Almoço*</h5>
            <Input
              type="text"
              name="lunchType"
              id="lunchType"
              onChange={e => handleOneShotInput(e.target.id, e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <div className="form-inputs">
              <h5>Descrição*</h5>
              <Input
                type="textarea"
                className="form-textarea"
                name="description"
                id="description"
                onChange={e => handleOneShotInput(e.target.id, e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="form-inputs">
              <h5>Valor</h5>
              <Input
                type="number"
                step="any"
                defaultValue={1}
                min={0}
                className="input-valor"
                name="lunchCost"
                id="lunchCost"
                onChange={e => handleOneShotInput(e.target.id, e.target.value)}
              />
            </div>

            <div className="form-inputs">
              <h5>Quantidade</h5>
              <Input
                type="number"
                step={1}
                defaultValue={1}
                min={1}
                className="input-valor"
                name="lunchAmount"
                id="lunchAmount"
                onChange={e => handleOneShotInput(e.target.id, e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <h6>*Campos obrigatórios</h6>
    </FormCadastroPequenosAlmocos>
  );
};

export default OneShot;
