export function confirmStatus(orderStatus: string): string {
  let retornoStatus = '';

  const statusConfirm = 'A_CONFIRMAR';
  const statusPending = 'COM_PENDENCIA';
  const statusProduction = 'EM_PRODUCAO';
  const statusFinished = 'FINALIZADO';
  const statusDelivered = 'ENTREGUE';
  const statusCanceled = 'CANCELADA';

  const aConfirmar = 'aConfirmar';
  const comPendencia = 'comPendencia';
  const emProducao = 'emProducao';
  const finalizado = 'finalizado';
  const entregue = 'entregue';
  const cancelado = 'cancelado';

  if (orderStatus === statusConfirm) {
    retornoStatus = aConfirmar;
  }
  if (orderStatus === statusPending) {
    retornoStatus = comPendencia;
  }
  if (orderStatus === statusProduction) {
    retornoStatus = emProducao;
  }
  if (orderStatus === statusFinished) {
    retornoStatus = finalizado;
  }
  if (orderStatus === statusDelivered) {
    retornoStatus = entregue;
  }
  if (orderStatus === statusCanceled) {
    retornoStatus = cancelado;
  }
  return retornoStatus;
}

export function formatStatus(statusName: string): string {
  let returnFormatStatus = '';

  const statusConfirm = 'A_CONFIRMAR';
  const statusPending = 'COM_PENDENCIA';
  const statusProduction = 'EM_PRODUCAO';
  const statusFinished = 'FINALIZADO';
  const statusDelivered = 'ENTREGUE';
  const statusCanceled = 'CANCELADA';

  const aConfirmar = 'A Confirmar';
  const comPendencia = 'Com Pendência';
  const emProducao = 'Em Produção';
  const finalizado = 'Finalizado';
  const entregue = 'Entregue';
  const cancelado = 'Cancelado';

  if (statusName === statusConfirm) {
    returnFormatStatus = aConfirmar;
  }
  if (statusName === statusPending) {
    returnFormatStatus = comPendencia;
  }
  if (statusName === statusProduction) {
    returnFormatStatus = emProducao;
  }
  if (statusName === statusFinished) {
    returnFormatStatus = finalizado;
  }
  if (statusName === statusDelivered) {
    returnFormatStatus = entregue;
  }
  if (statusName === statusCanceled) {
    returnFormatStatus = cancelado;
  }
  return returnFormatStatus;
}

export function showUserType(userType: string): string {
  let retornoStatus = '';

  const roleAdmin = 'ADMIN';
  const roleProduction = 'PRODUCTION';

  const admin = 'Administrador';
  const producao = 'Produção';

  if (userType === roleAdmin) {
    retornoStatus = admin;
  }
  if (userType === roleProduction) {
    retornoStatus = producao;
  }
  return retornoStatus;
}
