/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-sequences */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import { FormHandles } from '@unform/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, subMonths, subDays, parseISO } from 'date-fns';

import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section } from './styles';
import { toEuroCurrency } from '../../../utils/toEuro';
import { ClientAllTotalizer } from './index.interface';

const TotalizadoresNegocio: React.FC = () => {
  const [loadingV2, setLoading] = useState<boolean>(true);
  const [clientTotalizer, setClientTotalizer] = useState<ClientAllTotalizer>({
    rowData: [],
    column: [],
    totalQuantity: 0,
    biggest: 0,
    totalCanceledAmount: '',
    totalCanceledQtd: 0,
    totalDeliveredAmount: '',
    totalDeliveredQtd: 0,
    totalCost: '',
    client: '',
  });
  const [dateFilter, setDateFilter] = useState({
    start: subDays(new Date(), 7),
    end: new Date(),
  });
  const [totalizerType, setTotalizerType] =
    useState<'order' | 'lunch'>('order');
  const [lunchType, setLunchType] =
    useState<'all' | 'normal' | 'single'>('all');

  const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

  const fetchData = async () => {
    try {
      setLoading(true);

      let url = '';
      if (totalizerType === 'lunch') {
        url = `/totalizer/business/lunch/${lunchType}?start=${formatDate(
          dateFilter.start,
        )}&end=${formatDate(dateFilter.end)}`;
      } else if (totalizerType === 'order') {
        url = `/totalizer/business/all?start=${formatDate(
          dateFilter.start,
        )}&end=${formatDate(dateFilter.end)}`;
      }
      const response = await api.get<ClientAllTotalizer>(url);

      setClientTotalizer(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const didMount = () => {
    fetchData();
  };

  const handleDateFilter = (date: any, label: string) => {
    setDateFilter({ start: subDays(new Date(), date), end: new Date() });
  };

  const handleStartDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, start: date });
  };

  const handleEndDate = (date: Date | any) => {
    setDateFilter({ ...dateFilter, end: date });
  };

  const handleTotalizerType = (type: 'order' | 'lunch') => {
    setLoading(true);
    setTotalizerType(type);
  };

  const handleTotalizerTypeFilter = (type: 'all' | 'normal' | 'single') => {
    setLunchType(type);
  };

  useEffect(didMount, [dateFilter, totalizerType, lunchType]);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Totalizadores Negócio</h1>
            <h3>Acompanhe os totalizadores dos relatórios de negócio</h3>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            <div className="selectContainer">
              <div className="selectContent">
                <div>
                  <div>
                    <h4>Período específico</h4>
                    <select
                      defaultValue=""
                      name="periodo-especifico"
                      id="periodo-especifico"
                      onChange={e =>
                        handleDateFilter(e.target.value, e.target.id)
                      }
                    >
                      <option id="last" value={7}>
                        Últimos 07 dias
                      </option>
                      <option id="last" value={15}>
                        Últimos 15 dias
                      </option>
                      <option id="last" value={30}>
                        Últimos 30 dias
                      </option>
                      <option id="last" value={60}>
                        Últimos 60 dias
                      </option>
                      <option id="last" value={90}>
                        Últimos 90 dias
                      </option>
                    </select>
                  </div>
                  <div>
                    <div className="delivery-date">
                      <div>
                        <span>Início</span>
                        <DatePicker
                          className="datapicker"
                          selected={dateFilter.start}
                          maxDate={dateFilter.end}
                          onChange={date => handleStartDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="I have been cleared!"
                        />
                      </div>
                      <div>
                        <span>Fim</span>
                        <DatePicker
                          className="datapicker"
                          minDate={dateFilter.start}
                          selected={dateFilter.end}
                          onChange={date => handleEndDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="I have been cleared!"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Tipo</h4>
                    <select
                      defaultValue=""
                      name="client-list"
                      id="client-list"
                      disabled={loadingV2}
                      onChange={e =>
                        handleTotalizerType(e.target.value as 'order' | 'lunch')
                      }
                    >
                      <option id="last" value="order">
                        Pedidos
                      </option>
                      <option id="last" value="lunch">
                        Pequenos Almoços
                      </option>
                    </select>
                  </div>
                  <div>
                    <h4>Filtro</h4>
                    <select
                      defaultValue=""
                      name="client-list"
                      id="client-list"
                      disabled={loadingV2 || totalizerType === 'order'}
                      onChange={e =>
                        handleTotalizerTypeFilter(
                          e.target.value as 'all' | 'normal' | 'single',
                        )
                      }
                    >
                      <option id="all" value="all">
                        Todos
                      </option>
                      <option id="normal" value="normal">
                        Normais
                      </option>
                      <option id="single" value="single">
                        Avulsos
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sectionCard">
            <h2>Pedidos no Período</h2>
            <div className="flex-container">
              <table style={{ width: '100%' }} id="customers">
                <thead>
                  <tr>
                    <th>Data de Entrega</th>
                    {clientTotalizer.column &&
                      clientTotalizer.column.map((i, index) => (
                        <>
                          <th key={String(index)}>{i.columnName}</th>
                        </>
                      ))}
                    <th>Cancelados</th>
                    <th>Entregues</th>
                    <th>Cancelados $</th>
                    <th>Entregues $</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingV2 &&
                    totalizerType === 'lunch' &&
                    clientTotalizer?.rowData &&
                    clientTotalizer!.rowData.map((i, index) => (
                      <tr key={String(index)}>
                        <td>{i.deliveryDate}</td>
                        {i.lunchs.map((z, lunchIndex) => (
                          <td>{z.quantity}</td>
                        ))}
                        <td>{i.canceledQuantity}</td>
                        <td>{i.deliveryQuantity}</td>
                        <td>{toEuroCurrency(i.canceledAmount)}</td>
                        <td>{toEuroCurrency(i.deliveryAmount)}</td>
                      </tr>
                    ))}
                  {!loadingV2 &&
                    totalizerType === 'order' &&
                    clientTotalizer?.rowData &&
                    clientTotalizer!.rowData.map((i, index) => (
                      <tr key={String(index)}>
                        <td>{i.deliveryDate}</td>
                        <td>{i.deliveryNormal}</td>
                        <td>{i.deliveryOneTime}</td>
                        <td>{i.deliveryOneShot}</td>
                        <td>{i.canceledQuantity}</td>
                        <td>{i.deliveryQuantity}</td>
                        <td>{toEuroCurrency(i.canceledAmount)}</td>
                        <td>{toEuroCurrency(i.deliveryAmount)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr style={{ fontWeight: 'bold' }}>
                    <td>TOTAL</td>
                    {clientTotalizer.column.map(i => (
                      <td>{i.columnTotalizer}</td>
                    ))}
                    <td>{clientTotalizer?.totalCanceledQtd}</td>
                    <td>{clientTotalizer?.totalDeliveredQtd}</td>
                    <td>{clientTotalizer?.totalCanceledAmount}</td>
                    <td>{clientTotalizer?.totalDeliveredAmount}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div />
          </div>
        </Section>
      </Main>
    </>
  );
};

export default TotalizadoresNegocio;
