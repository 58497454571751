/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 8,
    marginLeft: 6,
    marginRight: 6,
    backgroundColor: '#FCFCFC',
  },

  header: {
    flexDirection: 'row',
  },

  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  menu: {
    width: '100%',
    paddingRight: 9,
    flexDirection: 'row',
    borderTop: '1px solid #2E3135',
    borderBottom: '1px solid #2E3135',
  },

  content: {
    width: '100%',
    paddingRight: 4,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  section: {
    margin: 8,
    maxWidth: 255,
    paddingLeft: 9,
    paddingRight: 9,
    paddingTop: 4,
    paddingBottom: 4,
    flexGrow: 1,
  },

  main: {
    alignContent: 'flex-start',
    textAlign: 'center',
    width: 180,
    maxWidth: 270,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    flexGrow: 1,
    borderBottom: '1px solid #E7E7E7',
  },

  image: {
    maxWidth: 180,
    objectFit: 'cover',
    width: '60%',
    height: 50,
  },

  title: {
    fontSize: 12,
    marginBottom: 6,
    fontWeight: 'bold',
  },

  subtitle: {
    fontSize: 10.5,
    fontWeight: 'normal',
    marginRight: 8,
  },

  boxtitle: {
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 9,
  },

  text: {
    textAlign: 'left',
    alignItems: 'flex-start',
    fontSize: 9,
    marginLeft: 8,
  },
});

interface ILunchsClient {
  foodId: string;
  clientId: string;
  clientName: string;
  totalCost: string;
  totalQuantity: number;
}

interface ILunchsClientALL {
  lunchName: string;
  data: ILunchsClient[];
}

interface IProps {
  startDate: string;
  endDate: string;
  client: ILunchsClientALL;
}

function PDFClientExplode({ client, endDate, startDate }: IProps) {
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View wrap={false} style={styles.header}>
          <View style={styles.section}>
            <Image style={styles.image} src="/img/logo.png" />
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Relatório de Pequeno Almoço</Text>
            <Text style={styles.title}>Pequeno Almoço: {client.lunchName}</Text>
            <Text style={styles.subtitle}>
              Período de {startDate} a {endDate}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              <Text style={styles.text}>
                Emissão: {format(new Date(), 'dd/MM/yyyy')}
              </Text>
            </Text>
            <Text style={styles.subtitle}>
              <Text style={styles.text}>
                Horário: {format(new Date(), 'HH:mm')}
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.menu}>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Cliente</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Quantidade</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.boxtitle}>Valor Gerado</Text>
            </View>
          </View>
          {client.data.map(i => (
            <View key={i.clientId} style={styles.content}>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.clientName}</Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.totalQuantity}</Text>
              </View>
              <View style={styles.main}>
                <Text style={styles.boxtitle}>{i.totalCost}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}

export default PDFClientExplode;
