/* eslint-disable camelcase */
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ContainerProps {
  type?: 'success' | 'error' | 'info' | 'alert';
  has_description: number;
}
const toastTypeVariations = {
  info: css`
    position: relative;
    border: 1px solid var(--blue);
    background: var(--background);
    color: var(--blue);

    h4 {
      margin-bottom: 10px;
    }

    div.side {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 95px;
      margin-right: 4px;
      background: var(--blue);
      border-radius: 10px 0 0 10px;
    }
  `,
  success: css`
    position: relative;
    border: 1px solid #219653;
    background: var(--background);
    color: #219653;

    h4 {
      margin-bottom: 10px;
    }

    div.side {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 95px;
      margin-right: 4px;
      background: var(--green);
      border-radius: 10px 0 0 10px;
    }
  `,
  error: css`
    position: relative;
    border: 1px solid var(--primary);
    background: var(--background);
    color: var(--primary);

    h4 {
      margin-bottom: 10px;
    }
    div.side {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 95px;
      margin-right: 4px;
      background: var(--primary);
      border-radius: 10px 0 0 10px;
    }
  `,

  alert: css`
    position: relative;
    border: 1px solid var(--orange);
    background: var(--background);
    color: var(--orange);

    h4 {
      margin-bottom: 10px;
    }
    div.side {
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 95px;
      margin-right: 4px;
      background: var(--orange);
      border-radius: 10px 0 0 10px;
    }
  `,
};

export const Container = styled(animated.div)<ContainerProps>`
  width: 505px;
  height: 95px;
  z-index: 10;
  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  & + div {
    margin-top: 8px;
  }

  ${props => toastTypeVariations[props.type || 'info']}

  > svg {
    margin: 0px 12px 0 14px;
  }

  div {
    flex: 1;

    p {
      margin-top: 4px;
      font-size: 1.4rem;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 8px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${props =>
    !props.has_description &&
    css`
      align-items: center;

      svg {
        margin-top: 10px;
      }
    `}
`;
