import { shade } from 'polished';
import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    .cancelButton {
      z-index: 88888;
      float: right;
      margin: 80px 20px -80px 0;
      button {
        float: right;
        margin: -20px -16px;
      }
      h4 {
        float: right;
        margin: 10px 24px -20px 0;
        color: var(--primary);
      }
    }
  }
  h2 {
    font-size: 2.4rem;
    margin: 32px 0 32px 40px;
  }

  .status {
    display: flex;
    flex-direction: column;
    margin: 20px 50px;
    h5 {
      margin: 10px 0 -15px 0;
    }
  }
  button.allItems {
    float: left;
    width: 274px;
    height: 47px;
    margin-left: 40px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: left;

    text-align: left;
    width: 94%;
    padding: 10px;
    margin: 20px 0 10px 0px;

    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
      }
    }

    .orderContainer {
      display: flex;
      flex-direction: column;
      margin: 16px 0 8px 50px;
      h4 {
        font-weight: 700;
        margin-bottom: 10px;
      }

      h5 {
        font-weight: 400;
        margin-bottom: 10px;
      }
    }

    .aConfirmar {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 20px;
      width: 135px;
      height: 33px;
      background: var(--blue);
      margin: 20px 0 10px;
      span {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .comPendencia {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      width: 135px;
      height: 33px;
      background: var(--red);
      margin: 20px 0 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .emProducao {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green-light);
      margin: 20px 0 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--secondary);
      }
    }

    .finalizado {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green-dark-plus);
      margin: 20px 0 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .entregue {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--green);
      margin: 20px 0 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .cancelado {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 135px;
      height: 33px;
      border-radius: 20px;
      background: var(--primary);
      margin: 20px 0 10px;
      span {
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        color: var(--input);
      }
    }

    .lunchContainer {
      width: 100%;
      max-width: 980px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      margin: 0 0 20px 40px;

      .contentLunch {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        width: 100%;
      }

      .lunchDetail {
        width: 100%;
        max-width: 100px;

        display: flex;
        justify-content: space-between;

        .orderImg {
          width: 100%;
          max-width: 80px;
          img {
            width: 80px;
            display: flex;
            align-items: center;
            margin: 0 16px 30px 0;
          }
        }
      }
      .orderTitle {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-bottom: 8px;

        span {
          margin: 8px 10px;
        }
        h4 {
          margin: 8px 10px;
        }
      }
      .orderQtd {
        width: 100%;
        max-width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      p {
        font-size: 6.4rem;
        font-weight: 700;
      }
    }

    .orderFooter {
      display: flex;
      flex-direction: column;
      margin: 16px 0 8px 50px;

      > div {
        display: flex;
        margin-right: 8px;
      }
      h4 {
        font-size: 1.4rem;
        font-weight: 700;
        margin: 0 8px 10px 0;
      }

      h5 {
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
  }

  .bottomPedidos {
    display: flex;
    margin: 0 0 60px 10px;
    button {
      margin-top: 10px;
      margin-right: 20px;
    }
  }
`;
