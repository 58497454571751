import React, { useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import lunchImg from '../../../../assets/lunch.png';
import Button from '../../../../components/Button';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import { EditIcon } from '../styles';
import {
  IClientFormData,
  IClientLunch,
  IClientLunchsUpdate,
  ILunchFromAPIList,
} from '../types';

interface IProps {
  lunchOptions: ILunchFromAPIList[];
  clientData: IClientFormData;
  loadingStatus(status: boolean): void;
}
const EditarClientePequenosAlmocos: React.FC<IProps> = ({
  lunchOptions,
  clientData,
  loadingStatus,
}) => {
  const { addToast } = useToast();

  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [pickedLunchs, setPickedLunchs] = useState<IClientLunchsUpdate[]>();

  const handlePickedLunchsStart = () => {
    if (clientData) {
      const transformPickedLunchs = clientData.lunchs.map((item: any) => ({
        lunchId: item.lunch.id,
        customLunchCost: item.lunch.smallLunchPrice,
        smallPriceCost: item.lunch.smallLunchPrice,
      }));

      setPickedLunchs(transformPickedLunchs as any);
    }
  };

  const handleCustomCost = async () => {
    try {
      setLocalLoading(true);
      await api.put(
        `client/update/lunchs/${clientData.client.id}`,
        pickedLunchs,
      );
      addToast({
        type: 'success',
        title: 'Edição Realizada!',
        description: 'Edição Realizada!',
      });
      setLocalLoading(false);
    } catch (error) {
      setLocalLoading(false);
      addToast({
        type: 'error',
        title: 'Erro na Atualização de Pequenos Almoços',
        description: 'Erro na Atualização de Pequenos Almoços',
      });
      window.scrollTo(0, 0);
    }
  };

  const increaseLunchAmount = (lunchId: string, amount: number) => {
    if (amount > 0 && pickedLunchs) {
      const newArray = pickedLunchs.map((lunch: IClientLunchsUpdate) =>
        lunch.lunchId === lunchId
          ? { ...lunch, customLunchCost: amount }
          : lunch,
      );

      setPickedLunchs(newArray);
    }
  };

  const handlePickedLunch = async ({
    lunchId,
    price,
    checked,
  }: {
    lunchId: string;
    price: number;
    checked: boolean;
  }) => {
    setLocalLoading(true);
    if (checked && pickedLunchs) {
      const obj = {
        lunchId,
        smallLunchPrice: price,
        checked,
      };

      const update = await api.put(
        `client/update/lunch/${clientData.client.id}/${lunchId}`,
        obj,
      );

      setPickedLunchs([
        ...pickedLunchs,
        { lunchId, smallPriceCost: price, customLunchCost: price },
      ]);
      setLocalLoading(false);
    } else {
      const obj = {
        lunchId,
        smallLunchPrice: price,
        checked,
      };

      const update = await api.put(
        `client/update/lunch/${clientData.client.id}/${lunchId}`,
        obj,
      );

      const selected = pickedLunchs?.filter(
        (lunch: any) => lunch.lunchId !== lunchId,
      );
      setPickedLunchs(selected);
      setLocalLoading(false);
    }
    setLocalLoading(false);
  };

  const lunchValue = (lunchId: string) => {
    let value = 0;

    const isLunchPicked = pickedLunchs?.some(i => i.lunchId === lunchId);

    if (isLunchPicked) {
      value = pickedLunchs!.filter(i => i.lunchId === lunchId)[0]
        .customLunchCost!;
    } else {
      value = lunchOptions.filter(i => i.id === lunchId)[0].smallLunchPrice;
    }

    return value;
  };

  useEffect(handlePickedLunchsStart, [clientData]);

  return (
    <>
      <div className="header-lunch" style={{ marginTop: 32 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Atualizar Pequenos Almoços</h2>
          <div style={{ marginLeft: 35 }}>
            {localLoading && <PulseLoader color="#bf1e2d" />}
          </div>
        </div>
      </div>
      <div
        className="lunch-list"
        style={{ marginTop: 54, opacity: localLoading ? 0.3 : 1 }}
      >
        <div className="selectLunch">
          {lunchOptions &&
            lunchOptions.length > 0 &&
            lunchOptions.map(item => (
              <div key={item.id} className="lunch-item">
                <div className="lunch-img-title">
                  <div>
                    <img src={lunchImg} alt="" />
                  </div>
                  <div>
                    <h4>{item.lunchType || 'Nome do Pequeno Almoço'}</h4>
                  </div>
                </div>
                <div className="lunch-cost">
                  <span>Valor</span>
                  <input
                    type="number"
                    placeholder=""
                    value={lunchValue(item.id)}
                    min={Number(0)}
                    step="any"
                    name="customLunchCost"
                    disabled={
                      pickedLunchs &&
                      !pickedLunchs.some(
                        (value: any) => value.lunchId === item.id,
                      )
                    }
                    id={item.id}
                    onChange={event =>
                      increaseLunchAmount(
                        event.target.id,
                        Number(event.target.value),
                      )
                    }
                  />
                </div>
                <div>
                  <input
                    type="checkbox"
                    defaultValue={item.id}
                    id={item.id}
                    checked={
                      pickedLunchs &&
                      pickedLunchs.some(
                        (value: any) => value.lunchId === item.id,
                      )
                    }
                    name="lunchs.lunchId"
                    onChange={({ target }) =>
                      handlePickedLunch({
                        checked: target.checked,
                        lunchId: target.value,
                        price: item.smallLunchPrice,
                      })
                    }
                  />
                </div>
              </div>
            ))}
        </div>
        <Button onClick={() => handleCustomCost()} className="buttonSave">
          Atualizar Valores
        </Button>
      </div>
    </>
  );
};

export default EditarClientePequenosAlmocos;
