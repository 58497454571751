import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Search, Delete } from 'react-iconly';

export const Main = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: var(--background);

  h1 {
    font-size: 3.6rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
    button {
      width: 274px;
      height: 47px;
      margin-right: 40px;
      font-size: 1.6rem;
    }
  }
`;

export const Section = styled.div`
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px 40px 40px 38px;

  .sectionCard {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    margin: 20px 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--table-color);
    box-shadow: 0 4px 21px -2px rgba(0, 0, 0, 0.08);

    h2 {
      color: var(--primary);
    }
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    color: var(--primary);
  }

  button.allItems {
    float: right;
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;

    margin: 25px 0 20px 5px;
    padding: 40px;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .deleteItem {
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: -35px;

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 274px;
        height: 47px;
        font-size: 1.6rem;
        font-weight: 400;
        svg {
          width: 2rem;
          margin-left: 10px;
        }
      }
    }
    .menu-cadastro {
      width: 100%;
      max-width: 815px;
      border-bottom: 2px solid var(--line-table);
      display: flex;
      align-items: center;
      justify-content: left;
      text-decoration: none;
      margin-top: 30px;
    }

    .item-cadastrados {
      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid var(--primary);

      font-size: 1.8rem;
      font-weight: 400;
      color: var(--primary);
    }

    .item-novo-cadastro {
      margin-right: 47px;

      width: 320px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: 400;
      border: 0;
      background: #e8ebf1;
      text-decoration: none;
      color: var(--secondary);

      &:hover {
        border-top: 2px solid var(--primary);
      }
    }

    .bottomPedidos {
      display: flex;
      margin-top: 10px;
      padding-bottom: 15px;
      > button {
        margin-right: 40px;
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }
  }
`;

const iconCSS = css`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

export const SearchIcon = styled(Search)`
  ${iconCSS}
`;

export const DeleteIcon = styled(Delete)`
  ${iconCSS}
`;

export const FormCadastroPequenosAlmocos = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .formulario-cadastro {
    margin-top: 34px;

    div {
      display: flex;
      align-items: left;
      justify-content: left;
      & + div {
        margin-bottom: 30px;
      }
    }

    .form-inputs {
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      span {
        font-weight: 700;
      }

      h5 {
        font-size: 1.6rem;
        font-weight: 400;
        margin: 20px 0 10px 10px;
      }

      input {
        border: 0;
        border-radius: 20px;
        width: 320px;
        height: 28px;
        padding: 10px 20px;
        background: var(--input);
      }
      input.input-veiculo {
        width: 200px;
      }
      input.input-contato {
        width: 250px;
      }
      input.form-info {
        border: 0;
        border-radius: 20px;
        padding: 10px 20px;
        max-width: 600px;
        width: 600px;
      }
      .selects {
        z-index: 1;
        display: flex;
        flex-direction: column;
        background: var(--input);
        color: var(--secondary);
        width: 230px;
        border-radius: 20px;
        height: 47px;
        padding: 2px 2px 4px 2px;
        border: none;
        & div {
          margin: -2px 0;
          border-radius: 20px;
          background: var(--input);
          color: var(--secondary);
        }
      }
    }
  }
`;
