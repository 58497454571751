/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section, FormCadastroPequenosAlmocos } from './styles';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input/index';

interface LunchFormData {
  id: string;
  lunchType: string;
  description: string;
  lunchCost: number;
}

const CadastroPequenoAlmocos: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const [lunch, setLunch] = useState<LunchFormData[]>([]);

  useEffect(() => {
    api.get('/lunch/list').then(response => {
      setLunch(response.data);
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: LunchFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          lunchType: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.post('/lunch/create', data);

        addToast({
          type: 'success',
          title: 'Cadastro realizado',
          description: 'Cadastro efetuado com sucesso',
        });
        setLoading(false);

        history.push('/pequenos-almocos');
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            JSON.parse(err.request.response).message ||
            'Oops ocorreu um erro ao realizar o cadastro',
        });
        window.scrollTo(0, 0);
      }
    },
    [addToast, history],
  );

  if (!lunch) {
    return <h1>Carregando...</h1>;
  }

  function handleBack() {
    history.go(-1);
  }
  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Acompanhe o Cadastro de Pequenos Almoços</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>
      <Section>
        <div className="sectionCard">
          <div className="flex-container">
            <div>
              <h2>Cadastro de Pequenos Almoços</h2>
            </div>

            <div className="menu-cadastro">
              <Link to="/pequenos-almocos">
                <div className="item-novo-cadastro">Cadastrados</div>
              </Link>
              <div className="item-cadastrados">Novo Cadastro</div>
            </div>

            <div>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormCadastroPequenosAlmocos>
                  <div className="formulario-cadastro">
                    <div>
                      <div className="form-inputs">
                        <h5>Nome do Pequeno Almoço*</h5>
                        <Input type="text" name="lunchType" />
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Descrição</h5>
                          <Input
                            type="textarea"
                            className="form-textarea"
                            name="description"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Valor*</h5>
                          <Input
                            type="text"
                            className="input-valor"
                            name="lunchCost"
                          />
                        </div>
                      </div>
                    </div>
                    <h6>*Campo Obrigatório</h6>
                    <div className="bottomPedidos">
                      <Button type="submit" loading={loading}>
                        Cadastrar
                      </Button>
                      <Button className="allItems" onClick={handleBack}>
                        Voltar
                      </Button>
                    </div>
                  </div>
                </FormCadastroPequenosAlmocos>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default CadastroPequenoAlmocos;
