import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`

display: flex;
flex-direction: column;
align-items: center;
width: 100%;
max-width: 800px;

.modalDeliveryMan {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;


  h1 {
    font-size: 3rem;
    color: var(--primary);
    margin-bottom: 40px;
  }

  h2 {
    text-align: center;
    width: 80%;
    margin-bottom: 40px;
  }

  .formDeliveryMan {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 30px;
    }
  }
   select {
      width: 590px;
      height: 47px;
      border: 0;
      border-radius: 20px;
      margin: 0 auto;
      padding: 0 20px;
      background: var(--input);
      color: var(--secondary);
    }
}



  .bottomPedidos {
    display: flex;
    margin: 40px;
    button {
      margin-right: 20px;
    }
  }


  button.allItems {
    width: 274px;
    height: 47px;
    background: transparent;
    border: 2px solid var(--red);
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--red);
    transition: background-color 0.2s;

    &:hover {
      color: ${(shade(0.2), '#BF1E2D')};
    }
`;
