/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import { format, isSameDay, parse, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import api from '../../../services/api';
import {
  ButtonWrapperHover,
  Main,
  RedRoundButton,
  Section,
  Tooltip,
} from './styles';
import { useToast } from '../../../hooks/toast';
import { DeliveryManModal } from '../../../components/DeliveryManModal';
import { confirmStatus, formatStatus } from '../../../utils/formatStatus';

interface ITodayOrdersDeliveryAddress {
  addressId: string;
  street: string;
  streetNumber: string;
  neighborhood: string;
  apartment: string;
  doorCode: string;
}

interface ITodayOrdersLunch {
  order: string;
  nextOrder: string;
  quantity: string;
  description: string;
  lunchs: string;
}

interface ITodayOrders {
  id: string;
  createdAt: any;
  deliveryDate: string;
  deliveryTime: string;
  deliverymanName: string;
  client: string;
  apartment: string;
  additionalData?: string;
  status:
    | 'A_CONFIRMAR'
    | 'COM_PENDENCIA'
    | 'EM_PRODUCAO'
    | 'FINALIZADO'
    | 'ENTREGUE'
    | 'CANCELADA';
  deliveryAddress: ITodayOrdersDeliveryAddress[];
  lunchs: ITodayOrdersLunch[];
}

interface IDeliveryman {
  additionalData: string;
  documentNumber: string;
  iban: string;
  id: string;
  licensePlate: string;
  name: string;
  referenceContactNumber: string;
  vehicleDocument: string;
}

interface IOrderParams {
  id: string;
}

const DashboardProduction: React.FC = () => {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<ITodayOrders | undefined>();
  const [todayOrders, setTodayOrders] = useState<ITodayOrders[]>();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>('');
  const [ordersStatus, setOrdersStatus] = useState<string>('');
  const [nextOrders, setNextOrders] = useState<ITodayOrders[]>();
  const [pendingOrders, setPendingOrders] = useState<ITodayOrders[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const mountedRef = useRef(true);
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<IOrderParams>();

  const today = new Date();

  const getTodayOrders = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');

    const order = await api.get<ITodayOrders[]>(
      `food-order/day/${formattedDate}`,
    );
    const orderArray = order.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setTodayOrders(orderByTime);
  };

  const getNextOrders = async () => {
    const order = await api.get<ITodayOrders[]>('food-order/all/next');
    const orderArray = order.data;

    const orderBySchedule = orderArray.sort((leftDate, rightDate) => {
      const leftTimestamp = Date.parse(leftDate.deliveryDate);
      const rightTimestamp = Date.parse(rightDate.deliveryDate);
      return leftTimestamp - rightTimestamp;
    });

    setNextOrders(orderBySchedule);
  };

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar dados',
        description: 'Oops ocorreu um erro ao carregar dados de entregadores',
      });
    }
  };

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  const getPendingProduction = async () => {
    const formattedDate = format(today, 'yyyy-MM-dd');
    const order = await api.get<ITodayOrders[]>(
      `/food-order/${formattedDate}/?status=${'A_CONFIRMAR'}`,
    );
    const orderArray = order.data;
    const replaceOrderArray = orderArray.map((result: any) => {
      const comparationNumber = result.deliveryTime.split(':').join('');
      const formattedNumber = Number(comparationNumber);
      result.formattedNumber = formattedNumber;
      return result;
    });

    const orderByTime = replaceOrderArray.sort((leftDate, rightDate) => {
      return leftDate.formattedNumber - rightDate.formattedNumber;
    });

    setPendingOrders(orderByTime);
  };

  const didMount = () => {
    mountedRef.current = true;
    setLoading(true);
    getTodayOrders();
    getNextOrders();
    getPendingProduction();
    getAllDeliveryman();
    setLoading(false);
  };

  function handleOpenModal(order: ITodayOrders) {
    setActiveModal(order);
    setIsOrderModalOpen(true);
  }

  function handleCloseModal() {
    setIsOrderModalOpen(false);
  }

  const handleChangeStatus = (value: string) => {
    setOrdersStatus(value);
  };

  const isNextDateAfterPrevious = (actualDate: string, nextDate: string) => {
    const formatActual = parse(actualDate, 'dd/MM/yyyy', new Date());
    const formatNext = parse(nextDate, 'dd/MM/yyyy', new Date());
    return isSameDay(formatActual, formatNext);
  };

  function handleChangeStatusConfirm(
    status: string,
    id: string,
    order: ITodayOrders,
  ) {
    try {
      if (status === 'openModal') {
        handleOpenModal(order);
        return;
      }
      setLoading(true);
      api.put(`food-order/update/status/${id}`, {
        status,
      });
      addToast({
        type: 'success',
        title: 'Status Alterado!',
        description: 'Status alterado com sucesso',
      });
      setLoading(false);

      setTimeout(() => {
        didMount();
        history.push('/');
      }, 500);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao alterar Status',
        description:
          err.data.message ||
          'Oops! Ocorreu um erro ao alterar o status do pedido',
      });
    }
  }

  function handleOnConfirmDeliveryMan(deliverymanId: string, id: string) {
    setLoading(true);
    api.put(
      `/food-order/update/${id}/deliveryman/${deliverymanId}`,
      deliverymanId,
    );

    addToast({
      type: 'success',
      title: 'Entregador adicionado!',
      description: 'Entregador adicionado ao pedido com sucesso',
    });
    setLoading(false);
    setTimeout(() => {
      didMount();
      history.push('/');
    }, 500);
  }

  const filteredTodayOrders = todayOrders?.filter((order: any) => {
    return order.status !== 'ENTREGUE' && order.status !== 'CANCELADA';
  });

  const pendingOrdersToDelivery = nextOrders?.filter((order: any) => {
    return order.status !== 'ENTREGUE' && order.status !== 'CANCELADA';
  });

  useEffect(() => {
    didMount();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Olá, Seja bem vindo(a) de volta</h1>
            <h3>Acompanhe aqui tudo que acontece na sua conta</h3>
            <h2>
              Você possui <span>{filteredTodayOrders?.length} pedidos</span>{' '}
              pendentes para produzir
            </h2>
          </div>

          <Link to="/novo-pedido-producao">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div className="sectionCard">
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
            <h2>Produção do dia {format(today, 'dd/MM/yyyy')}</h2>
            <div className="flex-container">
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status</h3>
              </div>
              <div className="item">Item</div>
            </div>

            {!loading &&
              todayOrders?.length !== 0 &&
              todayOrders?.map(order => (
                <div key={order.id} className="flex-container">
                  <div style={{ width: '210px' }}>{order.client}</div>
                  <div>{order.deliveryTime.substr(0, 5)}</div>
                  <div>
                    {order.deliveryAddress[0].street} n
                    {order.deliveryAddress[0].streetNumber}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {order.apartment}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '16px',
                    }}
                  >
                    {order.lunchs.map((i, index) => (
                      <div key={String(index)}>
                        <p>
                          {index < 3 ? `${i.quantity} - ${i.order}` : `...`}
                        </p>
                      </div>
                    ))}
                    <div
                      style={{ position: 'relative', display: 'inline-block' }}
                    >
                      {order.additionalData && (
                        <ButtonWrapperHover>
                          {order.additionalData && (
                            <>
                              <RedRoundButton>
                                <span>!</span>
                              </RedRoundButton>
                              <Tooltip>{order.additionalData}</Tooltip>
                            </>
                          )}
                        </ButtonWrapperHover>
                      )}
                    </div>
                  </div>
                  {order.status !== 'ENTREGUE' ? (
                    <>
                      <div>
                        <div className={confirmStatus(order.status)}>
                          <span>{formatStatus(order.status)}</span>
                        </div>
                      </div>
                      <div>
                        <div className="changeStatusSelect">
                          <select
                            name="changeStatus"
                            id="changeStatus"
                            defaultValue={order.status}
                            onChange={event =>
                              handleChangeStatus(event.target.value)
                            }
                          >
                            <option value="A Confirmar">Mudar Status</option>
                            {order.status === 'A_CONFIRMAR' && (
                              <>
                                <option value="A_CONFIRMAR">A Confirmar</option>
                                <option value="EM_PRODUCAO">Em Produção</option>
                              </>
                            )}
                            {order.status === 'EM_PRODUCAO' && (
                              <>
                                <option value="EM_PRODUCAO">Em Produção</option>
                                <option value="FINALIZADO">Finalizado</option>
                              </>
                            )}
                            {order.status === 'FINALIZADO' &&
                              order.deliverymanName === '' && (
                                <>
                                  <option value="FINALIZADO">Finalizado</option>
                                  <option value="ENTREGUE">Entregue</option>
                                </>
                              )}
                            {order.status === 'FINALIZADO' &&
                              order.deliverymanName !== '' && (
                                <option value="openModal">Entregue</option>
                              )}
                          </select>
                          <Button
                            onClick={() =>
                              handleChangeStatusConfirm(
                                ordersStatus,
                                order.id,
                                order,
                              )
                            }
                          >
                            Ok
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div className={confirmStatus(order.status)}>
                          <span>{formatStatus(order.status)}</span>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="lastDiv">
                    <Link to={`/producao-do-dia-detalhe-prod/${order.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {todayOrders?.length === 0 && (
              <h2>Não há pedidos nesse momento para serem produzidos...</h2>
            )}
          </div>
          <div className="sectionCard">
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
            <h2>Produção Pendente {format(today, 'dd/MM/yyyy')}</h2>
            <div className="flex-container">
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Apartamento</h3>
              </div>
              <div>
                <h3>Pedido</h3>
              </div>
              <div>
                <h3>Status</h3>
              </div>

              <div className="item">Item</div>
            </div>
            {!loading &&
              filteredTodayOrders?.length !== 0 &&
              filteredTodayOrders?.map(order => (
                <div key={order.id} className="flex-container">
                  <div>{order.deliveryTime.substr(0, 5)}</div>
                  <div>{order.client}</div>
                  <div>
                    {order.deliveryAddress[0].street} n
                    {order.deliveryAddress[0].streetNumber}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {order.apartment}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '16px',
                    }}
                  >
                    {order.lunchs.map((i, index) => (
                      <div key={String(index)}>
                        {index < 3 ? `${i.quantity} - ${i.order}` : `...`}
                      </div>
                    ))}
                    <div
                      style={{ position: 'relative', display: 'inline-block' }}
                    >
                      {order.additionalData && (
                        <ButtonWrapperHover>
                          {order.additionalData && (
                            <>
                              <RedRoundButton>
                                <span>!</span>
                              </RedRoundButton>
                              <Tooltip>{order.additionalData}</Tooltip>
                            </>
                          )}
                        </ButtonWrapperHover>
                      )}
                    </div>
                  </div>

                  {order.status !== 'ENTREGUE' ? (
                    <>
                      <div>
                        <div className={confirmStatus(order.status)}>
                          <span>{formatStatus(order.status)}</span>
                        </div>
                      </div>
                      <div>
                        <div className="changeStatusSelect">
                          <select
                            name="changeStatus"
                            id="changeStatus"
                            defaultValue={order.status}
                            onChange={event =>
                              handleChangeStatus(event.target.value)
                            }
                          >
                            <option value="A Confirmar">Mudar Status</option>
                            {order.status === 'A_CONFIRMAR' && (
                              <>
                                <option value="A_CONFIRMAR">A Confirmar</option>
                                <option value="EM_PRODUCAO">Em Produção</option>
                              </>
                            )}
                            {order.status === 'EM_PRODUCAO' && (
                              <>
                                <option value="EM_PRODUCAO">Em Produção</option>
                                <option value="FINALIZADO">Finalizado</option>
                              </>
                            )}
                            {order.status === 'FINALIZADO' &&
                              order.deliverymanName !== '' && (
                                <>
                                  <option value="FINALIZADO">Finalizado</option>
                                  <option value="ENTREGUE">Entregue</option>
                                </>
                              )}
                            {order.status === 'FINALIZADO' &&
                              order.deliverymanName === '' && (
                                <option value="openModal">Entregue</option>
                              )}
                          </select>
                          <Button
                            onClick={() =>
                              handleChangeStatusConfirm(
                                ordersStatus,
                                order.id,
                                order,
                              )
                            }
                          >
                            Ok
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div className={confirmStatus(order.status)}>
                          <span>{formatStatus(order.status)}</span>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="lastDiv">
                    <Link to={`/producao-do-dia-detalhe-prod/${order.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {filteredTodayOrders?.length === 0 && (
              <h2>Não há pedidos nesse momento para serem produzidos...</h2>
            )}
          </div>
          <div className="sectionCard">
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
            <h2>Entregas Pendentes</h2>
            <div className="flex-container">
              <div>
                <h3>Data do Pedido</h3>
              </div>
              <div>
                <h3>Data de Entrega</h3>
              </div>
              <div>
                <h3>Horário de Entrega</h3>
              </div>
              <div>
                <h3>Cliente</h3>
              </div>
              <div>
                <h3>Endereço</h3>
              </div>
              <div>
                <h3>Motorista</h3>
              </div>
              <div style={{ width: '50px' }} />
            </div>
            {!loading &&
              pendingOrdersToDelivery?.length !== 0 &&
              pendingOrdersToDelivery?.map((nextOrder, i) => (
                <div
                  key={nextOrder.id}
                  className="flex-container"
                  style={{
                    borderBottom:
                      pendingOrdersToDelivery[i + 1] &&
                      pendingOrdersToDelivery &&
                      isNextDateAfterPrevious(
                        nextOrder.deliveryDate,
                        pendingOrdersToDelivery[i + 1].deliveryDate,
                      )
                        ? '1px solid var(--line)'
                        : '2px solid var(--secondary)',
                  }}
                >
                  <div>
                    {format(parseISO(nextOrder.createdAt), 'dd/MM/yyyy')}
                  </div>

                  <div>
                    {format(parseISO(nextOrder.deliveryDate), 'dd/MM/yyyy')}
                  </div>
                  <div>{nextOrder.deliveryTime}</div>
                  <div>{nextOrder.client}</div>
                  <div className="nextOrderAddress">
                    {nextOrder.deliveryAddress[0].street} n
                    {nextOrder.deliveryAddress[0].streetNumber}-
                    {nextOrder.deliveryAddress[0].neighborhood}
                  </div>
                  <div>
                    <div>
                      {nextOrder.deliverymanName === '' ? (
                        <>
                          <div className="changeStatusSelect">
                            <select
                              name="deliverymanname"
                              id="deliverymanname"
                              defaultValue="Selecionar"
                              onChange={event =>
                                handleDeliveryman(event.target.value)
                              }
                            >
                              <option value="">Selecionar</option>
                              {deliverymanList &&
                                deliverymanList?.map(driver => (
                                  <option key={driver.id} value={driver.id}>
                                    {driver.name} - {driver.licensePlate}
                                  </option>
                                ))}
                            </select>
                            <Button
                              onClick={() =>
                                handleOnConfirmDeliveryMan(
                                  deliverymanId,
                                  nextOrder.id,
                                )
                              }
                            >
                              Ok
                            </Button>
                          </div>
                        </>
                      ) : (
                        <h5>{nextOrder.deliverymanName}</h5>
                      )}
                    </div>
                  </div>
                  <div className="lastDiv">
                    <Link to={`/producao-do-dia-detalhe-prod/${nextOrder.id}`}>
                      <Button className="button-plus">
                        <span>+</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            {pendingOrdersToDelivery?.length === 0 && (
              <h2>Não há pedidos nesse momento para serem entregues...</h2>
            )}
          </div>
        </Section>
        {activeModal?.id && (
          <DeliveryManModal
            id={activeModal?.id}
            isOpen={isOrderModalOpen}
            onRequestClose={handleCloseModal}
            onConfirmDeliveryMan={handleOnConfirmDeliveryMan}
            onCancelDeliveryMan={handleCloseModal}
          />
        )}
      </Main>
    </>
  );
};

export default DashboardProduction;
