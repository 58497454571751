/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import PulseLoader from 'react-spinners/PulseLoader';
import api from '../../../services/api';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Main, Section, FormCadastroCliente, DeleteIcon } from './styles';
import { useToast } from '../../../hooks/toast';

interface IClientAddress {
  id: string;
  street: string;
  city: string;
  neighborhood: string;
  doorCode: string;
  numberOfBedrooms: string;
  deliveryPrice: number;
  apartments: [{ aptId: string; value: string }];
  addressType: string;
}

interface IClientAddressTransformed {
  id: string;
  street: string;
  city: string;
  neighborhood: string;
  doorCode: string;
  numberOfBedrooms: string;
  deliveryPrice: number;
  apartments: string[];
  addressType: string;
}

const EditarMorada: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { clientId, addressId }: { clientId: string; addressId: string } =
    useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [address, setAddress] = useState<IClientAddress | any>();

  const readClientData = async () => {
    setLoading(true);
    try {
      const response = await api.get<IClientAddress>(
        `/client/read/${clientId}/${addressId}`,
      );
      setAddress({
        ...response.data,
        apartments: response.data.apartments.map(item => ({
          aptId: uuidv4(),
          value: item,
        })),
      });
      setLoading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no carregamento dos dados',
        description: 'Erro no carregamento dos dados',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    readClientData();
  }, [addressId]);

  const changeApartamentNumber = (id: number, aptId: number, apt: string) => {
    const obj = {
      aptId,
      value: apt,
    };

    setAddress({
      ...address,
      apartments: address.apartments.map((place: any) =>
        place.aptId === aptId ? obj : place,
      ),
    });
  };

  const addApartment = () => {
    const newApt = { aptId: uuidv4(), value: '' };

    setAddress({
      ...address,
      apartments: [...address.apartments, newApt],
    });
  };

  const removeApt = ({ aptId }: { aptId: number }) => {
    if (address.apartments.length > 1) {
      setAddress({
        ...address,
        apartments: address.apartments.filter(
          (apt: any) => apt.aptId !== aptId,
        ),
      });
    }
  };

  const changeAddressData = ({ input, data }: { input: any; data: any }) => {
    const main: any = address;

    main![input] = data;

    setAddress({ ...address, input: data });
  };

  const handleSubmit = async (data: IClientAddress) => {
    const transformArray: IClientAddressTransformed = {
      ...data,
      apartments: data.apartments.map(item => item.value),
    };
    setLoading(true);
    await api.put(
      `/client/update/address/${clientId}/${addressId}`,
      transformArray,
    );
    setLoading(false);

    addToast({
      type: 'success',
      title: 'Endereço Atualizado com Sucesso',
      description: 'Endereço Atualizado com Sucesso',
    });
    window.scrollTo(0, 0);

    history.push(`/editar-cliente/${clientId}`);
  };

  function handleBack() {
    history.push(`/editar-cliente/${clientId}`);
  }

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Acompanhe o cadastro de seus clientes</h1>
          </div>
          <Link to="/novo-pedido">
            <Button type="button">+ Novo Pedido</Button>
          </Link>
        </div>
        <Section>
          <div>
            <div className="flex-container">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <h2>Editar Morada</h2>
                <div style={{ marginLeft: 35 }}>
                  {loading && <PulseLoader color="#bf1e2d" />}
                </div>
              </div>
              <div style={{ opacity: loading ? 0.3 : 1 }}>
                {!loading && (
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormCadastroCliente>
                      <div className="morada">
                        <div className="formulario-cadastro">
                          <div>
                            <div className="form-inputs">
                              <h5>Rua</h5>
                              <Input
                                name="street"
                                id="street"
                                type="text"
                                value={address?.street}
                                onChange={e =>
                                  changeAddressData({
                                    data: e.target.value,
                                    input: e.target.id,
                                  })
                                }
                              />
                            </div>
                            <div className="form-inputs">
                              <h5>Número</h5>
                              <Input
                                id="streetNumber"
                                name="streetNumber"
                                type="text"
                                className="input-numero"
                                value={address?.streetNumber}
                                onChange={e =>
                                  changeAddressData({
                                    data: e.target.value,
                                    input: e.target.id,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <div className="form-inputs">
                                <h5>Bairro</h5>
                                <Input
                                  id="neighborhood"
                                  name="neighborhood"
                                  type="text"
                                  value={address?.neighborhood}
                                  onChange={e =>
                                    changeAddressData({
                                      data: e.target.value,
                                      input: e.target.id,
                                    })
                                  }
                                />
                              </div>
                              <div className="form-inputs">
                                <h5>Cidade</h5>
                                <Input
                                  id="city"
                                  name="city"
                                  type="text"
                                  value={address?.city}
                                  onChange={e =>
                                    changeAddressData({
                                      data: e.target.value,
                                      input: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <div className="form-inputs">
                                <h5>Morada de Entrega</h5>
                                <select
                                  name="deliveryPlace"
                                  id="deliveryPlace"
                                  defaultValue={address?.deliveryPlace}
                                  onChange={e =>
                                    changeAddressData({
                                      data: e.target.value,
                                      input: e.target.id,
                                    })
                                  }
                                >
                                  <option value="Morada de Entrega">
                                    Morada de Entrega
                                  </option>

                                  <option value="FISCAL">FISCAL</option>
                                  <option value="DELIVERY">DELIVERY</option>
                                </select>
                              </div>
                              <div className="form-inputs">
                                <h5>Código da Porta</h5>
                                <Input
                                  id="doorCode"
                                  name="doorCode"
                                  type="text"
                                  value={address?.doorCode}
                                  onChange={e =>
                                    changeAddressData({
                                      data: e.target.value,
                                      input: e.target.id,
                                    })
                                  }
                                />
                              </div>
                              <div className="form-inputs">
                                <h5>Custo de Entrega</h5>
                                <Input
                                  id="deliveryPrice"
                                  name="deliveryPrice"
                                  type="number"
                                  className="input-numero"
                                  value={address?.deliveryPrice}
                                  onChange={e =>
                                    changeAddressData({
                                      data: e.target.value,
                                      input: e.target.id,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="form-inputs">
                              <h4>Quantidade de Apartamentos</h4>
                              <div className="form-inputs">
                                <h5>
                                  Clique no botão Abaixo para adicionar
                                  apartamentos
                                </h5>
                                <Button
                                  onClick={() => addApartment()}
                                  className="buttonSave"
                                >
                                  Adicionar apartamentos
                                </Button>
                                <div className="form-inputs-apartments">
                                  {address?.apartments.length > 0 &&
                                    address?.apartments.map(
                                      (i: any, index: any) => (
                                        <div key={i.aptId}>
                                          <div className="form-input-numbers">
                                            <h5>Apt {index + 1}</h5>
                                            <div>
                                              <Input
                                                className="apartment"
                                                onChange={e =>
                                                  changeApartamentNumber(
                                                    address?.fakeId,
                                                    i.aptId,
                                                    e.target.value,
                                                  )
                                                }
                                                value={i.value}
                                                name="listApartments"
                                                type="text"
                                              />
                                              <DeleteIcon
                                                className="buttonDeleted"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                  removeApt({
                                                    aptId: i.aptId,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ),
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bottomPedidos">
                            <Button
                              onClick={() => handleSubmit(address)}
                              className="buttonSave"
                            >
                              Atualizar Morada
                            </Button>
                            <Button className="allItems" onClick={handleBack}>
                              Voltar
                            </Button>
                          </div>
                          <div />
                        </div>
                      </div>
                    </FormCadastroCliente>
                  </Form>
                )}
                {loading && <h4>Carregando dados de endereço...</h4>}
              </div>
            </div>
          </div>
        </Section>
      </Main>
    </>
  );
};

export default EditarMorada;
