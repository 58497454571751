/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback, useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import api from '../../../services/api';

import Button from '../../../components/Button';
import { Main, Section, FormCadastroPequenosAlmocos } from './styles';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input/index';
import Select from '../../../components/InputSelect';

const CadastroUsuario: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const options = [
    { value: 'ADMIN', label: 'Administrador' },
    { value: 'PRODUCTION', label: 'Produção' },
  ];

  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          username: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          contactNumber: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          role: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
          password: Yup.string().required(
            `Campo Obrigatório${window.scrollTo(0, 0)}`,
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api.post('/control-panel/create', data);

        addToast({
          type: 'success',
          title: 'Cadastro realizado',
          description: 'Cadastro efetuado com sucesso',
        });

        setLoading(false);
        window.scrollTo(0, 0);
        history.push('/painel');
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            JSON.parse(err.request.response).message ||
            'Houve um erro no cadastro',
        });
        window.scrollTo(0, 0);
      }
    },
    [addToast, history],
  );

  function handleBack() {
    history.go(-1);
  }
  return (
    <Main>
      <div className="header-main">
        <div>
          <h1>Gerenciar Usuários</h1>
        </div>
        <Link to="/novo-pedido">
          <Button type="button">+ Novo Pedido</Button>
        </Link>
      </div>
      <Section>
        <div className="sectionCard">
          <h2>Cadastro de Usuários</h2>
          <div className="flex-container">
            <div className="menu-cadastro">
              <Link to="/painel">
                <div className="item-novo-cadastro">Cadastrados</div>
              </Link>
              <div className="item-cadastrados">Novo Cadastro</div>
            </div>

            <div>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormCadastroPequenosAlmocos>
                  <div className="formulario-cadastro">
                    <div>
                      <div className="form-inputs">
                        <h5>Nome do Usuário*</h5>
                        <Input type="text" name="name" />
                      </div>
                      <div className="form-inputs">
                        <h5>Telefone Usuário*</h5>
                        <Input type="text" name="contactNumber" />
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>E-mail do Usuário*</h5>
                          <Input type="text" name="username" />
                        </div>
                        <div className="form-inputs">
                          <h5>Cargo</h5>
                          <Input type="text" name="job" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="form-inputs">
                          <h5>Nível de acesso</h5>

                          <Select
                            className="selects"
                            name="role"
                            options={options}
                          />
                        </div>
                        <div className="form-inputs">
                          <div className="password">
                            <h5>Senha*</h5>
                            <Input type="password" name="password" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>*Campos Obrigatórios</h6>

                    <div className="bottomPedidos">
                      <Button loading={loading} type="submit">
                        Cadastrar
                      </Button>
                      <Button className="allItems" onClick={handleBack}>
                        Voltar
                      </Button>
                    </div>
                  </div>
                </FormCadastroPequenosAlmocos>
              </Form>
            </div>
          </div>
        </div>
      </Section>
    </Main>
  );
};

export default CadastroUsuario;
