/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isAfter, format } from 'date-fns';
import api from '../../../services/api';
import Button from '../../../components/Button';
import { Main, Section, FormCadastroCliente } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import Normal from './Normal';
import Avulso from './Avulso';
import {
  IDeliveryman,
  ILunch,
  INewRequestData,
  INewRequestDataLunchs,
  ISelectedAddress,
} from './types';

const CadastroNovoPedidoAvulso: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [pickedLunchs, setPickedLunchs] = useState<INewRequestDataLunchs[]>([]);
  const [lunchList, setLunchList] = useState<ILunch[]>([]);
  const [additionalData, setAdditionalData] = useState<string>('');
  const [startDate, setStartDate] = useState<any>(new Date());
  const [checkoutDate, setCheckoutDate] = useState<any>(new Date());
  const [deliveryTime, setDeliveryTime] = useState<any>();
  const [deliverymanList, setDeliverymanList] = useState<IDeliveryman[]>();
  const [deliverymanId, setDeliverymanId] = useState<string>();
  const [selectedAddress, setSelectedAddress] = useState<
    ISelectedAddress | any
  >({
    apartment: '',
    deliveryPrice: 0,
    street: '',
    streetNumber: '',
    city: '',
    neighborhood: '',
    doorCode: '',
  });
  const user = localStorage.getItem('@BiB:user');

  const readClientData = async () => {
    try {
      setLoading(true);
      const response = await api.get<ILunch[]>('/lunch/list');

      setLunchList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao obter o cadastro',
      });
    }
  };

  const getAllDeliveryman = async () => {
    try {
      const response = await api.get(`/deliveryman/all`);
      setDeliverymanList(response.data);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no Cadastro',
        description: 'Oops ocorreu um erro ao realizar o cadastro',
      });
    }
  };

  useEffect(() => {
    readClientData();
    getAllDeliveryman();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalFoodCost = () => {
    let totalFood = 0;

    totalFood = pickedLunchs.reduce(
      (a, b) => a + b.lunchCost * b.lunchAmount,
      0,
    );

    return Math.round(totalFood * 100) / 100;
  };
  const totalDeliveryCost = () => {
    const totalFoodQuantity = pickedLunchs.reduce(
      (a, b) => a + b.lunchAmount,
      0,
    );

    const deliveryCost = selectedAddress ? selectedAddress.deliveryPrice : 0;

    return Math.round(totalFoodQuantity * deliveryCost! * 100) / 100;
  };
  const totalCost = (foodCost: number, deliveryCost: number) => {
    return foodCost + deliveryCost;
  };

  function handleBack() {
    history.go(-1);
  }

  const handleSubmit = async (data: INewRequestData) => {
    try {
      formRef.current?.setErrors({});

      data.additionalData = additionalData;
      data.checkinDate = format(startDate, 'yyyy-MM-dd');
      data.checkoutDate = format(checkoutDate, 'yyyy-MM-dd');
      data.deliveryTime = deliveryTime;
      data.receiverName = '';
      data.type = 'SEM_CONTRATO';
      data.status = 'A_CONFIRMAR';
      data.apartment = selectedAddress!.apartment!;
      data.totalCost = totalCost(totalFoodCost(), totalDeliveryCost());
      data.clientId = JSON.parse(user!).id;
      data.lunches = pickedLunchs;
      data.oneShot = false;
      data.oneTime = true;
      data.deliverymanId = deliverymanId || '';
      data.oneTimeAddress = selectedAddress;

      setLoading(true);
      await api.post('/food-order/create', data);

      addToast({
        type: 'success',
        title: 'Novo pedido realizado',
        description: 'Pedido efetuado com sucesso',
      });
      setLoading(false);
      window.scrollTo(0, 0);
      history.push('/proximos-pedidos');
    } catch (err) {
      if (err instanceof ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro no pedido',
        description:
          JSON.parse(err.request.response).message ||
          'Oops! Ocorreu um erro ao realizar o pedido',
      });
      window.scrollTo(0, 0);
    }
  };

  const handleCheckinDate = (date: any) => {
    if (isAfter(date, checkoutDate)) {
      setCheckoutDate(date);
    }

    setStartDate(date);
  };
  const handleCheckoutDate = (date: any) => {
    setCheckoutDate(date);
  };

  const handleDeliveryTime = (time: string) => {
    setDeliveryTime(time);
  };

  const handleDeliveryman = (value: string) => {
    setDeliverymanId(value);
  };

  const pickedLunchAmount = (lunchId: string) => {
    const filter = pickedLunchs.filter(i => i.lunchId === lunchId)[0];

    return filter && filter.lunchAmount ? filter.lunchAmount : 0;
  };

  const handlePickedLunch = ({
    lunchId,
    checked,
  }: {
    lunchId: string;
    checked: boolean;
  }) => {
    if (checked) {
      if (pickedLunchs.some(i => i.lunchId === lunchId)) {
        // setPickedLunchs(pickedLunchs.map((i) => i.lunchId === lunchId ? i : {.}))
      } else {
        const selectedLunch = lunchList.filter(
          lunch => lunch.id === lunchId,
        )[0];

        const correctedLunchValue = Number(selectedLunch.smallLunchPrice);

        const obj = {
          lunchId,
          lunchCost: correctedLunchValue,
          lunchAmount:
            pickedLunchAmount(lunchId) === 0 ? 1 : pickedLunchAmount(lunchId),
        };

        setPickedLunchs([...pickedLunchs, obj]);
      }
    } else {
      const selected = pickedLunchs.filter(lunch => lunch.lunchId !== lunchId);
      setPickedLunchs(selected);
    }
  };

  const increaseLunchAmount = (lunchId: string, amount: number) => {
    if (amount > 0) {
      if (pickedLunchs.some(i => i.lunchId === lunchId)) {
        const newArray = pickedLunchs.map(lunch =>
          lunch.lunchId === lunchId ? { ...lunch, lunchAmount: amount } : lunch,
        );
        setPickedLunchs(newArray);
      } else {
        const selectedLunch = lunchList.filter(
          lunch => lunch.id === lunchId,
        )[0];

        const correctedLunchValue = Number(selectedLunch.smallLunchPrice);

        const obj = {
          lunchId,
          lunchCost: correctedLunchValue,
          lunchAmount: 1,
        };

        setPickedLunchs([...pickedLunchs, obj]);
      }
    }

    if (amount === 0) {
      const selected = pickedLunchs.filter(lunch => lunch.lunchId !== lunchId);
      setPickedLunchs(selected);
    }
  };

  const handleAdditionalData = (data: string) => {
    setAdditionalData(data);
  };

  const changeAddressData = ({ input, data }: { input: any; data: any }) => {
    const main = selectedAddress;

    main![input] = data;

    setSelectedAddress({ ...selectedAddress, main });
  };

  return (
    <>
      <Main>
        <div className="header-main">
          <div>
            <h1>Novo Pedido</h1>
          </div>
        </div>
        <Section>
          <div className="sectionCard">
            {!loading && lunchList.length && (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormCadastroCliente>
                  <div className="destino">
                    <h2>Destino</h2>
                  </div>

                  <Avulso
                    changeAddressData={changeAddressData}
                    selectedAddress={selectedAddress}
                  />

                  <div className="lunchTitle">
                    <h2>Selecione a data a entregar</h2>
                  </div>

                  <div className="delivery-date">
                    <div>
                      <span>1º dia de Pequeno Almoço</span>
                      <DatePicker
                        className="datapicker"
                        selected={startDate}
                        onChange={date => handleCheckinDate(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                    <div>
                      <span>Último dia de Pequeno Almoço</span>
                      <DatePicker
                        className="datapicker"
                        selected={checkoutDate}
                        onChange={date => handleCheckoutDate(date)}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="I have been cleared!"
                      />
                    </div>
                  </div>
                  <div className="delivery-time">
                    <span>Horário de Entrega</span>
                    <select
                      defaultValue=""
                      name="horario-entrega"
                      id="horario-entrega"
                      onChange={event => handleDeliveryTime(event.target.value)}
                    >
                      <option selected defaultValue="Selecionar">
                        Selecionar Horário
                      </option>
                      <option value="05:00">05:00</option>
                      <option value="05:30">05:30</option>
                      <option value="06:00">06:00</option>
                      <option value="06:30">06:30</option>
                      <option value="07:00">07:00</option>
                      <option value="07:15">07:15</option>
                      <option value="07:30">07:30</option>
                      <option value="07:45">07:45</option>
                      <option value="08:00">08:00</option>
                      <option value="08:15">08:15</option>
                      <option value="08:30">08:30</option>
                      <option value="08:45">08:45</option>
                      <option value="09:00">09:00</option>
                      <option value="09:30">09:30</option>
                      <option value="10:00">10:00</option>
                      <option value="10:30">10:30</option>
                      <option value="11:00">11:00</option>
                      <option value="11:30">11:30</option>
                      <option value="12:00">12:00</option>
                      <option value="15:00">15:00</option>
                      <option value="16:00">16:00</option>
                      <option value="17:00">17:00</option>
                    </select>
                    <span>Selecionar Entregador</span>
                    <select
                      defaultValue=""
                      name="horario-entrega"
                      id="horario-entrega"
                      onChange={event => handleDeliveryman(event.target.value)}
                    >
                      <option value="" selected>
                        Selecionar Entregador
                      </option>
                      {deliverymanList &&
                        deliverymanList?.map(driver => (
                          <option key={driver.id} value={driver.id}>
                            {driver.name} - {driver.licensePlate}
                          </option>
                        ))}
                    </select>
                    <span>Observações</span>
                    <textarea
                      name="additionalData"
                      id="additionalData"
                      onChange={({ target: { value } }) =>
                        handleAdditionalData(value)
                      }
                    />
                  </div>

                  <div className="lunchs">
                    <h2>Selecione seu Pedido</h2>
                    <Normal
                      pickedLunchAmount={pickedLunchAmount}
                      handlePickedLunch={handlePickedLunch}
                      increaseLunchAmount={increaseLunchAmount}
                      lunchList={lunchList}
                      pickedLunchs={pickedLunchs}
                    />
                    <h4 style={{ marginTop: 15 }}>
                      Total: € {totalCost(totalFoodCost(), totalDeliveryCost())}
                    </h4>
                  </div>
                  <div className="bottomPedidos">
                    <Button loading={loading} type="submit">
                      Finalizar Pedido
                    </Button>
                    <Button className="allItems" onClick={handleBack}>
                      Voltar
                    </Button>
                  </div>
                </FormCadastroCliente>
              </Form>
            )}
            {loading && (
              <div className="loadingData">
                <span>Carregando...</span>
              </div>
            )}
          </div>
        </Section>
      </Main>
    </>
  );
};

export default CadastroNovoPedidoAvulso;
